import { Component, OnChanges, Output, EventEmitter, Input } from '@angular/core';
import { NgSelectConfig } from '@ng-select/ng-select';
import { UserGroupsService } from 'src/app/services/user-groups.service';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'filter-report',
  templateUrl: './filter-report.component.html',
  styleUrls: ['./filter-report.component.scss']
})
export class FilterReportComponent implements OnChanges {

  constructor(
    private usergroupService: UserGroupsService,
    private userService: UserService,
    private route: ActivatedRoute
  ) { }

  @Output() filter = new EventEmitter<object>();
  @Input() mode = 1;
  @Input() filterSection = 1;

  enterpriseId = null;
  groupId = '';
  userId = '';
  usrlist = false;
  u_role: any;
  currentRoute: any;
  uui : any = true;
  selected = {
    enterpriseId: this.enterpriseId,
    groupId: this.groupId,
    userId: this.userId
  };

  enterprises = [
    { id: 1, name: 'Clowdwork' },
    { id: 2, name: 'Alcomex' },
    { id: 3, name: 'UCV' },
  ];

  groups = [];
  users = [];
  users1 = [];

  ngOnInit() {

    this.currentRoute = this.route.snapshot.url.join('/');
    if (this.currentRoute == "application-use") {
      const uid = localStorage.getItem("application_use_uID");
      const ugid = localStorage.getItem("application_use_ugID");
      if (uid || ugid) {
        this.userId = uid;
        this.groupId = ugid;
        this.usrlist = false;
      }
    }
    if (this.currentRoute == "daily-productivity") {
      const uid1 = localStorage.getItem("dailyProductivity_uID");
      const ugid1 = localStorage.getItem("dailyProductivity_ugID");
      if (uid1 || ugid1) {
        this.userId = uid1;
        this.groupId = ugid1;
        this.usrlist = false;
      }
    }
    if (this.currentRoute == "user-activity") {
      const uid2 = localStorage.getItem("user-activity_uID");
      const ugid2 = localStorage.getItem("user-activity_ugID");
      if (uid2 || ugid2) {
        this.userId = uid2;
        this.groupId = ugid2;
        this.usrlist = false;
      }
    }
    if (this.currentRoute == "time-sheet") {
      const uid3 = localStorage.getItem("time-sheet_uID");
      const ugid3 = localStorage.getItem("time-sheet_ugID");
      if (uid3 || ugid3) {
        this.userId = uid3;
        this.groupId = ugid3;
        this.usrlist = false;
      }
    }

    if (this.currentRoute == "worked-time") {
      const uid4 = localStorage.getItem("worked_time_uID");
      const ugid4 = localStorage.getItem("worked_time_ugID");
      if (uid4 || ugid4) {
        this.userId = uid4;
        this.groupId = ugid4;
        this.usrlist = false;
      }
    }

    if (this.currentRoute == "screenshots") {
      const uid5 = localStorage.getItem("screenshots_uID");
      const ugid5 = localStorage.getItem("screenshots_ugID");
      if (uid5 || ugid5) {
        this.userId = uid5;
        this.groupId = ugid5;
        this.usrlist = false;
      }
    }

    if (this.currentRoute == "collector") {
      const uid5 = localStorage.getItem("uuid");
      if (uid5) {
        this.userId = uid5;
      }
    }

    this.usergroupService.userGroupsByUsers().subscribe((res) => {
      console.log(res);
      const role = JSON.parse(localStorage.getItem("role"));
      var existRole = false;
      var Admins = [
        "Admin",
        "Administrator",
        "Adminstrator1",
        "Adminstrator2",
        "Adminstrator3",
        "Adminstrator4",
        "Adminstrator5",
        "Admin-enterprise",
      ];
      if (role) {
        existRole = role.some((value) =>
          Admins.includes(this.capitalizeFirstLetter(value.name))
        );
      } else {
        existRole = false;
      }
     
      this.u_role = 'true';
      var defaultGroup = { name: "All groups", uuid: '' }
      this.groups = [defaultGroup].concat(res[0]);
      
      var defaultUser = [{ uuid: '', name: "All users" }]
      this.users = defaultUser.concat(res[1]);
      this.users1 = res.users;

      if (this.groupId !== '' && this.groupId !== null) {
        this.uui = false;
        this.userService.userbygrpid(this.groupId).subscribe((res) => {
          console.log("rwa",res);
          var defaultUser = [{ id: '', name: "All users" }]
          this.users = defaultUser.concat(res.data.relationships.users);
        })
      }

    })
    this.filter.emit({
      enterpriseId: this.enterpriseId,
      groupId: this.groupId,
      userId: this.userId
    });

  }

  capitalizeFirstLetter(word) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }
  ngOnChanges(): void {
  }
  select() {
    if (this.groupId == "") {
      this.uui = true;
      this.usergroupService.userGroupsByUsers().subscribe((res) => {
        var defaultUser = [{ id: '', name: "All users" }]
        this.users = defaultUser.concat(res[1]);
        this.users1 = res.users;
      })

      if (this.u_role == "false") {
        this.usrlist = true;
      } else {
        this.usrlist = false;
      }
      this.users = [];

    } 

    if ((typeof this.groupId) == (typeof {})) {
      this.groupId = '';
    }
    if ((typeof this.userId) == (typeof {})) {
      this.userId = ''
    }

    this.filter.emit({
      enterpriseId: this.enterpriseId,
      groupId: this.groupId,
      userId: this.userId
    });



    if (this.groupId !== '' && this.groupId !== null) {
      this.uui = false;
      this.userService.userbygrpid(this.groupId).subscribe((res) => {
        var defaultUser = [{ id: '', name: "All users" }]
        this.users = defaultUser.concat(res.data.relationships.users);
      })
    }else{

      this.uui = true;

    this.usergroupService.userGroupsByUsers().subscribe((res) => {
      
      var defaultUser = [{ uuid: '', name: "All users" }]
      this.users = defaultUser.concat(res[1]);
      this.users1 = res.users;
    })

    }
  }

  select1() {

    this.filter.emit({
      enterpriseId: this.enterpriseId,
      groupId: this.groupId,
      userId: this.userId
    });
  }
}
