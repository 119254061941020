import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-lists-widget11',
  templateUrl: './lists-widget11.component.html',
})
export class ListsWidget11Component implements OnInit {
  @Input() cssClass: '';

  constructor() { }

  ngOnInit(): void { }
}
