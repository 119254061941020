// USA
export const locale = {
  lang: 'en',
  data: {
    "Agregar Tiempo Manual": "Add Manual Time",
    "Actividad por Usuario": "User Activity",
    "Uso de Aplicaciones": "Applications Usage",
    "Tiempo Trabajado": "Worked Time",
    "Capturas de Pantalla": "Screenshots",
    "Reportes de Productividad": "Time Tracking Reports",
    'Usuarios': 'Users',
    'Grupos de Usuarios': 'Users Groups',
    'Ventana de Tiempo': 'Time window',
    'Configuración de Seguridad': 'Security Settings',
    'Aplicaciones': 'Applications',
    'Grupos de Aplicaciones': 'Applications Groups',
    'Empresas Involucradas': 'Stakeholders',
    'Roles': 'Roles',
    'Configuración de Cliente': 'Client Configuration',
    'Colector de Logs': 'Log Collector',
    'Papelera': 'Trash',
    'Pagos Recibidos': 'Payments',
    'Planilla Automática de Horas': 'Automatic Timesheet',    
    'Productividad Diaria': 'Daily Productivity',
    'Ante.': 'Prev.',
    'Prox.': 'Next.',
    'Hoy': 'Today',
    'Mes': 'Month',
    'Semana': 'Week',
    'Día': 'Day',
    'Año': 'Year',
    'Guardar': 'Save',
    'Cerrar': 'Close',
    'intervalos de captura':'Capture intervals',
    'minutos':'min',
    'Correo electrónico a la usuario creada':'Send Email notification to created user',
    'apagada':'OFF',
    'en':'ON',
    'Nombre': 'Name',
    'Fecha': 'Date',
    'Hora inicio': 'Start time',
    'Hora finalización': 'End time',
    'Ninguna': 'None',
    'Seleccione una tarea': 'Select a task',
    'Entrar': 'Sign In',
    'Perfil': 'Profile',
    'Mi Perfil': 'My Profile',
    'Inactividad': 'Idle',
    'Privado': 'Private',
    'En reunión': 'Meetings',
    'Tiempo manual': 'Manual Time',
    'Tiempo Manual': 'Meetings',
    'Tiempo Manual ':'Manual time',
    'Sin categorizar': 'Uncategorized',
    'No productivo': 'Non Productive',
    'No Productivo': 'Non Productive',
    'Productivo': 'Productive',
    'Reunión': 'Manual Time',
    'Reuniones':'Meeting Time',
    'Filtros': 'Filters',
    'Nombre de la aplicación': 'Application name',
    'Descripción': 'Description',
    'Duración': 'Duration',
    'Horas': 'Hours',
    'Añadir nuevo usuario': 'Add user',
    'Importar Excel': 'Import Excel',
    'Importar':'Import',
    'Grupos': 'Groups',
    'Editar': 'Edit',
    'Rango de horas':'Time range',
    'Si': 'Yes',
    '-Las actividades productivas son aquellas que agregan valor al negocio.':'- Productive activities are those that add value to the business.',
    '-Las actividades no productivas son aquellas que no agregan valor al negocio.':'- Non-productive activities are those that do not add value to the business.',
    '-Las actividades no categorizadas son aquellas actividades que el administrador aún no ha clasificado como productivas o no productivas y en algunos casos, algunas empresas lo usan como comodín para validar qué usuarios y con qué frecuencia están usando esa aplicación.':'-Uncategorized activities are those activities that the administrator has not yet classified as productive or non-productive and in some cases, some companies use it as a wildcard to validate which users and how often they are using that application.',
    'El sistema almacena automáticamente las aplicaciones y los sitios web utilizados por los miembros del equipo y los registra de manera predeterminada como productivos.' : 'The system automatically stores the applications and websites used by the team members and records them by default as productive.',
    'Para categorizar una aplicación para todos los usuarios de la empresa, debe seleccionar la opción todos los grupos y todos los usuarios en los filtros. En caso de que necesite categorizar una aplicación solo para un grupo o usuario en particular, debe indicar en el filtro correspondiente el nombre del grupo/usuario y actualizar la lista haciendo clic en el botón de búsqueda. Luego debe buscar la aplicación, marcar uno de los criterios (Productivo, No productivo o Sin categorizar) y de esta manera la aplicación seleccionada quedará categorizada para todos los usuarios, grupo y/o usuario seleccionado.':'To categorize an application for all users in the company, you must select the option all groups and all users in the filters.  In case you need to categorize an application only for a particular group or user, you must indicate in the corresponding filter the name of the group / user and update the list by clicking on the search button.   Then you must search for the application, check one of the criteria (Productive, Non-productive or Uncategorized) and in this way the selected application will be categorized for all users, group and/or selected user.',
    '¿Quieres eliminar este usuario de forma permanente?': 'Do you want to permanently delete this user?',
    'Cancelar': 'Cancel',
    'Crear': 'Create',
    'Confirmar contraseña': 'Confirm password',
    'Estoy de acuerdo con los': 'I agree with those',
    'términos y condiciones.': 'terms and conditions.',
    'Estoy de acuerdo con los terminos y condiciones': 'I agree with the terms and conditions',
    'Configuración de seguridad': 'Security settings',
    'correo electronico es requerido':'Email is required',
    'el correo electrónico es invalido': 'Invalid email address',
    'Se requiere el nombre':'Name is required',
    'El nombre debe tener al menos 2 caracteres':'Name must be at least 2 characters',
    'El nombre no debe exceder los 14 caracteres' :'Name must not exceed 14 characters',
    'Se requiere empresa': 'Company is required',
    'Se requiere rol' :'Role is required',
    'Estado': 'Status',
    'Activo':'Active',
    'Inactivo': 'Idle',
    'Se requiere estado' :'Status is required',
    'Se requiere tarifa por hora' :'Hourly rate is required',
    'Se requiere límite semanal' :'Weekly Limit is required',
    'se requiere contraseña':'Password is required',
    'La contraseña debe tener al menos 6 caracteres':'Password must be at least 6 characters',
    'La contraseña no debe exceder los 20 caracteres' :'Password must not exceed 20 characters',
    'Repita la contraseña':'Repeat Password',
    'Se requiere repetir la contraseña':'Repeat Password is required',
    'Repetir la contraseña no coincide con la contraseña':'Passwords do not match',
    'Introduce la contraseña para actualizar':'Enter password to update',
    'Archivo Excel': 'Excel file',
    'Agregar grupos adicionales' :'Add Additional Groups',
    'Seleccione al menos un grupo':'Select atleast one group',
    'Rol': 'Role',
    'Tarifa por Hora':'Hourly rate',
    'Límite Semanal (Horas)':'Weekly Limit (Hours)',
    'Primera Conexión':'First Connection',
    'Ultima Conexión':'Last connection',
    'Estatus de Usuario':'User Status',
    'Modo del Agente':'Agent Mode',
    'Acciones':'Actions',
    'Desactivado':'disabled',
    'Activado':'Activated',
    'Interactivo':'Interactive',
    'Silencioso':'Silent',
    'Empresa':'Company',
    'Correo electrónico': 'Email',
    'Contraseña':'Password',
    'Usuario nuevo':'New user',
    'editar usuario':'Edit User',
    'Se requiere usuario':'User field is required',
    'El grupo de usuarios es obligatorio':'User group is required',
    'Productiva':'Productive',
    'No Productiva':'Non Productive',
    'Sin Categorizar':'Uncategorized',
    'Empresas':'Company',
    'Descargas':'Downloads',
    'Ayuda':'Help',
    'Inducción':'Onboarding',
    'Salir':'Leave',
    'Añadir nuevo grupo':'Add new group',
    'No puedes eliminar este registro porque tiene informacion asociada':'You cannot delete this record because it has associated information',
    'ordenar por':'Order by',
    'Añadir nuevo log':'Add new log',
    'Log Solicitado':'Log Requested',
    'Fecha/Hora Solicitud':'Request Date/Time',
    'Recibido':'Received',
    'Log agente':'agent log',
    'Base de datos':'Database',
    'Monitor':'Display',
    'Descargar':'Download',
    'Eliminar':'Remove',
    'se requiere grupo de usuarios':'User group is required',
    'Lunes':'Monday',
    'Martes':'Tuesday',
    'Miércoles':'Wednesday',
    'Jueves':'Thursday',
    'Viernes':'Friday',
    'Sábado':'Saturday',
    'Domingo':'Sunday',
    'Hasta':'To',
    'Desde':'From',
    'Buscar':'Search',
    'Permisos':'Permissions',
    'Tiempo Reunión':'Meeting time',
    'Productividad':'Productivity',
    'No hay registros':'No Records',
    'Actualizar usuario':'Update user',
    'Grupo':'Group',
    'Editar Grupo':'Edit Group',
    '¿Quieres eliminar este grupo de forma permanente?': 'Do you want to permanently delete this group?',
    '¿Quieres eliminar este regla de forma permanente?': 'Do you want to permanently delete this rule?',
    'Uso':'Use',
    'Tiempo total':'Total time',
    'Tiempo':'Time',
    'Promedio productividad':'Productivity average',
    'Revisar':'Check',
    '(Solo para despliegues con Microsoft Active Directory)':'(Only for Microsoft Active Directory deployments)',
    'Tiempo Manual del Sistema':'Manual time',
    'Tiempo Manual del Sistemaa':'Meetings',
    'Desintalador de Windows':'Windows Uninstaller',
    'Si tu trabajo o el de tu equipo involucra actividades fuera del computador, esta actividad puede agregarse en el calendario de ClowdWork para ser agregado al reporte de actividad.':"If your work or your team's work involves activities away from the computer, these activities can be added to the ClowdWork calendar to be included in the activity report.",
    "Solo tienes que hacer clic sobre la fecha y agregar el nombre de la actividad y las horas invertidas en ella. Esta información se visualizará en los reportes de productividad.":"Just click on the date and add the name of the activity and the hours spent on it. This information will be displayed in the productivity reports.",
    "El administrador podrá visualizar fácilmente las actividades fuera de línea de sus colaboradores a través de los filtros en los reportes.":"The administrator will be able to easily visualize the offline activities of his team members through the filters in the reports.",
    "assets/media/help/manualtimesp.jpg":"assets/media/help/manualtime.jpg",
    "assets/media/help/manualtime1sp.jpg":"assets/media/help/manualtime1.jpg",
    "Más":"More",
    "Menos":"Less",
    "Abrir": "Open",
    "Enero" : "January",
    "Febrero" : "February",
    "Marzo" : "March",
    "Abril" : "April",
    "Mayo" : "May",
    "Junio" : "June",
    "Julio" : "July",
    "Agosto" : "August",
    "Septiembre" : "September",
    "Octubre" : "October",
    "Noviembre" : "November",
    "Diciembre" : "December",
    "la fecha es": "the date is",
    "¡Hola": "Hello",
    "Actualización del perfil": "Profile update",
    "Fecha de nacimiento": "Date of birth",
    "Usuario eliminado con éxito": "User Deleted succesfully",
    "La configuración de los usuarios se puede gestionar fácilmente en la plataforma.":"The user settings can be easily managed on the platform.",
    "Los usuarios y las capturas de pantalla pueden activarse o desactivarse fácilmente, según sea necesario, haciendo clic en la columna correspondiente.":"Users and the screenshot capture feature can be easily activated or deactivated as required by clicking on the relevant column.",
    'Los usuarios pueden ser eliminados en la columna "Acciones". Haciendo clic en el icono del "bolígrafo", se pueden modificar los ajustes de cada usuario, como se muestra en la siguiente imagen:': "Users can be deleted on the “Actions” Column. By clicking on the “pen” icon, the settings for each user can be modified as shown in the next image:",
    'assets/media/help/users1sp.gif':'assets/media/help/users1.png',
    'assets/media/help/users2sp.gif':'assets/media/help/users2.png',
    "La plataforma Clowdwork permite crear fácilmente grupos o departamentos de la empresa para poder visualizar los informes por áreas. Los usuarios pueden ser transferidos de un grupo a otro simplemente arrastrando el ícono del usuario a cualquier grupo deseado.":"Clowdwork allows you to easily create groups or company departments so that the reports can be visualized by areas. Users can be transferred from one group to other group by simply dragging the user icon into any desired group.",
    "Los grupos pueden ser creados fácilmente y los usuarios pueden ser eliminados fácilmente y sus nombres pueden ser cambiados si es necesario":"Groups can be easily created, users can be easily deleted and their names can be changed if required",
    'assets/media/help/group1sp.gif':'assets/media/help/group1.gif',
    "Usuario añadido con éxito":"User added succesfully",
    "Usuario actualizado con éxito":"User updated succesfully",
    'Api no encontrada para agregar grupos de usuarios':'API not found to add user groups',
    'Grupo eliminado con éxito.':'Group deleted successfully.',
    'El grupo cambió con éxito.':'Group changed successfuly.',
    'Algo salió mal':'Something went wrong',
    'Grupo añadido con éxito':'Group added successfully',
    'Grupo actualizado con éxito.':'Group updated successfully.',
    "Una ventana de tiempo es un período entre una hora de inicio y una hora de finalización en la cual, no se generarán reportes para toda la empresa, grupo o usuario seleccionado.":"A time window is a period between a start time and an end time in which no reports will be generated for the entire company, group or selected user.",
    'assets/media/help/windowtracking1sp.gif':'assets/media/help/windowtracking1.gif',
    'La funcionalidad de ventana de tiempo, está preconfigurada para que siga una jerarquía entre los periodos / reglas de tiempo.':'The time window feature is preconfigured to follow a hierarchy of time periods / rules.',
    "La jerarquía de los periodos es la siguiente:":"The hierarchy of periods is as follows:",
    'Días':'Days',
    'es la primera.':'is the first one.',
    'es la segunda, solapa a Días.':'is the second, flaps to Days.',
    'es la tercera, solapa a Días y a Semana.':'is the third, overlapping Days and Week.',
    'Por ejemplo, si establece en':'For example, if you set in ',
     'que todos los sábados de 15:00 a 23:59, entonces todos los sábados estará inhabilitado el registro de actividades en ese intervalo de horas.':'Saturdays from 15:00 to 23:59, then all Saturdays will be disabled to register activities in that time interval.',
     'assets/media/help/windowtracking2sp.jpg':'assets/media/help/windowtracking2.gif',
     'assets/media/help/windowtracking3sp.jpg':'assets/media/help/windowtracking3.gif',
     'assets/media/help/windowtracking4sp.jpg':'assets/media/help/windowtracking4.gif',
     'Si luego establece en':'If you then set',
     "que todos los fines de semana de 15:00 a 23:59, entonces prevalece esta regla y todos los fines de semana estará inhabilitado el registro de actividades en ese intervalo de horas.":"every weekend from 15:00 to 23:59, then this rule prevails and all weekends will disable activity logging in that time range.",
     "Por último, si establece una regla de":"Finally, if you set a",
     "de 15:00 a 23:59, entonces en el día que eligió en la fecha  estará inhabilitado el registro de actividades en ese intervalo de horas.":"rule from 15:00 to 23:59, then on the day you chose the date, activity logging in that time range will be disabled.",
     "En el ejemplo de prueba se establecio el domingo 7-01-2023, entonces, esta regla solapará a la regla de Semana para este día en específico.":"In the test example, Sunday 7-01-2023 was set, so this rule will overlap the Week rule for this specific day.",
     'Antes de crear las reglas de tiempo, valide que la hora establecida en el campo':'Before creating the time rules, validate that the time set in the',
     'sea la correcta (cada navegador muestra el formato de hora diferente - 24 hrs  / AM -PM).':'field is correct (each browser displays different time format - 24 hrs / AM -PM).',
     'Nota':'Note',
     'Las reglas de tiempo creadas se aplicarán después del próximo reinicio de la PC del usuario. Este reinicio es necesario para que el agente tome las configuraciones asignadas desde el módulo de ventana de tiempo.':"The created time rules will be applied after restarting the user's PC. Restarting is necessary for the agent to take the assigned settings from the time window module.",
    "VENTANA DE TIEMPO":"Time Window", 
    "Todos los grupos":"All groups",
    "Todos los usuarios":"All users",
    "Crear regla":"Create rules",
    "Filtrar reglas":"Filter rules",
    "Nombre regla":"Rule name",    
    "Inicio":"Start",    
    "Fin":"End",   
    "Seguimiento":"Tracking",    
    "Período":"Period",    
    "Usuario":"User",
    "Ver":"See",
    "Deshabilitar seguimiento":"Disable tracking",
    "Días de semana":"Weekdays",
    "Fines de semana":"Weekends",
    "Ver regla":"View rule",
    "Editar regla": "Edit rule",
    "Grupo de usuarios":"User groups",
    'Software de seguimiento de tiempo':'Time tracking software',
    'completamente automático.':'Fully automatic',
    'Software de seguimiento de tiempo completamente automático':'Time tracking software fully automatic',
    'El informe de actividad de los usuarios le permite ver la cantidad de tiempo que cualquier miembro del equipo invierte en actividades productivas e improductivas, actividades privadas, trabajo fuera del ordenador y su tiempo de inactividad.':'The user activity report allows you to see the amount of time any team member invests in productive and unproductive activities, private activities, work off the computer and their idle time.',
    'Este módulo le permite categorizar el uso de aplicaciones/sitios web utilizados por sus empleados como Productivo, Sin categorizar y No Productivo.':'This module allows you to categorize applications / websites used by your team members as Productive, Uncategorized and Non-Productive.',
    'Todos los informes pueden descargarse fácilmente en formato PDF o CSV con un solo clic. La opción de descarga se habilitará una vez que los informes estén disponibles.':'All the reports can be easily downloaded in PDF or CSV format with a single click. The download option will be enabled once the reports are available.',
    'Descargue ahora el software de productividad automática de ClowdWork:':'Download ClowdWork´s automatic productivity software now:',
    'assets/media/help/useractivity1sp.png':'assets/media/help/useractivity1.png',
    'Este informe muestra la actividad de los usuarios. Puedes ampliar cualquier momento específico en el gráfico y podrás ver la actividad en ese marco de tiempo.':'This report shows user activity. You can zoom in any specific time on the graph and you will be able to see activity on that timeframe.',
    'assets/media/help/useractivity2sp.png':'assets/media/help/useractivity2.png',
    'assets/media/help/useractivity3sp.png':'assets/media/help/useractivity3.png',
    'assets/media/help/useractivity4sp.png':'assets/media/help/useractivity4.png',
    'El informe de actividad de los usuarios puede filtrarse por usuario, grupo o fecha para que pueda acceder fácilmente a la información que necesita.':'The user activity report can be filtered by user, group or date so that you can easily access the information you need.',
    'Actividad por Usuario (Rango de Horas)':'User Activity (Time Range)',
    'ClowdWork realiza un seguimiento automático de la actividad de los usuarios y proporciona métricas de productividad, ayudándole a identificar el tiempo invertido en actividades que añaden valor a su negocio':'ClowdWork automatically tracks user activity and provides productivity metrics, helping you identify time invested on activities that add value to your business',
    'Todos los informes se pueden descargar fácilmente en formato PDF o CSV con un solo clic. El botón de descarga estará disponible una vez que descargue el software':'All the reports can be easily downloaded in PDF or CSV format with a single click. The download button will be available once you download the software',
    'PARA COMENZAR A GENERAR METRÍCAS DEBES DESCARGAR EL AGENTE AUTOMÁTICO DE PRODUCTIVIDAD EN EL SIGUIENTE ENLACE':'TO START GENERATING METRICS YOU NEED TO DOWNLOAD THE TIME TRACKING AGENT IN THE FOLLOWING LINK',
    'assets/media/help/dailypro1sp.png':'assets/media/help/dailypro1.png',
    'assets/media/help/dailypro2sp.png':'assets/media/help/dailypro2.png',
    'assets/media/help/dailypro3sp.png':'assets/media/help/dailypro3.png',
    'assets/media/help/app1sp.png':'assets/media/help/app1.png',
    'assets/media/help/app2sp.png':'assets/media/help/app2.png',
    'Este informe permite ver el tiempo dedicado a actividades productivas (medido por la barra de la izquierda en horas), el tiempo no productivo, el tiempo manual o el tiempo fuera del ordenador y el porcentaje de productividad (la línea roja medida por la barra de porcentaje de la derecha) de cada uno de los miembros de su equipo.':'This report allows you to see the time spent on productive activities (measured by the bar on the left in hours), non-productive time, manual time or time away from the computer and the productivity percentage (the red line measured by the percentage bar on the right) of each of your team members.',
    'En la parte inferior puedes ver el informe en formato de tabla.':' At the bottom you can see the report in table format.',
    'La columna de productividad muestra el porcentaje de productividad asociado a cada día para el usuario o área seleccionada. Este porcentaje se calcula en función de las horas de trabajo de cada empresa, que se pueden configurar en la casilla situada junto al título de la columna de productividad, en el extremo derecho de la tabla.':'The Productivity column shows the productivity percentage associated with each day for the selected user or area. This percentage is calculated based on the working hours of each company, which can be configured in the box next to the title of the productivity column on the far right of the table',
    'El informe de productividad diaria dispone de un sistema de búsqueda que permite filtrar la información (por usuario, grupo o área y fecha) para ver sólo los datos que necesita.':'The daily productivity report has a search system that allows you to filter the information (by user, group or area and date) to view only the data you need.',
    'Este informe hace un seguimiento del uso del tiempo de trabajo ya que muestra todas las aplicaciones, herramientas, software y sitios web a los que se ha accedido, especificando el tiempo exacto invertido en cada uno de ellos. ClowdWork hace un seguimiento automático de todos los miembros del equipo, independientemente de su ubicación, y ofrece un desglose por URL o aplicación para que puedas identificar fácilmente si el tiempo de trabajo':'This report tracks work time usage by showing all applications, tools, software and websites accessed, specifying the exact time spent on each of them. ClowdWork automatically tracks all team members, regardless of their location, and provides a breakdown by URL or application so you can easily identify whether work time is being spent on a particular activity.',
    'Todos los informes pueden descargarse en PDF o Excel haciendo clic en los botones verdes en la parte superior derecha, los cuales aparecerán una vez se hayan generado las métricas:':'All reports can be downloaded in PDF or Excel format by clicking on the green buttons on the top right, which will appear once the metrics have been generated:',
    'PARA COMENZAR A GENERAR MÉTRICAS DEBES DESCARGAR EL AGENTE AUTOMÁTICO DE PRODUCTIVIDAD EN EL SIGUIENTE ENLACE':'TO START GENERATING METRICS YOU MUST DOWNLOAD THE AUTOMATIC PRODUCTIVITY AGENT IN THE FOLLOWING LINK',
    'Haciendo clic en el botón que aparece junto a cada aplicación, podrás ver una lista detallada de todos los archivos, URLs y apps que ha utilizado cualquier miembro del equipo y el tiempo que ha dedicado a cada uno. Haciendo clic en el menú de la parte superior, podrás ver la actividad por categorías: Productiva, no productiva, sin categorizar o actividad sin conexión.':'By clicking on the button next to each application, you will see a detailed list of all the files, URLs and apps used by any team member and the time spent on each one. By clicking on the menu at the top, you can view the activity by category: Productive, non-productive, uncategorized or offline activity.',
    'El segundo botón permite clasificar cada aplicación, como productiva, no productiva o sin categorizar, para toda la organización, un grupo o un usuario. Con base en esta clasificación se generan las métricas de productividad. En la parte derecha se muestra un gráfico que muestra cómo se divide el uso del tiempo laboral en términos porcentuales.':'The second button allows you to classify each application as productive, non-productive or uncategorized for the whole organization, a group or a user. Based on this classification, productivity metrics are generated. On the right side is a graph showing how the use of work time is divided in percentage terms.',
    'assets/media/help/appuse1sp.png':'assets/media/help/appuse1.png',
    'assets/media/help/appuse2sp.png':'assets/media/help/appuse2.png',
    'Datos no disponibles':'No Data Available',
    'Cambiar logotipo':'Change logo',
    'Cargar imagen':'Upload image',
    'Su categoria':'Your category',
    'Cliente': 'Client',
    'Agregar cliente': 'Add client',
    'Vista':'View',
    'Nombre de empresa': 'Company name',
    'Módulos':'Modules',
    'Administrador de usuarios': 'User admin',
    'Es compañero':'partner',
    'nombre de host': 'Hostname',
    'licencia de cantidad':'licenses Quantity',
    'Cliente creado con éxito': 'Client created successfully',
    'Nuevo cliente':'New client',
    'Ver cliente':'View client',
    'ID de idioma':'Language id',
    'Solicitar demostración':'Request demo',
    'Se ha enviado la solicitud.':'Request has been submitted.',
    'Solicite una demostración': 'Request a demo',
    'Actualizar hora manual':'Update Manual Time',
    '¿Está seguro de que desea eliminar este evento?':'Are you sure you want to delete this Event',
    'Evento añadido con éxito':'Event added succesfully', 
    'seleccione la fecha de inicio':'select start date',
    'seleccione la fecha de finalización':'select end date',
    'seleccione la hora de inicio':'select start time',
    'seleccione la hora de finalización':'select end time',
    'seleccionar nombre':'select name',
    'Desinstaladores':'Uninstallers',
    'Tiempo en reuniones':'Meetings',
    'Tablero':'Dashboard',
    'Induccion':'Onboarding',
    'Cambiar':'Update',
    'Cargo':'Position',
    'Aceptar': 'Accept',
    'Cambiar contraseña': 'Change Password',
    'Repetir contraseña':'Repeat password',
    'Nueva contraseña':'New Password',
    'Contraseña anterior':'Old Password',
    'Cambio':'Change',
    'Versión OS X':'OSX Version',
    'Versión Linux':'Linux Version',
    'Versión Windows':'Windows Version',
    'Desde OS 10.11 en adelante':'From OS 10.11 onwards',
    'Desde Ubuntu 18.04 en adelante':'From Ubuntu 18.04 onwards',
    'Desde Windows 7 en adelante':'From Windows 7 onwards',
    'El informe de tiempo trabajado muestra la cantidad de tiempo invertido en actividades productivas, no productivas, manuales o fuera de línea.':'The worked time report shows the amount of time spent on productive, non-productive, manual or offline activities.',
    'Todos los informes se pueden descargar fácilmente en formato PDF o CSV con un solo clic. El botón de descarga estará disponible una vez que descargue el software.':'All reports can be easily downloaded in PDF or CSV format with a single click. The download button will be available once you download the report',
    'PARA COMENZAR A GENERAR METRÍCAS DEBES DESCARGAR EL AGENTE AUTOMÁTICO DE PRODUCTIVIDAD EN EL SIGUIENTE ENLACE:':'TO START GENERATING METRICS YOU NEED TO DOWNLOAD THE AUTOMATIC PRODUCTIVITY AGENT IN THE FOLLOWING LINK:',
    'assets/media/help/windowtrack1sp.png':'assets/media/help/windowtrack1.png',
    'assets/media/help/windowtrack2sp.png':'assets/media/help/windowtrack2.png',
    'Para cada miembro del equipo, se puede mostrar el total de horas trabajadas por día. La línea verde permite comparar entre usuarios el tiempo trabajado en el rango seleccionado. La línea roja vertical muestra el valor medio trabajado por todo el equipo en el rango de tiempo a analizar.':'For each team member, you can see the total hours worked per day. The green line allows you to compare the worked time among users in the selected range. The vertical red line shows the average value worked by the whole team in the time range to be analyzed.',
    'Los botones de la parte superior permite seleccionar la vista del tipo de tiempo requerido (Productivo, Manual, Sin categoría, No productivo u Ocioso).':'The buttons at the top allow you to filter the required information (Productive, Manual, Uncategorized, Non-productive or Idle).',
    'El informe de Tiempo Trabajado tiene un sistema de búsqueda que permite filtrar la información (por usuario, grupo o área y fecha) para ver sólo los datos que necesita.':'The Worked time report has a search system that allows you to filter the information (by user, group or area and date) to see only the data you need.',
    'Bienvenido a ClowdWork':'Welcome to ClowdWork',
    '¿No tienes cuenta?':"You Don't have an account?",
    '¿Olvidaste tu password?':'Forgot your password?',
    'Ocurrio un error, verifica el usuario y el password':'An error occurred.  Please check the username and password',
    'Esta función le permite ver cómo avanza el trabajo con capturas de pantalla automáticas a intervalos regulares, lo que garantiza que la actividad diaria se ajuste a las prioridades y los objetivos de la empresa. Esta función se puede activar o desactivar en cualquier momento.':'This feature allows you to see how work is progressing with automatic screenshots at regular intervals, ensures that daily activity is in line with company priorities and objectives. This feature can be enabled or disabled at any time.',
    'PARA EMPEZAR A GENERAR ESTE INFORME ES NECESARIO DESCARGAR EL GESTOR AUTOMÁTICO DE PRODUCTIVIDAD HACIENDO CLIC EN EL SIGUIENTE ENLACE:':'TO BEGIN GENERATING THIS REPORT YOU NEED TO DOWNLOAD THE AUTOMATIC PRODUCTIVITY AGENT BY CLICKING ON THE FOLLOWING LINK:',
    'ClowdWork realiza capturas de pantalla a intervalos regulares y mide los niveles de actividad para que puedas gestionar la productividad de tus equipos de trabajo operativos. Por defecto, las capturas de pantalla se realizan cada 5 minutos, pero los intervalos son totalmente personalizables. Los niveles de actividad se basan en el uso del ratón y del teclado de cada usuario. Se puede acceder al informe de capturas de pantalla y a los niveles de actividad en tiempo real desde cualquier dispositivo y, haciendo clic en cada captura de pantalla, se puede ampliar o descargar la imagen.':'ClowdWork takes screenshots at regular intervals and measures activity levels so you can manage the productivity of your operational work teams. The activity levels are based on mouse and keyboard usage for each user. The screen capture report and activity levels can be accessed in real time from any device and by clicking every screenshot, you can zoom in or download the image.',
    'Esta función se puede activar o desactivar en cualquier momento desde la configuración (en el menú de la parte superior derecha':'This feature can be enabled or disabled at any time from the settings (in the menu in the top right)',
    'según las necesidades de cada empresa.':'according to company’s needs.',
    'Grupo de administradores':'Admin group',
    'Saltar incorporación':'Skip Onboarding',
    'El informe también está disponible en formato de tabla.':'The report is also available in table format.',
    'Registro':'Register',
    'En este módulo puede crear usuarios y actualizar la información de cada miembro del equipo (nombre de usuario, grupo al que pertenece, contraseña). También puede asignar roles (usuario general, usuario con acceso a informes - administrador de grupo o administrador general). La captura de usuarios y capturas de pantalla también se puede habilitar o deshabilitar.' : 'In this module you can create users and update the information of each team member (user name, group to which they belong, password). You can also assign roles (general user, user with access to reports - group administrator or general administrator). Users and screenshots capture can also be enabled or disabled.',
    'Agregue los miembros de su equipo >>':'Add your team members >>',
    'Seleccione el sistema operativo correspondiente y complete la descarga. El software comenzará a generar automáticamente métricas de productividad.':'Please select the corresponding operating system and complete the download. The software will begin to automatically generate productivity metrics.',
    'Instalar el agente de productividad >>':'Install the productivity agent >>',
    'Puede crear, actualizar o eliminar grupos según los departamentos de su empresa':'You can create, update or delete groups according to your company departments',
    'Configurar grupos de usuarios >>':'Configure user groups >>',
    'Puede clasificar las aplicaciones que utilizan los miembros de su equipo como productivas o no productivas para que los informes de productividad se puedan generar con precisión. De esta manera, puedes ayudar a todos los miembros del equipo a enfocarse en aquellas actividades que realmente agregan valor a tu negocio.':'You can classify the applications that your team members use as productive or non-productive so that productivity reports can be generated accurately. This way, you can help all team members focus on those activities that really add value to your business.',
    'Clasificar aplicaciones >>':'Classify applications >>',
    'La plataforma ofrece varios informes de productividad. Cada módulo cuenta con filtros, para que puedas ver la información ya sea por grupo/departamento de la empresa o individualmente. ¡También puede descargar los informes en formato PDF o Excel con solo un clic!':'The platform offers various productivity reports. Each module has filters, so that you can see the information either by group / company department or individually. You can also download the reports in PDF or Excel format with just one click!',
    'Informes >>':'Reports >>',
    'Agrega los miembros de tu equipo':'Add your team members',
    'Instalar el agente de productividad':'Install the productivity agent',
    'Configurar grupos de usuarios':'Configure user groups',
    'Clasificar aplicaciones':'Classify applications',
    'Informes de productividad en tiempo real':'Real-time productivity reports',
    'Formulario de activación':'Activation form',
    'Correo electrónico del cliente':'Customer email',
    'Nombre de usuario':'User name',
    'Facturación y Planes':'Billing and Plans',
    'Logotipo cambiado con éxito':'Logo changed successfully',
    'Subir':'Upload',
    'Grupo nuevo':'new group',
    'Algosalió mal' : 'Something went worng',
    'Se ha enviado un correo electrónico, siga las instrucciones.' : 'An email has been sent. Please follow the instructions',
    'Cambia la contraseña':'Change password',
   'comprobar contraseña y confirmación clave': 'Check and confirm password',
   'Contraseña cambiada con éxito' : 'Password Changed successfully',
   'Grupo añadido con éxito.':'Group added successfully.',
   'Regla añadida con éxito':'Rule added successfully',
   'Regla actualizada con éxito':'Rule updated successfully',
   'Descargar manual de desinstalación de Windows':'Download Windows Uninstaller manual',
   'Descargar manual de desinstalación de GNU/Linux':'Download GNU/Linux Uninstaller manual',
   'Descargar manual de desinstalación de Mac':'Download Mac Uninstaller manual',
   'Archivo importado con éxito.':'File imported succesfully.',
   'La actualización no está disponible.':'Update is not available.',
   'Actualizar cliente':'Update client',
   'Reportar a':'Report to',
   'Se requiere la URL de informe':'Reporting URL is required',
   'DB sufijo':'DB suffix',
   'Se requiere el sufijo db':'Db suffix is required',
   'Nuevo beta':'New beta',
   'Nuevo stable':'New stable',
   'El canal es requerido':'Channel is required',
   'Se requiere la versión de Windows':'Windows version is required',
   'Se requiere la versión de Linux':'Linux version is required',
   'versión Mac':'Mac version',
   'Se requiere la versión de Mac':'Mac version is required',
   'Intervalo de inventario de hardware':'Hardware inventory interval',
   'Se requiere un intervalo de inventario de hardware':'Hardware inventory interval is required',
   'Intervalo de inventario de software':'Software inventory interval',
   'Se requiere un intervalo de inventario de software':'Software inventory interval is required',
   'Tiempo de inactividad a ralentí':'Inactivity time to idle',
   'Se requiere tiempo de inactividad para inactividad':'Inactivity time to idle is required',
   'Intervalo de envío del registro':'Registry submission interval',
   'Se requiere el intervalo de envío del registro':'Registry submission interval is required',
   'Intervalo de verificación de config':'Config verification interval',
   'Se requiere intervalo de verificación de config':'Config verification interval is required',
   'Intervalo de registro':'Log interval',
   'Se requiere intervalo de registro':'Log interval is required',
   'Canal':'Channel',
   'Monitor activado':'Monitor activated',
   'Captura de pantalla':'Screenshot',
   'Salida y clerre de sesion':'Departure and closing of the session',
   'Modo privado':'Private mode',
   'Permitir el inventario de hardware':'Allow hardware inventory',
   'Permitir el inventario de software':'Allow software inventory',
   'Send registry':'Send registry',
   'Silent mode':'Silent mode',
   'No existe ningun user con ese identificador':'There is no user with that identifier',
   'error No puedes eliminar este registro porque tiene informacion asociada':'You cannot delete this record because it has associated information',
   'Proyectos':'Projects',
   'Buscar nuevo':'Search new',
   'Filtrar':'Filter',
   'Marcar como favorito':'Mark as favorite',
   'Nuevo proyecto':'New Project',
   'Filtrar por usuario':'Filter by user',
   'Nuevo subproyecto':'New sub project',
   'Nueva tarea':'New task',
   'Nombre del proyecto':'New project',
   'Fecha de inicio':'Start date',
   'Fecha final':'End date',
   'Cliente ':'Customer',
   'Categoría':'Category',
   'Mi equipo':'My team',
   'screensot_enable':'Screenshot enable',
   'Ver más':'View more',
   "Agregar recolección de log":"Add log collection",
   "Descargar manual de Instalación de Windows":"Download Windows Installer manual",
   'Descargar manual de Instalación de GNU/Linux':'Download GNU/Linux Installer manual',
   'Descargar manual de Instalación de Mac':'Download Mac Installer manual',
   'Sistema Operativo/Agente':'Operating system/Agent',
   'Trabaja de forma más inteligente y disfruta de la vida':'Work smarter and enjoy life',
   'Nombre Completo':'Full Name',
   'El nombre de la empresa es obligatorio':'Company name is required',
  'El nombre de la empresa debe contener al menos 3 caracteres':'Company name must contain atleast 3 characters',
  'He leído y estoy de acuerdo con':'I have read and agree to the',
  'Términos y condiciones':'Terms and Conditions', 
  'de CloudWork.':'of Clowdwork',
  'INFORMACIÓN DE USUARIO':'USER INFO',
  ': Mínimo 8 caracteres':': Min 8 characters',
  'Regístrese para una prueba gratuita de ClowdWork':'Sign up for a free ClowdWork trial',
  'REGISTRATE GRATIS':'SIGN UP FOR FREE',
  'Registro exitoso':'Registration successfull',
  ': Mínimo 6 caracteres':': Minimum 6 characters.',
  'Tienpo Manual':'Manual Time',
  'Aplicaciones más usadas':'APP-URL Usage',
  'Tiempos de tracking más altos':'Top time tracked classification',
  '(Dominio de la empresa)':'(Company domain)',
  "No utilice caracteres especiales ni espacios; use guiones '-' como separadores. Máximo 14 caracteres":"Do not use special characters or spaces; use hyphens '-' as separators. Maximum 14 characters",
  "Caracteres especiales y espacios no permitidos.":"Special characters and spaces not allowed.",
  'img_validation':'Invalid image format. Only JPEG, PNG, and GIF formats are allowed.',
  'proyectos': 'Projects',
  'Referencia del proyecto':'Project-reference',
  'database_type':'Database type',
  TRANSLATOR: {
      SELECT: 'Select your language',
    },
    MENU: {
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: 'Don\'t have an account?',
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Login Account',
        BUTTON: 'Sign In',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.'
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username'
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions is required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted'
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created'
        }
      }
    }
  }
};
