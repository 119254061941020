<!--begin::Advance Table: Widget 7-->
<div class="card card-custom" [ngClass]="cssClass">
  <!--begin::Header-->
  <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">New Arrivals</span>
          <span class="text-muted mt-3 font-weight-bold font-size-sm">More than 400+ new members</span>
      </h3>
      <div class="card-toolbar">
          <ul class="nav nav-pills nav-pills-sm nav-dark-75">
              <li class="nav-item"
                (click)="setCurrentTab('Month')"
                [style.cursor]="'pointer'"
              >
                  <a class="nav-link py-2 px-4"
                    [class.active]="currentTab === 'Month'"
                    data-toggle="tab"
                  >
                    Month
                  </a>
              </li>
              <li class="nav-item"
                (click)="setCurrentTab('Week')"
                [style.cursor]="'pointer'"
              >
                  <a class="nav-link py-2 px-4"
                    [class.active]="currentTab === 'Week'"
                    data-toggle="tab"     
                  >Week</a>
              </li>
              <li class="nav-item"
                (click)="setCurrentTab('Day')"
                [style.cursor]="'pointer'"
              >
                  <a class="nav-link py-2 px-4 active"
                    [class.active]="currentTab === 'Day'"
                    data-toggle="tab"
                  >Day</a>
              </li>
          </ul>
      </div>
  </div>
  <!--end::Header-->

  <!--begin::Body-->
  <div class="card-body pt-2 pb-0 mt-n3">
      <div class="tab-content mt-5" id="myTabTables12">
          <!--begin::Tap pane-->
          <div class="tab-pane fade"
            role="tabpanel"
            [ngClass]="{'active show': currentTab === 'Month'}"
            >
              <!--begin::Table-->
              <div class="table-responsive">
                  <table class="table table-borderless table-vertical-center">
                      <thead>
                          <tr>
                              <th class="p-0 w-50px"></th>
                              <th class="p-0 min-w-200px"></th>
                              <th class="p-0 min-w-120px"></th>
                              <th class="p-0 min-w-120px"></th>
                              <th class="p-0 min-w-120px"></th>
                              <th class="p-0 min-w-160px"></th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td class="p-0 py-4">
                                  <div class="symbol symbol-50 symbol-light mr-5">
                                      <span class="symbol-label">
                                        <span [inlineSVG]="'./assets/media/svg/misc/005-bebo.svg'" class="svg-icon h-50 align-self-center"></span>
                                      </span>
                                  </div>
                              </td>
                              <td class="pl-0">
                                  <a class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg cursor-pointer">Beats Studio</a>
                                  <span class="text-muted font-weight-bold d-block">FTP: bprow@bnc.cc</span>
                              </td>
                              <td class="text-right pr-0">
                                  <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                      $57,000
                                  </span>
                                  <span class="text-muted font-weight-bold">
                                      Paid
                                  </span>
                              </td>
                              <td class="text-right">
                                  <span class="text-muted font-weight-bold">
                                      AngularJS, C#
                                  </span>
                              </td>
                              <td class="text-right">
                                  <span class="label label-lg label-light-danger label-inline">Rejected</span>
                              </td>
                              <td class="pr-0 text-right">
                                  <a class="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer">
                                    <span [inlineSVG]="'./assets/media/svg/icons/General/Settings-1.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                  </a>
                                  <a class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3 cursor-pointer">
                                    <span [inlineSVG]="'./assets/media/svg/icons/Communication/Write.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                  </a>
                                  <a class="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer">
                                    <span [inlineSVG]="'./assets/media/svg/icons/General/Trash.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                  </a>
                              </td>
                          </tr>
                          <tr>
                              <td class="p-0 py-4">
                                  <div class="symbol symbol-50 symbol-light mr-5">
                                      <span class="symbol-label">
                                        <span [inlineSVG]="'./assets/media/svg/misc/014-kickstarter.svg'" class="svg-icon h-50 align-self-center"></span>
                                      </span>
                                  </div>
                              </td>
                              <td class="pl-0">
                                  <a href="#" class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">KTR Application</a>
                                  <span class="text-muted font-weight-bold d-block"><span class="font-weight-bolder text-dark-75">FTP:</span> bprow@bnc.cc</span>
                              </td>
                              <td class="text-right">
                                  <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                      $45,200,000
                                  </span>
                                  <span class="text-muted font-weight-bold">
                                      Paid
                                  </span>
                              </td>
                              <td class="text-right">
                                  <span class="text-muted font-weight-bold">
                                      ReactJS, Ruby
                                  </span>
                              </td>
                              <td class="text-right">
                                  <span class="label label-lg label-light-warning label-inline">In Progress</span>
                              </td>
                              <td class="pr-0 text-right">
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/General/Settings-1.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3 cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/Communication/Write.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/General/Trash.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                            </td>
                          </tr>
                          <tr>
                              <td class="p-0 py-4">
                                  <div class="symbol symbol-50 symbol-light">
                                      <span class="symbol-label">
                                        <span [inlineSVG]="'./assets/media/svg/misc/006-plurk.svg'" class="svg-icon h-50 align-self-center"></span>                                          
                                      </span>
                                  </div>
                              </td>
                              <td class="pl-0">
                                  <a href="#" class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Sant Outstanding</a>
                                  <div>
                                      <span class="font-weight-bolder">Email:</span>
                                      <a class="text-muted font-weight-bold text-hover-primary" href="#">bprow@bnc.cc</a>
                                  </div>
                              </td>
                              <td class="text-right">
                                  <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                      $2,000,000
                                  </span>
                                  <span class="text-muted font-weight-bold">
                                      Paid
                                  </span>
                              </td>
                              <td class="text-right">
                                  <span class="text-muted font-weight-bold">
                                      ReactJs, HTML
                                  </span>
                              </td>
                              <td class="text-right">
                                  <span class="label label-lg label-light-primary label-inline">Approved</span>
                              </td>
                              <td class="pr-0 text-right">
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/General/Settings-1.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3 cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/Communication/Write.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/General/Trash.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                            </td>
                          </tr>
                          <tr>
                              <td class="p-0 py-4">
                                  <div class="symbol symbol-50 symbol-light mr-5">
                                      <span class="symbol-label">
                                        <span [inlineSVG]="'./assets/media/svg/misc/015-telegram.svg'" class="svg-icon h-50 align-self-center"></span>
                                      </span>
                                  </div>
                              </td>
                              <td class="pl-0">
                                  <a href="#" class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Telegram Mobile</a>
                                  <span class="text-muted font-weight-bold d-block"><span class="font-weight-bolder text-dark-75">FTP:</span> bprow@bnc.cc</span>
                              </td>
                              <td class="text-right">
                                  <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                      $4,600,000
                                  </span>
                                  <span class="text-muted font-weight-bold">
                                      Paid
                                  </span>
                              </td>
                              <td class="text-right">
                                  <span class="text-muted font-weight-bold">
                                      Python, MySQL
                                  </span>
                              </td>
                              <td class="text-right">
                                  <span class="label label-lg label-light-warning label-inline">In Progress</span>
                              </td>
                              <td class="pr-0 text-right">
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/General/Settings-1.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3 cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/Communication/Write.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/General/Trash.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                            </td>
                          </tr>
                          <tr>
                              <td class="p-0 py-4">
                                  <div class="symbol symbol-50 symbol-light mr-5">
                                      <span class="symbol-label">
                                        <span [inlineSVG]="'./assets/media/svg/misc/003-puzzle.svg'" class="svg-icon h-50 align-self-center"></span>                                      
                                      </span>
                                  </div>
                              </td>
                              <td class="pl-0">
                                  <a href="#" class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Cisco Management</a>
                                  <span class="text-muted font-weight-bold d-block"><span class="font-weight-bolder text-dark-75">FTP:</span> bprow@bnc.cc</span>
                              </td>
                              <td class="text-right">
                                  <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                      $560,000
                                  </span>
                                  <span class="text-muted font-weight-bold">
                                      Paid
                                  </span>
                              </td>
                              <td class="text-right">
                                  <span class="text-muted font-weight-bold">
                                      Laravel, Metronic
                                  </span>
                              </td>
                              <td class="text-right">
                                  <span class="label label-lg label-light-success label-inline">Success</span>
                              </td>
                              <td class="pr-0 text-right">
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/General/Settings-1.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3 cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/Communication/Write.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/General/Trash.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                            </td>
                          </tr>
                      </tbody>
                  </table>
              </div>
              <!--end::Table-->
          </div>
          <!--end::Tap pane-->

          <!--begin::Tap pane-->
          <div class="tab-pane fade"
            role="tabpanel"
            [ngClass]="{'active show': currentTab === 'Week'}"
          >
              <!--begin::Table-->
              <div class="table-responsive">
                  <table class="table table-borderless table-vertical-center">
                      <thead>
                          <tr>
                              <th class="p-0 w-50px"></th>
                              <th class="p-0 min-w-200px"></th>
                              <th class="p-0 min-w-120px"></th>
                              <th class="p-0 min-w-120px"></th>
                              <th class="p-0 min-w-120px"></th>
                              <th class="p-0 min-w-160px"></th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td class="p-0 py-4">
                                  <div class="symbol symbol-50 symbol-light mr-5">
                                      <span class="symbol-label">
                                        <span [inlineSVG]="'./assets/media/svg/misc/015-telegram.svg'" class="svg-icon h-50 align-self-center"></span>
                                      </span>
                                  </div>
                              </td>
                              <td class="pl-0">
                                  <a href="#" class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Telegram Mobile</a>
                                  <span class="text-muted font-weight-bold d-block"><span class="font-weight-bolder text-dark-75">FTP:</span> bprow@bnc.cc</span>
                              </td>
                              <td class="text-right">
                                  <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                      $4,600,000
                                  </span>
                                  <span class="text-muted font-weight-bold">
                                      Paid
                                  </span>
                              </td>
                              <td class="text-right">
                                  <span class="text-muted font-weight-bold">
                                      Python, MySQL
                                  </span>
                              </td>
                              <td class="text-right">
                                  <span class="label label-lg label-light-warning label-inline">In Progress</span>
                              </td>
                              <td class="pr-0 text-right">
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/General/Settings-1.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3 cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/Communication/Write.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/General/Trash.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                            </td>
                          </tr>
                          <tr>
                              <td class="p-0 py-4">
                                  <div class="symbol symbol-50 symbol-light mr-5">
                                      <span class="symbol-label">
                                        <span [inlineSVG]="'./assets/media/svg/misc/003-puzzle.svg'" class="svg-icon h-50 align-self-center"></span>
                                      </span>
                                  </div>
                              </td>
                              <td class="pl-0">
                                  <a href="#" class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Cisco Management</a>
                                  <span class="text-muted font-weight-bold d-block"><span class="font-weight-bolder text-dark-75">FTP:</span> bprow@bnc.cc</span>
                              </td>
                              <td class="text-right">
                                  <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                      $560,000
                                  </span>
                                  <span class="text-muted font-weight-bold">
                                      Paid
                                  </span>
                              </td>
                              <td class="text-right">
                                  <span class="text-muted font-weight-bold">
                                      Laravel, Metronic
                                  </span>
                              </td>
                              <td class="text-right">
                                  <span class="label label-lg label-light-success label-inline">Success</span>
                              </td>
                              <td class="pr-0 text-right">
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/General/Settings-1.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3 cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/Communication/Write.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/General/Trash.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                            </td>
                          </tr>
                          <tr>
                              <td class="p-0 py-4">
                                  <div class="symbol symbol-50 symbol-light mr-5">
                                      <span class="symbol-label">
                                        <span [inlineSVG]="'./assets/media/svg/misc/005-bebo.svg'" class="svg-icon h-50 align-self-center"></span>                                      
                                      </span>
                                  </div>
                              </td>
                              <td class="pl-0">
                                  <a href="#" class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Beats Studio</a>
                                  <span class="text-muted font-weight-bold d-block">FTP: bprow@bnc.cc</span>
                              </td>
                              <td class="text-right pr-0">
                                  <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                      $57,000
                                  </span>
                                  <span class="text-muted font-weight-bold">
                                      Paid
                                  </span>
                              </td>
                              <td class="text-right">
                                  <span class="text-muted font-weight-bold">
                                      AngularJS, C#
                                  </span>
                              </td>
                              <td class="text-right">
                                  <span class="label label-lg label-light-danger label-inline">Rejected</span>
                              </td>
                              <td class="pr-0 text-right">
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/General/Settings-1.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3 cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/Communication/Write.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/General/Trash.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                            </td>
                          </tr>
                          <tr>
                              <td class="p-0 py-4">
                                  <div class="symbol symbol-50 symbol-light">
                                      <span class="symbol-label">
                                        <span [inlineSVG]="'./assets/media/svg/misc/006-plurk.svg'" class="svg-icon h-50 align-self-center"></span>                                                                                
                                      </span>
                                  </div>
                              </td>
                              <td class="pl-0">
                                  <a href="#" class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Sant Outstanding</a>
                                  <div>
                                      <span class="font-weight-bolder">Email:</span>
                                      <a class="text-muted font-weight-bold text-hover-primary" href="#">bprow@bnc.cc</a>
                                  </div>
                              </td>
                              <td class="text-right">
                                  <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                      $2,000,000
                                  </span>
                                  <span class="text-muted font-weight-bold">
                                      Paid
                                  </span>
                              </td>
                              <td class="text-right">
                                  <span class="text-muted font-weight-bold">
                                      ReactJs, HTML
                                  </span>
                              </td>
                              <td class="text-right">
                                  <span class="label label-lg label-light-primary label-inline">Approved</span>
                              </td>
                              <td class="pr-0 text-right">
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/General/Settings-1.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3 cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/Communication/Write.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/General/Trash.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                            </td>
                          </tr>
                          <tr>
                              <td class="p-0 py-4">
                                  <div class="symbol symbol-50 symbol-light mr-5">
                                      <span class="symbol-label">
                                        <span [inlineSVG]="'./assets/media/svg/misc/014-kickstarter.svg'" class="svg-icon h-50 align-self-center"></span>                                                                                
                                      </span>
                                  </div>
                              </td>
                              <td class="pl-0">
                                  <a href="#" class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">KTR Application</a>
                                  <span class="text-muted font-weight-bold d-block"><span class="font-weight-bolder text-dark-75">FTP:</span> bprow@bnc.cc</span>
                              </td>
                              <td class="text-right">
                                  <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                      $45,200,000
                                  </span>
                                  <span class="text-muted font-weight-bold">
                                      Paid
                                  </span>
                              </td>
                              <td class="text-right">
                                  <span class="text-muted font-weight-bold">
                                      ReactJS, Ruby
                                  </span>
                              </td>
                              <td class="text-right">
                                  <span class="label label-lg label-light-warning label-inline">In Progress</span>
                              </td>
                              <td class="pr-0 text-right">
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/General/Settings-1.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3 cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/Communication/Write.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/General/Trash.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                            </td>
                          </tr>
                      </tbody>
                  </table>
              </div>
              <!--end::Table-->
          </div>
          <!--end::Tap pane-->

          <!--begin::Tap pane-->
          <div class="tab-pane fade" role="tabpanel"
            [ngClass]="{'active show': currentTab === 'Day'}"
          >
              <!--begin::Table-->
              <div class="table-responsive">
                  <table class="table table-borderless table-vertical-center">
                      <thead>
                          <tr>
                              <th class="p-0 w-50px"></th>
                              <th class="p-0 min-w-200px"></th>
                              <th class="p-0 min-w-120px"></th>
                              <th class="p-0 min-w-120px"></th>
                              <th class="p-0 min-w-120px"></th>
                              <th class="p-0 min-w-160px"></th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td class="p-0 py-4">
                                  <div class="symbol symbol-50 symbol-light">
                                      <span class="symbol-label">
                                        <span [inlineSVG]="'./assets/media/svg/misc/006-plurk.svg'" class="svg-icon h-50 align-self-center"></span>                                                                                
                                      </span>
                                  </div>
                              </td>
                              <td class="pl-0">
                                  <a href="#" class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Sant Outstanding</a>
                                  <div>
                                      <span class="font-weight-bolder">Email:</span>
                                      <a class="text-muted font-weight-bold text-hover-primary" href="#">bprow@bnc.cc</a>
                                  </div>
                              </td>
                              <td class="text-right">
                                  <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                      $2,000,000
                                  </span>
                                  <span class="text-muted font-weight-bold">
                                      Paid
                                  </span>
                              </td>
                              <td class="text-right">
                                  <span class="text-muted font-weight-bold">
                                      ReactJs, HTML
                                  </span>
                              </td>
                              <td class="text-right">
                                  <span class="label label-lg label-light-primary label-inline">Approved</span>
                              </td>
                              <td class="pr-0 text-right">
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/General/Settings-1.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3 cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/Communication/Write.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/General/Trash.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                            </td>
                          </tr>
                          <tr>
                              <td class="p-0 py-4">
                                  <div class="symbol symbol-50 symbol-light mr-5">
                                      <span class="symbol-label">
                                        <span [inlineSVG]="'./assets/media/svg/misc/015-telegram.svg'" class="svg-icon h-50 align-self-center"></span>                                                                                
                                      </span>
                                  </div>
                              </td>
                              <td class="pl-0">
                                  <a href="#" class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Telegram Mobile</a>
                                  <span class="text-muted font-weight-bold d-block"><span class="font-weight-bolder text-dark-75">FTP:</span> bprow@bnc.cc</span>
                              </td>
                              <td class="text-right">
                                  <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                      $4,600,000
                                  </span>
                                  <span class="text-muted font-weight-bold">
                                      Paid
                                  </span>
                              </td>
                              <td class="text-right">
                                  <span class="text-muted font-weight-bold">
                                      Python, MySQL
                                  </span>
                              </td>
                              <td class="text-right">
                                  <span class="label label-lg label-light-warning label-inline">In Progress</span>
                              </td>
                              <td class="pr-0 text-right">
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/General/Settings-1.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3 cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/Communication/Write.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/General/Trash.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                            </td>
                          </tr>
                          <tr>
                              <td class="p-0 py-4">
                                  <div class="symbol symbol-50 symbol-light mr-5">
                                      <span class="symbol-label">
                                        <span [inlineSVG]="'./assets/media/svg/misc/003-puzzle.svg'" class="svg-icon h-50 align-self-center"></span>                                          
                                      </span>
                                  </div>
                              </td>
                              <td class="pl-0">
                                  <a href="#" class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Cisco Management</a>
                                  <span class="text-muted font-weight-bold d-block"><span class="font-weight-bolder text-dark-75">FTP:</span> bprow@bnc.cc</span>
                              </td>
                              <td class="text-right">
                                  <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                      $560,000
                                  </span>
                                  <span class="text-muted font-weight-bold">
                                      Paid
                                  </span>
                              </td>
                              <td class="text-right">
                                  <span class="text-muted font-weight-bold">
                                      Laravel, Metronic
                                  </span>
                              </td>
                              <td class="text-right">
                                  <span class="label label-lg label-light-success label-inline">Success</span>
                              </td>
                              <td class="pr-0 text-right">
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/General/Settings-1.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3 cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/Communication/Write.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/General/Trash.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                            </td>
                          </tr>
                          <tr>
                              <td class="p-0 py-4">
                                  <div class="symbol symbol-50 symbol-light mr-5">
                                      <span class="symbol-label">
                                        <span [inlineSVG]="'./assets/media/svg/misc/005-bebo.svg'" class="svg-icon h-50 align-self-center"></span>                                          
                                      </span>
                                  </div>
                              </td>
                              <td class="pl-0">
                                  <a href="#" class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">Beats Studio</a>
                                  <span class="text-muted font-weight-bold d-block">FTP: bprow@bnc.cc</span>
                              </td>
                              <td class="text-right pr-0">
                                  <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                      $57,000
                                  </span>
                                  <span class="text-muted font-weight-bold">
                                      Paid
                                  </span>
                              </td>
                              <td class="text-right">
                                  <span class="text-muted font-weight-bold">
                                      AngularJS, C#
                                  </span>
                              </td>
                              <td class="text-right">
                                  <span class="label label-lg label-light-danger label-inline">Rejected</span>
                              </td>
                              <td class="pr-0 text-right">
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/General/Settings-1.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3 cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/Communication/Write.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/General/Trash.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                            </td>
                          </tr>
                          <tr>
                              <td class="p-0 py-4">
                                  <div class="symbol symbol-50 symbol-light mr-5">
                                      <span class="symbol-label">
                                        <span [inlineSVG]="'./assets/media/svg/misc/014-kickstarter.svg'" class="svg-icon h-50 align-self-center"></span>                                          
                                      </span>
                                  </div>
                              </td>
                              <td class="pl-0">
                                  <a href="#" class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">KTR Application</a>
                                  <span class="text-muted font-weight-bold d-block"><span class="font-weight-bolder text-dark-75">FTP:</span> bprow@bnc.cc</span>
                              </td>
                              <td class="text-right">
                                  <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                      $45,200,000
                                  </span>
                                  <span class="text-muted font-weight-bold">
                                      Paid
                                  </span>
                              </td>
                              <td class="text-right">
                                  <span class="text-muted font-weight-bold">
                                      ReactJS, Ruby
                                  </span>
                              </td>
                              <td class="text-right">
                                  <span class="label label-lg label-light-warning label-inline">In Progress</span>
                              </td>
                              <td class="pr-0 text-right">
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/General/Settings-1.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3 cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/Communication/Write.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                                <a class="btn btn-icon btn-light btn-hover-primary btn-sm cursor-pointer">
                                  <span [inlineSVG]="'./assets/media/svg/icons/General/Trash.svg'" class="svg-icon svg-icon-md svg-icon-primary"></span>
                                </a>
                            </td>
                          </tr>
                      </tbody>
                  </table>
              </div>
              <!--end::Table-->
          </div>
          <!--end::Tap pane-->
      </div>
  </div>
  <!--end::Body-->
</div>
<!--end::Advance Table Widget 7-->
