import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment as env } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  private messageSource = new BehaviorSubject(true);
  currentMessage = this.messageSource.asObservable();

  private messageSource1 = new BehaviorSubject('addYourTeamMembers');
  currentMessage1 = this.messageSource1.asObservable();

  private modal = new BehaviorSubject<any>(null);


  constructor(
    public http: HttpClient
  ) { 
    this.openModal({data:'close'})
  }

  title = new BehaviorSubject("ClowdWork");

  getTitle() {
    return this.title;
  }

  setTitle(title) {
    this.title.next(title);
  } 

  getApiUrl() {
    return env.apiUrl;
  }

  dashboard(): Observable<any> {
    const headers = { 'content-type': 'application/json'}
    return this.http.get(`${env.apiUrl}/api/admin/dashboard`,{'headers':headers});
  }
  
  changeProfilePic(formData): Observable<any> {
    return this.http.post(`${env.apiUrl}/api/admin/user-profile-avatar`, formData);
  }

  getProfilePic(): Observable<any> {
    return this.http.get(`${env.apiUrl}/api/admin/user-profile-avatar`,{responseType: 'blob'});
  }

  changeMessage(message: boolean) {
   return this.messageSource.next(message)
  }

  changeButtonMessage(message) {
    return this.messageSource1.next(message)
   }
 
  changeLogoPic(formData): Observable<any> {
    return this.http.post(`${env.apiUrl}/api/admin/logo`, formData);
  }

  getLogoPic(): Observable<any> {
    return this.http.get(`${env.apiUrl}/api/admin/logo`,{responseType: 'blob'});
  }

  modalResult(): Observable<any> {
    return this.modal.asObservable();
  }

  openModal(data: any): void {
    this.modal.next(data);
  }

}
  