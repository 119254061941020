import { Injectable } from '@angular/core';
import { environment as env } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserGroupsService {

  constructor(
    public http: HttpClient
  ) { }

  userGroup(filter): Observable<any> {
    const headers = { 'content-type': 'application/json'}
    return this.http.get(`${env.apiUrl}/api/admin/user-groups?page=${filter.page}&per_page=${filter.per_page}`,{'headers':headers});
  }

  editGroup(data): Observable<any> {
    const headers = { 'content-type': 'application/json'}
    return this.http.put(`${env.apiUrl}/api/admin/user-groups/${data.id}`,JSON.stringify(data),{'headers':headers});
  }

  addGroup(data): Observable<any> {
    const headers = { 'content-type': 'application/json'}
    return this.http.post(`${env.apiUrl}/api/admin/user-groups`, JSON.stringify(data),{'headers':headers});
  }

  show(id): Observable<any> {
    const headers = { 'content-type': 'application/json'}
    return this.http.get(`${env.apiUrl}/api/admin/user-groups/${id}`,{'headers':headers});
    
  }

  userList(): Observable<any> {
    const headers = { 'content-type': 'application/json'}
    return this.http.get(`${env.apiUrl}/api/admin/user-groups/create`,{'headers':headers});
    
  }

  groupList(): Observable<any> {
    const headers = { 'content-type': 'application/json'}
    return this.http.get(`${env.apiUrl}/api/admin/rules/create`,{'headers':headers});
    
  }

  export(lang): Observable<Blob> {
    return this.http.get(`${env.apiUrl}/api/admin/user-groups/export?lang=${lang}`, { responseType: 'blob' });
  }
  
  search(search): Observable<any> {
    const headers = { 'content-type': 'application/json'}
    return this.http.get(`${env.apiUrl}/api/admin/user-groups?&search=${search}`,{'headers':headers});
  }

  deleteGroup(id): Observable<any> {
    const headers = { 'content-type': 'application/json'}
    return this.http.delete(`${env.apiUrl}/api/admin/user-groups/${id}`,{'headers':headers});
  }
  
  changeUser(data): Observable<any> {
    const headers = { 'content-type': 'application/json'}
    return this.http.put(`${env.apiUrl}/api/admin/user-groups/change-user`,JSON.stringify(data),{'headers':headers});
 
  }
    //additional-groups

// you must to use these endpoints:
// 1. GET {{hostname}}/api/admin/user-groups-additional/create
// 2. POST {{hostname}}/api/admin/user-groups-additional
// 3 GET {{hostname}}/api/admin/user-groups-additional/{user_id}/edit
// 4. PUT {{hostname}}/api/admin/user-groups-additional/{user_id}/
// 5 DELETE {{hostname}}/api/admin/user-groups-additional/{user_id}

additionalGroups(): Observable<any> {
  const headers = { 'content-type': 'application/json'}
  return this.http.get(`${env.apiUrl}/api/admin/user-groups-additional/create`,{'headers':headers});
}

addAdditionalGroup(data): Observable<any> {
  const headers = { 'content-type': 'application/json'}
  return this.http.post(`${env.apiUrl}/api/admin/user-groups-additional`, JSON.stringify(data),{'headers':headers});
}

additionalGroupsByid(user_id): Observable<any> {
  const headers = { 'content-type': 'application/json'}
  return this.http.get(`${env.apiUrl}/api/admin/user-groups-additional/${user_id}/edit`,{'headers':headers});
}

additionalGroupsPut(user_id,data): Observable<any> {
  const headers = { 'content-type': 'application/json'}
  return this.http.put(`${env.apiUrl}/api/admin/user-groups-additional/${user_id}/`,JSON.stringify(data),{'headers':headers});
}

deleteAdditionalGroup(user_id): Observable<any> {
  const headers = { 'content-type': 'application/json'}
  return this.http.delete(`${env.apiUrl}/api/admin/user-groups-additional/${user_id}`,{'headers':headers});
}

userGroupsByUsers(): Observable<any> {
  const headers = { 'content-type': 'application/json'}
  return this.http.get(`${env.apiUrl}/api/admin/user-groups-by-users`,{'headers':headers});
}
}
