<div class="row">
  <div class="col-xl-4">
    <app-mixed-widget4 cssClass="gutter-b card-stretch"></app-mixed-widget4>
  </div>
  <div class="col-xl-8">
    <app-base-tables-widget6 class="gutter-b"></app-base-tables-widget6>
  </div>
</div>

<div class="row">
  <div class="col-xl-4">
    <div class="row">
      <div class="col-xl-12">
        <app-stats-widget11
          cssClass="gutter-b"
          symbolShape="symbol-circle"
          baseColor="danger"
        ></app-stats-widget11>
      </div>
      <div class="col-xl-12">
        <app-stats-widget10
          cssClass="gutter-b"
          symbolShape="symbol-circle"
          baseColor="info"
        ></app-stats-widget10>
      </div>
    </div>
  </div>
  <div class="col-xl-8">
    <app-lists-widget14 cssClass="gutter-b card-stretch"></app-lists-widget14>
  </div>
</div>

<!--begin::Row-->
<div class="row">
  <div class="col-lg-12 col-xxl-12">
    <app-advance-tables-widget9
      cssClass="card-stretch gutter-b"
    ></app-advance-tables-widget9>
  </div>
</div>
<!--end::Row-->

<!--begin::Row-->
<div class="row">
  <div class="col-xl-6">
    <app-lists-widget10 cssClass="card-stretch gutter-b"></app-lists-widget10>
  </div>
  <div class="col-xl-6">
    <app-base-tables-widget1
      cssClass="card-stretch gutter-b"
    ></app-base-tables-widget1>
  </div>
</div>
<!--end::Row-->

<!--begin::Row-->
<div class="row">
  <div class="col-lg-4">
    <app-lists-widget8 cssClass="card-stretch gutter-b"></app-lists-widget8>
  </div>
  <div class="col-lg-8">
    <app-base-tables-widget2
      cssClass="card-stretch gutter-b"
    ></app-base-tables-widget2>
  </div>
</div>
<!--end::Row-->
