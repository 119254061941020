<div class="card card-custom card-stretch gutter-b">
  <div class="card-header border-0 pt-5">
    <h3 class="card-title font-weight-bolder">Action Needed</h3>
    <div class="card-toolbar">
      <div
        class="dropdown dropdown-inline"
        ngbDropdown
        placement="bottom-right"
        class="dropdown dropdown-inline"
        title="Quick actions"
      >
        <a
          ngbDropdownToggle
          class="btn btn-clean btn-hover-light-primary btn-sm btn-icon cursor-pointer"
        >
          <i class="ki ki-bold-more-hor"></i>
        </a>
        <div
          class="dropdown-menu dropdown-menu-sm dropdown-menu-right"
          ngbDropdownMenu
        >
          <app-dropdown-menu4></app-dropdown-menu4>
        </div>
      </div>
    </div>
  </div>
  <!-- Body -->
  <div class="card-body d-flex flex-column">
    <div class="flex-grow-1">
      <div id="kt_mixed_widget_14_chart" style="height: '200px';">
        <apx-chart
          [series]="chartOptions.series"
          [chart]="chartOptions.chart"
          [xaxis]="chartOptions.xaxis"
          [yaxis]="chartOptions.yaxis"
          [dataLabels]="chartOptions.dataLabels"
          [stroke]="chartOptions.stroke"
          [legend]="chartOptions.legend"
          [fill]="chartOptions.fill"
          [states]="chartOptions.states"
          [tooltip]="chartOptions.tooltip"
          [colors]="chartOptions.colors"
          [markers]="chartOptions.markers"
          [plotOptions]="chartOptions.plotOptions"
        ></apx-chart>
      </div>
    </div>
    <div class="pt-5">
      <p class="text-center font-weight-normal font-size-lg pb-7">
        Notes: Current sprint requires stakeholders<br />
        to approve newly amended policies
      </p>
      <a
        class="btn btn-success btn-shadow-hover font-weight-bolder w-100 py-3 cursor-pointer"
        >Generate Report</a
      >
    </div>
  </div>
</div>
