<div class="card card-custom bg-gray-100 card-stretch gutter-b">
  <!-- Header -->
  <div class="card-header border-0 bg-danger py-5">
    <h3 class="card-title font-weight-bolder text-white dash_heading" >{{Productividad}} <i ngbTooltip="{{ProToolTip}}" class="fa fa-info-circle ml-5 protooltip"></i></h3>
    <div class="card-toolbar">
      <div
        ngbDropdown
        placement="bottom-right"
        class="dropdown dropdown-inline"
        title="Quick actions"
      >
        <!-- <a
          ngbDropdownToggle
          class="btn btn-transparent-white btn-sm font-weight-bolder dropdown-toggle px-5 dropdown-toggle btn btn-transparent"
        >
          Export
        </a> -->
        <div
          ngbDropdownMenu
          class="dropdown-menu dropdown-menu-sm dropdown-menu-right"
        >
          <app-dropdown-menu2></app-dropdown-menu2>
        </div>
      </div>
    </div>
  </div>
  <!-- Body -->
  <div class="card-body p-0 position-relative overflow-hidden">
    <!--begin::Chart-->
    <div
      id="kt_mixed_widget_1_chart"
      class="card-rounded-bottom bg-danger"
      [style.height]="'200px'"
    >
      <apx-chart
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [xaxis]="chartOptions.xaxis"
        [yaxis]="chartOptions.yaxis"
        [dataLabels]="chartOptions.dataLabels"
        [stroke]="chartOptions.stroke"
        [legend]="chartOptions.legend"
        [fill]="chartOptions.fill"
        [states]="chartOptions.states"
        [tooltip]="chartOptions.tooltip"
        [colors]="chartOptions.colors"
        [markers]="chartOptions.markers"
        [plotOptions]="chartOptions.plotOptions"
      ></apx-chart>
    </div>  
    <!--end::Chart-->
    <!--begin::Stats-->
    <div class="card-spacer mt-n25">
      <!--begin::Row-->
      <span class="dash_heading" style="font-size: 120%; font-weight: 1000; position: absolute; margin: 20% 0% 0% 0%;"  placement="right">{{podium}} <i ngbTooltip="{{podiumTooltip}}" class="fa fa-info-circle ml-5 podium_tooltip "></i></span>
      <div class=" m-0" style="padding-top: 10%;
      ">
        <div class="col bg-light-warning rounded-xl" style="position: relative; padding: 2% 0% 2% 0%; max-width: 41%; margin: 26% 0% 0% 30%; text-align: center;">
          <div class="winner" style="text-align: center; margin-bottom: 10%;">
            <img style="width: 30%;" src= './assets/winner-1.png'/>
          </div>
          <a href="#" class="text-warning font-weight-bold font-size-h6 justify-content-center">
            {{first.name}} <i ngbTooltip="{{totalhourToolTip}}" class="fa fa-info-circle ml-5 podium_tooltip"></i><br>
          <span > {{first.productive_time_sum}} </span></a> 
         
        </div>

        <div class="col bg-light-primary rounded-xl" style=" position: inherit; padding: 2% 0% 2% 0%; max-width: 41%; text-align: center; margin: -17% 0% 0% -5%;" >
          <div class="winner" style="text-align: center; margin-bottom: 10%;" >
            <img style="width: 30%;" src= './assets/winner-2.png'/>
          </div>
          <a href="#" class="text-primary font-weight-bold font-size-h6 mt-2">
            {{second.name}} <i ngbTooltip="{{totalhourToolTip}}" class="fa fa-info-circle ml-5 podium_tooltip"></i><br>
            <span  placement="right">{{second.productive_time_sum}} </span> </a>
         
        </div>
        <div class="col bg-light-success rounded-xl" style="position: inherit; padding: 2% 0% 2% 0%; max-width: 39%; margin: -24% 0% 0% 66%; text-align: center; ">
          <div class="winner" style="text-align: center; margin-bottom: 10%;">
            <img style="width: 30%;" src= './assets/winner-3.png'/>
          </div>
          <a href="#" class="text-success font-weight-bold font-size-h6 mt-2">
            {{third.name}} <i ngbTooltip="{{totalhourToolTip}}" class="fa fa-info-circle ml-5 podium_tooltip"></i><br>
            <span  placement="left">{{third.productive_time_sum}} </span> </a>
         
        </div>
      </div>
      <!--end::Row-->
      <!--begin::Row-->
      <div class="row m-0">
        <!-- <div class="col bg-light-danger px-6 py-8 rounded-xl mr-7">
          <span
            [inlineSVG]="'./assets/media/svg/icons/Design/Layers.svg'"
            class="svg-icon svg-icon-3x svg-icon-danger d-block my-2"
          ></span>
          <a href="#" class="text-danger font-weight-bold font-size-h6 mt-2">
            Item Orders
          </a>
        </div> -->
      </div>
      <!--end::Row-->
    </div>
    <!--end::Stats-->
  </div>
</div>
