<ng-container *ngIf="demo === 'demo1'">
  <app-dashboard1></app-dashboard1>
</ng-container>

<ng-container *ngIf="demo === 'demo2'">
  <app-dashboard2></app-dashboard2>
</ng-container>


<ng-container *ngIf="demo === 'demo3'">
  <app-dashboard3></app-dashboard3>
</ng-container>
