import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';

import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AuthService } from './modules/auth/_services/auth.service';
import { environment } from 'src/environments/environment';
// Highlight JS
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { SplashScreenModule } from './_metronic/partials/layout/splash-screen/splash-screen.module';
// #fake-start#
import { FakeAPIService } from './_fake/fake-api.service';
import { ComponentsModule } from './components/components.module';
// #fake-end#

import { ToastrModule } from 'ngx-toastr';

//import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { DataTablesModule } from 'angular-datatables';

import {NgxUploadModule} from '@wkoza/ngx-upload';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';
import { DashboardsModule } from './_metronic/partials/content/dashboards/dashboards.module';
import { LightboxModule } from 'ngx-lightbox';


/*function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      authService.getUserByToken().subscribe().add(resolve);
    });
  };
}*/

/*export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}*/

@NgModule({
  declarations: [AppComponent],
  imports: [
    LightboxModule,
    BrowserModule,
    NgbModule,
    BrowserAnimationsModule,
    SplashScreenModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    HighlightModule,
    ClipboardModule,
    // #fake-start#
    /*environment.isMockEnabled
      ? HttpClientInMemoryWebApiModule.forRoot(FakeAPIService, {
        passThruUnknownUrl: true,
        dataEncapsulation: false,
      })
      : [],*/
    // #fake-end#
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    ComponentsModule,
    NgSelectModule,
    FormsModule, 
    ToastrModule.forRoot(), // ToastrModule added  
    DataTablesModule,
    NgxUploadModule.forRoot(),
    DashboardsModule 

  ],
  providers: [
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
          json: () => import('highlight.js/lib/languages/json')
        },
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: LocationStrategy, 
      useClass: HashLocationStrategy
    } ,
    TranslatePipe

  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
