import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lists-widget4',
  templateUrl: './lists-widget4.component.html',
})
export class ListsWidget4Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
