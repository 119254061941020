import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[module]'
})
export class ModuleDirective {
  private appModules = [];  

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
    this.appModules = JSON.parse(localStorage.getItem('modules'));
   // console.log('module',this.appModules);
   }

   @Input()
   set module(val) {
     if(this.appModules.find(module=>val==module.slug)) {
       this.viewContainer.createEmbeddedView(this.templateRef);
     } else {
       this.viewContainer.clear();
     }
   }

}
