// USA
export const locale = {
  lang: 'pt',
  data: {
    'Agregar Tiempo Manual': 'Adicionar hora manual',
    'Actividad por Usuario': 'Atividade do usuário',
    'Uso de Aplicaciones': 'Uso do aplicativo',
    'Tiempo Trabajado': 'Tempo trabalhado',
    'Capturas de Pantalla': 'Capturas de tela',
    'Reportes de Productividad': 'Relatórios de controle de tempo',
    'Usuarios': 'Usuários',
    'Grupos de Usuarios': 'Grupos de usuários',
    'Ventana de Tiempo': 'Janela de tempo',
    'Configuración de Seguridad': 'Configurações de segurança',
    'Aplicaciones': 'Aplicações',
    'Grupos de Aplicaciones': 'Grupos de aplicativos',
    'Empresas Involucradas': 'Partes Interessadas',
    'Roles': 'Funções',
    'Configuración de Cliente': 'Configuração do cliente',
    'Colector de Logs': 'Log Collector',
    'Papelera': 'Lixo',
    'Pagos Recibidos': 'Pagamentos',
    'Planilla Automática de Horas': 'Quadro de Horários Automático',  
    'Productividad Diaria': 'Produtividade diária',
    'Ante.': 'Ante.',
    'Prox.': 'Próx.',
    'Hoy': 'Hoje',
    'Mes': 'Mês',
    'Semana': 'Semana',
    'Día': 'Dia',
    'Año': 'Ano',
    'Guardar': 'Salvar',
    'Cerrar': 'Fechar',
    'Nombre': 'Nome',
    'Fecha': 'Encontro',
    'Hora inicio': 'Hora de início',
    'Hora finalización': 'Fim do tempo',
    'Ninguna': 'Nenhum',
    'Seleccione una tarea': 'Selecione uma tarefa',
    'Entrar': 'Entrar',
    'Perfil': 'Perfil',
    'Mi Perfil': 'Meu perfil',
    'Inactividad': 'Inatividade',
    'Privado': 'Privado',
    'En reunión': 'Em uma reunião',
    'Tiempo manual': 'Tempo manual',
    'Sin categorizar': 'não categorizado',
    'No productivo': 'Não produtivo',
    'Productivo': 'Produtivo',
    'Reunión': 'Tempo manual',
    'Reuniones':'Reuniones',
    'Filtros': 'Filtros',
    'Nombre de la aplicación': 'Nome da Aplicação',
    'Descripción': 'Descrição',
    'Duración': 'Duração',
    'horas': 'horas',
    'Añadir nuevo usuario': 'Adicionar novo usuário',
    'Importar Excel': 'Importação do Excel',
    'Grupos': 'Grupos',
    'Editar': 'Editar',
    'Si': 'Sim',
    'Rango de horas':'Intervalo de horas',
    '¿Quieres eliminar este usuario de forma permanente?': 'Deseja excluir este usuário permanentemente?',
    'Cancelar': 'Cancelar',
    'Crear': 'Para criar',
    'Confirmar contraseña': 'Confirme a Senha',
    'Estoy de acuerdo con los': 'concordo com aqueles',
    'términos y condiciones.': 'termos e Condições.',
    'Estoy de acuerdo con los terminos y condiciones': 'Eu concordo com os termos e condições',
    'Configuración de seguridad': 'Configurações de segurança',
    'correo electronico es requerido':'e-mail é obrigatório',
    'el correo electrónico es invalido': 'o e-mail é inválido',
    'Se requiere el nombre':'o nome é obrigatório',
    'El nombre debe tener al menos 2 caracteres':'O nome deve ter pelo menos 2 caracteres',
    'El nombre no debe exceder los 14 caracteres' :'O nome não deve exceder 14 caracteres',
    'Se requiere empresa': 'empresa é necessária',
    'Se requiere rol' :'o papel é obrigatório',
    'Estado': 'Doença',
    'Activo':'Ativo',
    'Inactivo': 'Inativo',
    'Se requiere estado' :'o status é obrigatório',
    'Se requiere tarifa por hora' :'Taxa horária necessária',
    'Se requiere límite semanal' :'Limite semanal obrigatório',
    'se requiere contraseña':'Senha requerida',
    'La contraseña debe tener al menos 6 caracteres':'A senha deve ter pelo menos 6 caracteres',
    'La contraseña no debe exceder los 20 caracteres' :'A senha não deve exceder 20 caracteres',
    'Repita la contraseña':'Repita a senha',
    'Se requiere repetir la contraseña':'Repetir senha necessária',
    'Repetir la contraseña no coincide con la contraseña':'A senha repetida não corresponde à senha',
    'Introduce la contraseña para actualizar':'Digite a senha para atualizar',
    'Archivo Excel': 'arquivo Excel',
    'Agregar grupos adicionales' :'Adicionar grupos adicionais',
    'Seleccione al menos un grupo':'Selecione pelo menos um grupo',
    'Rol': 'Função',
    'Tarifa por Hora':'Taxa horária',
    'Límite Semanal (Horas)':'Limite semanal (horas)',
    'Primera Conexión':'Primeira conexão',
    'Ultima Conexión':'Última conexão',
    'Estatus de Usuario':'Status do usuário',
    'Modo del Agente':'Modo de agente',
    'Acciones':'Ações',
    'Desactivado':'Desativado',
    'Activado':'Ativado',
    'Interactivo':'Interativo',
    'Silencioso':'Silencioso',
    'Empresa':'O negócio',
    'Correo electrónico': 'Correio eletrônico',
    'Contraseña':'Senha',
    'Usuario nuevo':'Novo usuário',
    'editar usuario':'Editar usuário',
    'Se requiere usuario':'Usuário obrigatório',
    'El grupo de usuarios es obligatorio':'O grupo de usuários é obrigatório',
    'Productiva':'Produtivo',
    'No Productiva':'Não produtivo',
    'Sin Categorizar':'Sem categoria',
    'Empresas':'Empresas',
    'Descargas':'Baixar',
    'Ayuda':'Ajuda',
    'Inducción':'Indução',
    'Salir':'Sair',
    'Añadir nuevo grupo':'Adicionar novo grupo',
    'No puedes eliminar este registro porque tiene informacion asociada':'Você não pode excluir este registro porque ele possui informações associadas',
    'ordenar por':'Organizar por',
    'Añadir nuevo log':'Adicionar novo registro',
    'Log Solicitado':'Registro solicitado',
    'Fecha/Hora Solicitud':'Solicitação de data/hora',
    'Recibido':'Recebido',
    'Log agente':'registro do agente',
    'Base de datos':'Base de dados',
    'Monitor':'Exibição',
    'Descargar':'Descarga',
    'Eliminar':'Remover',
    '(Solo para despliegues con Microsoft Active Directory)':'(Para uso apenas com o Microsoft Active Directory)',
    'Tiempo Manual ':'Tempo manual',
    'No Productivo': 'Não produtivo',
    'Horas': 'Horas',
    'se requiere grupo de usuarios':'grupo de usuários necessário',
    'Lunes':'Segunda-feira',
    'Martes':'Terça-feira',
    'Miércoles':'Quarta-feira',
    'Jueves':'Quinta-feira',
    'Viernes':'Sexta-feira',
    'Sábado':'Sábado',
    'Domingo':'Domingo',
    'Hasta':'Até',
    'Desde':'Desde',
    'Buscar':'Procurar',
    'Permisos':'permissões',
    'Tiempo Reunión':'Reunião do tempo',
    'Productividad':'Produtividade',
    'No hay registros':'não há registros',
    'Actualizar usuario':'Actualizar usuario',
    'Grupo':'Conjunto',
    'Editar Grupo':'Editar Grupo',
    '¿Quieres eliminar este grupo de forma permanente?': 'Deseja excluir este grupo permanentemente?',
    '¿Quieres eliminar este regla de forma permanente?': 'Deseja excluir esta regra permanentemente?',
    'Uso':'Usar',
    'Tiempo total':'tempo total',
    'Tiempo':'Tempo',
    'Promedio productividad':'produtividade média',
    'Revisar':'Verificar',
    'Tiempo Manual del Sistema':'Tempo manual',
    'Tiempo Manual del Sistemaa':'Reunión',
    'Desintalador de Windows':'Desinstalador do Windows',
    'Si tu trabajo o el de tu equipo involucra actividades fuera del computador, esta actividad puede agregarse en el calendario de ClowdWork para ser agregado al reporte de actividad.':"Se o seu trabalho ou o da sua equipa envolve atividades fora do computador, esta atividade pode ser adicionada ao calendário ClowdWork para ser adicionado ao relatório de atividades.",
    "Solo tienes que hacer clic sobre la fecha y agregar el nombre de la actividad y las horas invertidas en ella. Esta información se visualizará en los reportes de productividad.":"Você só precisa clicar na data e adicionar o nome da atividade e as horas gastas nela. Essas informações serão exibidas nos relatórios de produtividade.",
    "El administrador podrá visualizar fácilmente las actividades fuera de línea de sus colaboradores a través de los filtros en los reportes.":"O administrador poderá visualizar facilmente as atividades offline de seus colaboradores através dos filtros dos relatórios.",
    "assets/media/help/manualtimesp.jpg":"assets/media/help/manualtimesp.jpg",
    "assets/media/help/manualtime1sp.jpg":"assets/media/help/manualtime1sp.jpg",
    "Más":"Mais",
    "Menos":"Menos",
    "Abrir": "Abrir",
    "Enero" : "Janeiro",
    "Febrero" : "Fevereiro",
    "Marzo" : "Marchar",
    "Abril" : "Abril",
    "Mayo" : "Poderia",
    "Junio" : "Junho",
    "Julio" : "Julho",
    "Agosto" : "Agosto",
    "Septiembre" : "Septiembre",
    "Octubre" : "Outubro",
    "Noviembre" : "novembro",
    "Diciembre" : "dezembro",
    "la fecha es": "A data é",
    "¡Hola": "Olá",
    "Actualización del perfil": "Actualización del perfil",
    "Fecha de nacimiento": "Data de nascimento",
    "Usuario eliminado con éxito": "Usuário excluído com sucesso",
    "La configuración de los usuarios se puede gestionar fácilmente en la plataforma.":"As configurações do usuário podem ser facilmente gerenciadas na plataforma.",
    "Los usuarios y las capturas de pantalla pueden activarse o desactivarse fácilmente, según sea necesario, haciendo clic en la columna correspondiente.":"Usuários e capturas de tela podem ser facilmente ativados ou desativados conforme necessário, clicando na coluna correspondente.",
    'Los usuarios pueden ser eliminados en la columna "Acciones". Haciendo clic en el icono del "bolígrafo", se pueden modificar los ajustes de cada usuario, como se muestra en la siguiente imagen:':'Os usuários podem ser removidos na coluna "Ações". Ao clicar no ícone "caneta", é possível modificar as configurações de cada usuário, conforme a imagem a seguir:',
    'assets/media/help/users1sp.gif':'assets/media/help/users1sp.gif',
    'assets/media/help/users2sp.gif':'assets/media/help/users2sp.gif',
    "La plataforma Clowdwork permite crear fácilmente grupos o departamentos de la empresa para poder visualizar los informes por áreas. Los usuarios pueden ser transferidos de un grupo a otro simplemente arrastrando el ícono del usuario a cualquier grupo deseado.":"A plataforma Clowdwork permite criar facilmente grupos ou departamentos da empresa para poder visualizar os relatórios por áreas. Os usuários podem ser transferidos de um grupo para outro simplesmente arrastando o ícone do usuário para qualquer grupo desejado.",
    "Los grupos pueden ser creados fácilmente y los usuarios pueden ser eliminados fácilmente y sus nombres pueden ser cambiados si es necesario":"Os grupos podem ser facilmente criados e os usuários podem ser facilmente excluídos e seus nomes podem ser alterados, se necessário",
    'assets/media/help/group1sp.gif':'assets/media/help/group1sp.gif',
    "Usuario añadido con éxito":"Usuário adicionado com sucesso",
    "Usuario actualizado con éxito":"Usuário atualizado com sucesso",
    'Api no encontrada para agregar grupos de usuarios':'API não encontrada para adicionar grupos de usuários',
    'Grupo eliminado con éxito.':'Grupo excluído com sucesso.',
    'El grupo cambió con éxito.':'O grupo foi alterado com sucesso.',
    'Algo salió mal':'algo deu errado',
    'Grupo añadido con éxito':'Grupo adicionado com sucesso',
    'Grupo actualizado con éxito.':'Grupo atualizado com sucesso.',
    "Una ventana de tiempo es un período entre una hora de inicio y una hora de finalización en la cual, no se generarán reportes para toda la empresa, grupo o usuario seleccionado.":"Uma janela de tempo é um período entre uma hora de início e uma hora de término em que não serão gerados relatórios para toda a empresa, grupo ou usuário selecionado.",
    'assets/media/help/windowtracking1sp.gif':'assets/media/help/windowtracking1sp.gif',
    'La funcionalidad de ventana de tiempo, está preconfigurada para que siga una jerarquía entre los periodos / reglas de tiempo.':'A funcionalidade da janela de tempo é pré-configurada para seguir uma hierarquia entre períodos de tempo/regras.',
    "La jerarquía de los periodos es la siguiente:":"A hierarquia dos períodos é a seguinte:",
    'Días':'Dias',
    'es la primera.':'é o primeiro.',
    'es la segunda, solapa a Días.':'é o segundo, aba para Dias.',
    'es la tercera, solapa a Días y a Semana.':'é o terceiro, sobrepõe Dias e Semana.',
    'Por ejemplo, si establece en':'Por exemplo, se você definir como ',
     'que todos los sábados de 15:00 a 23:59, entonces todos los sábados estará inhabilitado el registro de actividades en ese intervalo de horas.':'que todos os sábados das 15h00 às 23h59, então todos os sábados o registro das atividades nesse intervalo de horas será desabilitado.',
     'assets/media/help/windowtracking2sp.jpg':'assets/media/help/windowtracking2sp.jpg',
     'assets/media/help/windowtracking3sp.jpg':'assets/media/help/windowtracking3sp.jpg',
     'assets/media/help/windowtracking4sp.jpg':'assets/media/help/windowtracking4sp.jpg',
     'Si luego establece en':'Se você então definir para',
     "que todos los fines de semana de 15:00 a 23:59, entonces prevalece esta regla y todos los fines de semana estará inhabilitado el registro de actividades en ese intervalo de horas.":"que todo final de semana das 15h00 às 23h59, então esta regra prevalece e todo final de semana o registro de atividades nesse intervalo de tempo será desabilitado.",
     "Por último, si establece una regla de":"Finalmente, se você definir uma regra de",
     "de 15:00 a 23:59, entonces en el día que eligió en la fecha  estará inhabilitado el registro de actividades en ese intervalo de horas.":"das 15h00 às 23h59, então no dia que você escolheu na data, o registro das atividades será desabilitado naquele intervalo de horas.",
     "En el ejemplo de prueba se establecio el domingo 7-01-2023, entonces, esta regla solapará a la regla de Semana para este día en específico.":"No exemplo de teste, foi definido domingo 01-07-2023, portanto, esta regra substituirá a regra da semana para este dia específico.",
     'Antes de crear las reglas de tiempo, valide que la hora establecida en el campo':'Antes de criar as regras de tempo, valide se o tempo definido no campo',
     'sea la correcta (cada navegador muestra el formato de hora diferente - 24 hrs  / AM -PM).':'está correto (cada navegador exibe um formato de hora diferente - 24 horas / AM -PM).',
     'Nota':'Observação',
     'Las reglas de tiempo creadas se aplicarán después del próximo reinicio de la PC del usuario. Este reinicio es necesario para que el agente tome las configuraciones asignadas desde el módulo de ventana de tiempo.':"As regras de tempo criadas serão aplicadas após a próxima reinicialização do PC do usuário. Essa reinicialização é necessária para que o agente assuma as configurações atribuídas no módulo de janela de tempo.",
    "VENTANA DE TIEMPO":"JANELA DE TEMPO", 
    "Todos los grupos":"Todos os grupos",
    "Todos los usuarios":"Todos os usuários",
    "Crear regla":"criar regra",
    "Filtrar reglas":"Regras de filtro",
    "Nombre regla":"nome da regra",    
    "Inicio":"Inicio",    
    "Fin":"O fim",   
    "Seguimiento":"rastreamento",    
    "Período":"Período",    
    "Usuario":"Do utilizador",
    "Ver":"Ver",
    "Deshabilitar seguimiento":"Desativar rastreamento",
    "Días de semana":"Dias da semana",
    "Fines de semana":"Fins de semana",
    "Ver regla":"ver regra",
    "Editar regla": "editar regra",
    "Grupo de usuarios":"grupo de usuários",
    'Software de seguimiento de tiempo':'software de rastreamento de tempo',
    'El informe de actividad de los usuarios le permite ver la cantidad de tiempo que cualquier miembro del equipo invierte en actividades productivas e improductivas, actividades privadas, trabajo fuera del ordenador y su tiempo de inactividad.':'O Relatório de atividades do usuário permite que você veja a quantidade de tempo que qualquer membro da equipe gasta em atividades produtivas e improdutivas, atividades privadas, trabalhando longe do computador e seu tempo ocioso.',
    'Todos los informes pueden descargarse fácilmente en formato PDF o CSV con un solo clic. La opción de descarga se habilitará una vez que los informes estén disponibles.':'Todos os relatórios podem ser facilmente baixados em formato PDF ou CSV com um clique. A opção de download será habilitada assim que os relatórios estiverem disponíveis.',
    'Descargue ahora el software de productividad automática de ClowdWork:':'Baixe agora o software de produtividade automática da ClowdWork:',
    'assets/media/help/useractivity1sp.png':'assets/media/help/useractivity1sp.png',
    'Este informe muestra la actividad de los usuarios. Puedes ampliar cualquier momento específico en el gráfico y podrás ver la actividad en ese marco de tiempo.':'Este relatório mostra a atividade do usuário. Você pode ampliar qualquer horário específico no gráfico e poderá ver a atividade nesse período de tempo.',
    'assets/media/help/useractivity2sp.png':'assets/media/help/useractivity2sp.png',
    'assets/media/help/useractivity3sp.png':'assets/media/help/useractivity3sp.png',
    'assets/media/help/useractivity4sp.png':'assets/media/help/useractivity4sp.png',
    'El informe de actividad de los usuarios puede filtrarse por usuario, grupo o fecha para que pueda acceder fácilmente a la información que necesita.':'O Relatório de atividades do usuário pode ser filtrado por usuário, grupo ou data para que você possa acessar facilmente as informações de que precisa.',
    'Actividad por Usuario (Rango de Horas)':'Atividade por usuário (intervalo de horas)',
    'ClowdWork realiza un seguimiento automático de la actividad de los usuarios y proporciona métricas de productividad, ayudándole a identificar el tiempo invertido en actividades que añaden valor a su negocio':'O ClowdWork rastreia automaticamente a atividade do usuário e fornece métricas de produtividade, ajudando você a identificar o tempo gasto em atividades que agregam valor ao seu negócio',
    'Todos los informes se pueden descargar fácilmente en formato PDF o CSV con un solo clic. El botón de descarga estará disponible una vez que descargue el software':'Todos os relatórios podem ser facilmente baixados em formato PDF ou CSV com um clique. O botão de download estará disponível assim que você baixar o software',
    'PARA COMENZAR A GENERAR METRÍCAS DEBES DESCARGAR EL AGENTE AUTOMÁTICO DE PRODUCTIVIDAD EN EL SIGUIENTE ENLACE':'PARA COMEÇAR A GERAR MÉTRICAS VOCÊ DEVE BAIXAR O AGENTE DE PRODUTIVIDADE AUTOMÁTICO NO LINK A SEGUIR',
    'assets/media/help/dailypro1sp.png':'assets/media/help/dailypro1sp.png',
    'assets/media/help/dailypro2sp.png':'assets/media/help/dailypro2sp.png',
    'assets/media/help/dailypro3sp.png':'assets/media/help/dailypro3sp.png',
    'Este informe le permite ver el tiempo dedicado a actividades productivas (medido por la barra de la izquierda en horas), el tiempo no productivo, el tiempo manual o el tiempo fuera del ordenador y el porcentaje de productividad (la línea roja medida por la barra de porcentaje de la derecha) de cada uno de los miembros de su equipo.':'Este relatório permite visualizar o tempo gasto em atividades produtivas (medido pela barra à esquerda em horas), tempo não produtivo, tempo manual ou longe do computador e o percentual de produtividade (a linha vermelha medida pelo barra à direita) de cada um dos membros de sua equipe.',
    'En la parte inferior puedes ver el informe en formato de tabla.':' Na parte inferior você pode ver o relatório em formato de tabela.',
    'La columna de productividad muestra el porcentaje de productividad asociado a cada día para el usuario o área seleccionada. Este porcentaje se calcula en función de las horas de trabajo de cada empresa, que se pueden configurar en la casilla situada junto al título de la columna de productividad, en el extremo derecho de la tabla.':'A coluna Produtividade exibe a porcentagem de produtividade associada a cada dia para o usuário ou área selecionada. Esse percentual é calculado com base na jornada de trabalho de cada empresa, que pode ser configurada na caixa ao lado do título da coluna produtividade, no canto direito da tabela.',
    'El informe de productividad diaria dispone de un sistema de búsqueda que permite filtrar la información (por usuario, grupo o área y fecha) para ver sólo los datos que necesita.':'O relatório diário de produtividade possui um sistema de busca que permite filtrar as informações (por usuário, grupo ou área e data) para ver apenas os dados que você precisa.',
    'Este informe hace un seguimiento del uso del tiempo de trabajo ya que muestra todas las aplicaciones, herramientas, software y sitios web a los que se ha accedido, especificando el tiempo exacto invertido en cada uno de ellos. ClowdWork hace un seguimiento automático de todos los miembros del equipo, independientemente de su ubicación, y ofrece un desglose por URL o aplicación para que puedas identificar fácilmente si el tiempo de trabajo':'Este relatório rastreia o uso do tempo de trabalho, mostrando todos os aplicativos, ferramentas, softwares e sites acessados, especificando o tempo exato gasto em cada um deles. O ClowdWork rastreia automaticamente todos os membros da equipe, independentemente da localização, e fornece uma divisão por URL ou aplicativo para que você possa identificar facilmente se o horário de trabalho é',
    'Todos los informes pueden descargarse en PDF o Excel haciendo clic en los botones verdes en la parte superior derecha, los cuales aparecerán una vez se hayan generado las métricas:':'Todos os relatórios podem ser baixados em PDF ou Excel clicando nos botões verdes no canto superior direito, que aparecerão assim que as métricas forem geradas:',
    'PARA COMENZAR A GENERAR MÉTRICAS DEBES DESCARGAR EL AGENTE AUTOMÁTICO DE PRODUCTIVIDAD EN EL SIGUIENTE ENLACE':'PARA COMEÇAR A GERAR MÉTRICAS VOCÊ DEVE BAIXAR O AGENTE DE PRODUTIVIDADE AUTOMÁTICO NO LINK A SEGUIR',
    'Haciendo clic en el botón que aparece junto a cada aplicación, podrás ver una lista detallada de todos los archivos, URLs y apps que ha utilizado cualquier miembro del equipo y el tiempo que ha dedicado a cada uno. Haciendo clic en el menú de la parte superior, podrás ver la actividad por categorías: Productiva, no productiva, sin categorizar o actividad sin conexión.':'Ao clicar no botão ao lado de cada aplicativo, você poderá ver uma lista detalhada de todos os arquivos, URLs e aplicativos que qualquer membro da equipe usou e o tempo gasto em cada um. Ao clicar no menu na parte superior, você poderá ver a atividade por categorias: produtiva, não produtiva, sem categoria ou offline.',
    'El segundo botón permite clasificar cada aplicación, como productiva, no productiva o sin categorizar, para toda la organización, un grupo o un usuario. Con base en esta clasificación se generan las métricas de productividad. En la parte derecha se muestra un gráfico que muestra cómo se divide el uso del tiempo laboral en términos porcentuales.':'O segundo botão permite classificar cada aplicação, como produtiva, não produtiva ou não categorizada, para toda a organização, um grupo ou um usuário. Com base nessa classificação, são geradas as métricas de produtividade. No lado direito está um gráfico que mostra como o uso do tempo de trabalho é dividido em termos percentuais.',
    'assets/media/help/appuse1sp.png':'assets/media/help/appuse1sp.png',
    'assets/media/help/appuse2sp.png':'assets/media/help/appuse2sp.png',
    'Datos no disponibles':'dados não disponíveis',
    'Cambiar logotipo':'mudar logotipo',
    'Cargar imagen':'Enviar Imagem',
    'Su categoria':'sua categoria',
    'Cliente': 'Cliente',
    'Agregar cliente': 'adicionar cliente',
    'Vista':'Visão',
    'Nombre de empresa': 'Nome de empresa',
    'Módulos':'módulos',
    'Administrador de usuarios': 'gerenciador de usuários',
    'Es compañero':'é parceiro',
    'nombre de host': 'nome de anfitrião',
    'licencia de cantidad':'licença de quantidade',
    'Cliente creado con éxito': 'Cliente criado com sucesso',
    'Ver cliente':'ver cliente',
    'ID de idioma':'ID do idioma',
    'Solicitar demostración':'solicitar demonstração',
    'Se ha enviado la solicitud.':'A solicitação foi enviada.',
    'Solicite una demostración': 'solicite uma demonstração',
    'Actualizar hora manual':'Atualizar hora manualmente',
    '¿Está seguro de que desea eliminar este evento?':'Tem certeza de que deseja excluir este evento?',
    'Evento añadido con éxito':'Evento adicionado com sucesso',
    'Tiempo en reuniones':'tempo em reuniões',
    'Tablero':'Quadro',
    'Induccion':'Indução',
    'Cambiar':'Mudar',
    'Cargo':'Posição',
    'Aceptar': 'Aceitar',
    'Cambiar contraseña': 'Mudar senha',
    'Repetir contraseña':'repetir a senha',
    'Nueva contraseña':'Nova senha',
    'Contraseña anterior':'Senha Antiga',
    'Cambio':'Mudar',
    'Versión OS X':'Versão OS X',
    'Versión Linux':'versão linux',
    'Versión Windows':'versão Windows',
    'Desde OS 10.11 en adelante':'Do OS 10.11 em diante',
    'Desde Ubuntu 18.04 en adelante':'Do Ubuntu 18.04 em diante',
    'Desde Windows 7 en adelante':'Do Windows 7 em diante',
    'El informe de tiempo trabajado muestra la cantidad de tiempo invertido en actividades productivas, no productivas, manuales o fuera de línea.':'O relatório Time Worked mostra a quantidade de tempo gasto em atividades produtivas, não produtivas, manuais ou offline.',
    'Todos los informes se pueden descargar fácilmente en formato PDF o CSV con un solo clic. El botón de descarga estará disponible una vez que descargue el software.':'Todos os relatórios podem ser facilmente baixados em formato PDF ou CSV com um clique. O botão de download estará disponível assim que você baixar o software.',
    'PARA COMENZAR A GENERAR METRÍCAS DEBES DESCARGAR EL AGENTE AUTOMÁTICO DE PRODUCTIVIDAD EN EL SIGUIENTE ENLACE:':'PARA COMEÇAR A GERAR MÉTRICAS VOCÊ DEVE BAIXAR O AGENTE DE PRODUTIVIDADE AUTOMÁTICO NO SEGUINTE LINK:',
    'assets/media/help/windowtrack1sp.png':'assets/media/help/windowtrack1sp.png',
    'assets/media/help/windowtrack2sp.png':'assets/media/help/windowtrack2sp.png',
    'Para cada miembro del equipo, se puede mostrar el total de horas trabajadas por día. La línea verde permite comparar entre usuarios el tiempo trabajado en el rango seleccionado. La línea roja vertical muestra el valor medio trabajado por todo el equipo en el rango de tiempo a analizar.':'Para cada membro da equipe, o total de horas trabalhadas por dia pode ser exibido. A linha verde permite ao usuário comparar o tempo trabalhado no intervalo selecionado. A linha vermelha vertical mostra o valor médio trabalhado por toda a equipe no intervalo de tempo a ser analisado.',
    'Los botones de la parte superior permite seleccionar la vista del tipo de tiempo requerido (Productivo, Manual, Sin categoría, No productivo u Ocioso).':'Os botões na parte superior permitem selecionar a exibição do tipo de tempo necessário (Produtivo, Manual, Sem categoria, Não produtivo ou Ocioso).',
    'El informe de Tiempo Trabajado tiene un sistema de búsqueda que permite filtrar la información (por usuario, grupo o área y fecha) para ver sólo los datos que necesita.':'O relatório de Tempos Trabalhados possui um sistema de pesquisa que permite filtrar a informação (por utilizador, grupo ou área e data) para ver apenas os dados de que necessita.',
    'Bienvenido a ClowdWork':'Bem-vindo ao ClowdWork',
    '¿No tienes cuenta?':"Você não tem uma conta?",
    '¿Olvidaste tu password?':'Esqueceu sua senha?',
    'Ocurrio un error, verifica el usuario y el password':'Ocorreu um erro, verifique o nome de usuário e a senha',
    'Esta función le permite ver cómo avanza el trabajo con capturas de pantalla automáticas a intervalos regulares, lo que garantiza que la actividad diaria se ajuste a las prioridades y los objetivos de la empresa. Esta función se puede activar o desactivar en cualquier momento.':'Esse recurso permite que você veja como o trabalho está progredindo com capturas de tela automáticas em intervalos regulares, garantindo que a atividade diária esteja alinhada com as metas e prioridades da empresa. Esse recurso pode ser ativado ou desativado a qualquer momento.',
    'PARA EMPEZAR A GENERAR ESTE INFORME ES NECESARIO DESCARGAR EL GESTOR AUTOMÁTICO DE PRODUCTIVIDAD HACIENDO CLIC EN EL SIGUIENTE ENLACE:':'PARA COMEÇAR A GERAR ESTE RELATÓRIO, É NECESSÁRIO BAIXAR O GERENCIADOR DE PRODUTIVIDADE AUTOMÁTICO CLICANDO NO LINK A SEGUIR:',
    'ClowdWork realiza capturas de pantalla a intervalos regulares y mide los niveles de actividad para que puedas gestionar la productividad de tus equipos de trabajo operativos. Por defecto, las capturas de pantalla se realizan cada 5 minutos, pero los intervalos son totalmente personalizables. Los niveles de actividad se basan en el uso del ratón y del teclado de cada usuario. Se puede acceder al informe de capturas de pantalla y a los niveles de actividad en tiempo real desde cualquier dispositivo y, haciendo clic en cada captura de pantalla, se puede ampliar o descargar la imagen.':'O ClowdWork faz capturas de tela em intervalos regulares e mede os níveis de atividade para que você possa gerenciar a produtividade de suas equipes de trabalho operacional. Por padrão, as capturas de tela são feitas a cada 5 minutos, mas os intervalos são totalmente personalizáveis. Os níveis de atividade são baseados no uso do mouse e do teclado de cada usuário. O relatório de captura de tela e os níveis de atividade em tempo real podem ser acessados ​​de qualquer dispositivo e, clicando em cada captura de tela, a imagem pode ser ampliada ou baixada.',
    'Esta función se puede activar o desactivar en cualquier momento desde la configuración (en el menú de la parte superior derecha':'Esta função pode ser ativada ou desativada a qualquer momento nas configurações (no menu no canto superior direito',
    'según las necesidades de cada empresa.':'de acordo com a necessidade de cada empresa.',
    'Grupo de administradores':'grupo de administradores',
    'Saltar incorporación':'Saltar incorporación',
    'intervalos de captura':'Capture intervals',
    'minutos':'min',
    'Correo electrónico a la usuario creada':'Email to created user',
    'apagada':'OFF',
    'en':'ON',
    'Importar':'Import',
    '-Las actividades productivas son aquellas que agregan valor al negocio.':'- Productive activities are those that add value to the business.',
    '-Las actividades no productivas son aquellas que no agregan valor al negocio.':'- Non-productive activities are those that do not add value to the business.',
    '-Las actividades no categorizadas son aquellas actividades que el administrador aún no ha clasificado como productivas o no productivas y en algunos casos, algunas empresas lo usan como comodín para validar qué usuarios y con qué frecuencia están usando esa aplicación.':'-Uncategorized activities are those activities that the administrator has not yet classified as productive or non-productive and in some cases, some companies use it as a wildcard to validate which users and how often they are using that application.',
    'El sistema almacena automáticamente las aplicaciones y los sitios web utilizados por los miembros del equipo y los registra de manera predeterminada como productivos.' : 'The system automatically stores the applications and websites used by the team members and records them by default as productive.',
    'Para categorizar una aplicación para todos los usuarios de la empresa, debe seleccionar la opción todos los grupos y todos los usuarios en los filtros. En caso de que necesite categorizar una aplicación solo para un grupo o usuario en particular, debe indicar en el filtro correspondiente el nombre del grupo/usuario y actualizar la lista haciendo clic en el botón de búsqueda. Luego debe buscar la aplicación, marcar uno de los criterios (Productivo, No productivo o Sin categorizar) y de esta manera la aplicación seleccionada quedará categorizada para todos los usuarios, grupo y/o usuario seleccionado.':'To categorize an application for all users in the company, you must select the option all groups and all users in the filters.  In case you need to categorize an application only for a particular group or user, you must indicate in the corresponding filter the name of the group / user and update the list by clicking on the search button.   Then you must search for the application, check one of the criteria (Productive, Non-productive or Uncategorized) and in this way the selected application will be categorized for all users, group and/or selected user.',
    'assets/media/help/app1sp.png':'assets/media/help/app1.png',
    'assets/media/help/app2sp.png':'assets/media/help/app2.png',
    'Nuevo cliente':'New client',
    'seleccione la fecha de inicio':'select start date',
    'seleccione la fecha de finalización':'select end date',
    'seleccione la hora de inicio':'select start time',
    'seleccione la hora de finalización':'select end time',
    'seleccionar nombre':'select name',
    'Desinstaladores':'Uninstallers',
    'El informe también está disponible en formato de tabla.':'The report is also available in table format.',
    'Registro':'Register',
    'En este módulo puede crear usuarios y actualizar la información de cada miembro del equipo (nombre de usuario, grupo al que pertenece, contraseña). También puede asignar roles (usuario general, usuario con acceso a informes - administrador de grupo o administrador general). La captura de usuarios y capturas de pantalla también se puede habilitar o deshabilitar.' : 'In this module you can create users and update the information of each team member (user name, group to which they belong, password). You can also assign roles (general user, user with access to reports - group administrator or general administrator). Users and screenshots capture can also be enabled or disabled.',
    'Agregue los miembros de su equipo >>':'Add your team members >>',
    'Seleccione el sistema operativo correspondiente y complete la descarga. El software comenzará a generar automáticamente métricas de productividad.':'Please select the corresponding operating system and complete the download. The software will begin to automatically generate productivity metrics.',
    'Instalar el agente de productividad >>':'Install the productivity agent >>',
    'Puede crear, actualizar o eliminar grupos según los departamentos de su empresa':'You can create, update or delete groups according to your company departments',
    'Configurar grupos de usuarios >>':'Configure user groups >>',
    'Puede clasificar las aplicaciones que utilizan los miembros de su equipo como productivas o no productivas para que los informes de productividad se puedan generar con precisión. De esta manera, puedes ayudar a todos los miembros del equipo a enfocarse en aquellas actividades que realmente agregan valor a tu negocio.':'You can classify the applications that your team members use as productive or non-productive so that productivity reports can be generated accurately. In this way, you can help all the team members focus on those activities that really add value to your business.',
    'Clasificar aplicaciones >>':'Classify applications >>',
    'La plataforma ofrece varios informes de productividad. Cada módulo cuenta con filtros, para que puedas ver la información ya sea por grupo/departamento de la empresa o individualmente. ¡También puede descargar los informes en formato PDF o Excel con solo un clic!':'The platform offers various productivity reports. Each module has filters, so that   you can see the information either by group / company department or individually. You can also download the reports in PDF or Excel format with just one click!',
    'Informes >>':'Reports >>',
    'Agrega los miembros de tu equipo':'Add your team members',
    'Instalar el agente de productividad':'Install the productivity agent',
    'Configurar grupos de usuarios':'Configure user groups',
    'Clasificar aplicaciones':'Classify applications',
    'Informes de productividad en tiempo real':'Real-time productivity reports',
    'Formulario de activación':'Activation form',
    'Correo electrónico del cliente':'Customer email',
    'Nombre de usuario':'User name',
    'Facturación y Planes':'Billings and Plans',
    'Logotipo cambiado con éxito':'Logo changed successfully',
    'Subir':'Upload',
    'Grupo nuevo':'new group',
    'Algosalió mal' : 'Something went worng',
    'Se ha enviado un correo electrónico, siga las instrucciones.' : 'An email has been sent, please follow the instruction',
    'Cambia la contraseña':'Change password',
   'comprobar contraseña y confirmación clave': 'check password and confirmation password',
   'Contraseña cambiada con éxito' : 'Password Changed successfully',
   'Grupo añadido con éxito.':'Group added successfully.',
   'Regla añadida con éxito':'Rule added successfully',
   'Regla actualizada con éxito':'Rule updated successfully',
   'Descargar desinstalador de Windows':'Download Windows Uninstaller',
   'Descargar desinstalador GNU/Linux':'Download GNU/Linux Uninstaller',
   'Descargar desinstalador OS X':'Download OS X Uninstaller',
   'Archivo importado con éxito.':'File succesfully imported.',
   'La actualización no está disponible.':'Update is not available.',
   'Actualizar cliente':'Update client',
   'Reportar a':'Report to',
   'Se requiere la URL de informe':'Reporting url is required',
   'DB sufijo':'DB suffix',
   'Se requiere el sufijo db':'Db suffix is required',
   'Nuevo beta':'New beta',
   'Nuevo stable':'New stable',
   'El canal es requerido':'Channel is required',
   'Se requiere la versión de Windows':'Windows version is required',
   'Se requiere la versión de Linux':'Linux version is required',
   'versión Mac':'Mac version',
   'Se requiere la versión de Mac':'Mac version is required',
   'Intervalo de inventario de hardware':'Hardware inventory interval',
   'Se requiere un intervalo de inventario de hardware':'Hardware inventory interval is required',
   'Intervalo de inventario de software':'Software inventory interval',
   'Se requiere un intervalo de inventario de software':'Software inventory interval is required',
   'Tiempo de inactividad a ralentí':'Inactivity time to idle',
   'Se requiere tiempo de inactividad para inactividad':'Inactivity time to idle is required',
   'Intervalo de envío del registro':'Registry submission interval',
   'Se requiere el intervalo de envío del registro':'Registry submission interval is required',
   'Intervalo de verificación de config':'Config verification interval',
   'Se requiere intervalo de verificación de config':'Config verification interval is required',
   'Intervalo de registro':'Log interval',
   'Se requiere intervalo de registro':'Log interval is required',
   'Canal':'Channel',
   'Monitor activado':'Monitor activated',
   'Captura de pantalla':'Screenshot',
   'Salida y clerre de sesion':'Departure and closing of the session',
   'Modo privado':'Private mode',
   'Permitir el inventario de hardware':'Allow hardware inventory',
   'Permitir el inventario de software':'Allow software inventory',
   'Send registry':'Send registry',
   'Silent mode':'Silent mode',
   'Tiempo Manual':'Reunión',
   'img_validation':'Formato de imagem inválido. Somente os formatos JPEG, PNG e GIF são permitidos.',
   'proyectos': 'Projetos',
   'Referencia del proyecto':'Projeto de referência',
   'database_type':'Tipo de banco de dados',
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    MENU: {
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Painel',
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: 'Don\'t have an account?',
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Login Account',
        BUTTON: 'Sign In',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.'
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username'
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted'
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created'
        }
      }
    }
  }
};
