import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirstLetterPipe } from './pipes/first-letter.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { SecurePipe } from './pipes/secure.pipe';

@NgModule({
  declarations: [FirstLetterPipe, SafePipe, SortPipe, SecurePipe],
  imports: [CommonModule],
  exports: [FirstLetterPipe, SafePipe, SortPipe, SecurePipe],
})
export class CoreModule { }
