import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-mixed-widget12',
  templateUrl: './mixed-widget12.component.html',
})
export class MixedWidget12Component {
  @Input() cssClass: '';
  constructor() { }
}
