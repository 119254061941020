<!-- splash screen -->
<div #splashScreen id="splash-screen">
  <!--<img src="./assets/media/logos/logo-dark.png" alt="Logo" />-->
  <!--
  <h2><span style="color: #00aab4;">Clowd</span><span style="color: #7CD501;;">Work</span></h2>
  <svg class="splash-spinner" viewBox="0 0 50 50">
    <circle
      class="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      stroke-width="5"
    ></circle>
  </svg>-->
  <img class="loading-effect" src="assets/media/logos/logo_u.png">
</div>
