<div
  ngbDropdown
  class="dropdown dropdown-inline ml-2"
  data-toggle="tooltip"
  title="Quick actions"
  data-placement="left"
>
  <a
    ngbDropdownToggle
    class="btn btn-hover-light-primary btn-sm btn-icon"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
  >
    <i class="ki ki-bold-more-hor"></i>
  </a>
  <div
    ngbDropdownMenu
    class="dropdown-menu dropdown-menu-fit dropdown-menu-md dropdown-menu-right"
  >
    <app-dropdown-menu2></app-dropdown-menu2>
  </div>
</div>
