import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-advance-tables-widget9',
  templateUrl: './advance-tables-widget9.component.html',
})
export class AdvanceTablesWidget9Component {
  @Input() cssClass;

  constructor() { }
}
