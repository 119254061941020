import { Component, OnInit, Input, Output, EventEmitter,  OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { GeneralService } from 'src/app/services/general.service';
import { TranslationService } from 'src/app/modules/i18n/translation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {

  message:boolean;
  message1:string;
  show_msg:any = "true";
  subscription: Subscription;
  constructor(public generalService: GeneralService,
              private translate: TranslationService,
              private router: Router) { }
  onboardingText:any;
  linkText:any = 'View more';
//  href='#/users'
  modalComponent:any='close';
  ngOnInit(): void {

    this.subscription = this.generalService.currentMessage.subscribe(message => this.message = message);
    this.subscription = this.generalService.currentMessage1.subscribe(message => this.message1 = message);
      
    this.oBClick(this.message1);
  }

  ngOnChanges() { 
  }

  close(){
  localStorage.setItem("board_card","false");
  this.generalService.changeMessage(false);
  }

  oBClick(button){
    this.generalService.changeButtonMessage(button);

       switch(button){
     
    case 'addYourTeamMembers':
         this.onboardingText = 'En este módulo puede crear usuarios y actualizar la información de cada miembro del equipo (nombre de usuario, grupo al que pertenece, contraseña). También puede asignar roles (usuario general, usuario con acceso a informes - administrador de grupo o administrador general). La captura de usuarios y capturas de pantalla también se puede habilitar o deshabilitar.';  
         //this.linkText = 'Agregue los miembros de su equipo >>';
       //  this.router.navigate(['/users'], { replaceUrl: true }); 
         this.modalComponent = 'userOpen';
        break;

    case 'installTheProductivityAgent':
         this.onboardingText = 'Seleccione el sistema operativo correspondiente y complete la descarga. El software comenzará a generar automáticamente métricas de productividad.';
         //this.linkText = 'Instalar el agente de productividad >>' ;
       //  this.router.navigate(['/package'], { replaceUrl: true }); 
         this.modalComponent = 'packageOpen';
        break;

    case 'configureUserGroups':
         this.onboardingText = 'Puede crear, actualizar o eliminar grupos según los departamentos de su empresa';
         //this.linkText = 'Configurar grupos de usuarios >>';
        // this.router.navigate([ '/user-groups'], { replaceUrl: true });
         this.modalComponent = 'groupOpen';
         break;
    case 'classifyApplications':
         this.onboardingText = 'Puede clasificar las aplicaciones que utilizan los miembros de su equipo como productivas o no productivas para que los informes de productividad se puedan generar con precisión. De esta manera, puedes ayudar a todos los miembros del equipo a enfocarse en aquellas actividades que realmente agregan valor a tu negocio.';
         //this.linkText = 'Clasificar aplicaciones >>';
        // this.router.navigate(['/applications'], { replaceUrl: true });
         this.modalComponent = 'applicationsOpen';
         break;

    case 'realTimeProductivityReports':
        this.onboardingText = 'The platform offers several productivity reports. Each module has filters, so you can view the information either by company group/department or individually. You can also download the reports in PDF or Excel format with just one click!';
        //this.linkText = 'Informes >>';
      //  this.router.navigate(['/user-activity'], { replaceUrl: true });
        this.modalComponent = 'userActivityOpen';
        break;
       }
  }

}
