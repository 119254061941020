<div class="card-toolbar">
    <ul class="nav nav-pills nav-pills-sm nav-dark-75">
        <li
        class="nav-item"
        (click)="setCurrentTab('Year')"
        [style.cursor]="'pointer'"
      >
        <a
          class="nav-link py-2 px-4"
          [class.active]="currentTab === 'Year'"
          data-toggle="tab"
          >Year</a
        >
      </li>
      <li
        class="nav-item"
        (click)="setCurrentTab('Month')"
        [style.cursor]="'pointer'"
      >
        <a
          class="nav-link py-2 px-4"
          [class.active]="currentTab === 'Month'"
          data-toggle="tab"
          >Month</a
        >
      </li>
      <li
        class="nav-item"
        (click)="setCurrentTab('Week')"
        [style.cursor]="'pointer'"
      >
        <a
          class="nav-link py-2 px-4"
          [class.active]="currentTab === 'Week'"
          data-toggle="tab"
          >Week</a
        >
      </li>
      <li
        class="nav-item"
        (click)="setCurrentTab('Day')"
        [style.cursor]="'pointer'"
      >
        <a
          class="nav-link py-2 px-4"
          [class.active]="currentTab === 'Day'"
          data-toggle="tab"
          >Day</a
        >
      </li>
    </ul>
  </div>
