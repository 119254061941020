import { Component, OnInit ,Input ,OnChanges, SimpleChanges} from '@angular/core';
import { LayoutService } from '../../../../../core';
import { GeneralService } from 'src/app/services/general.service';
import { ReportsService } from 'src/app/services/reports.service';
import * as moment from 'moment';
import { locale } from 'src/assets/i18n/jp';

@Component({
  selector: 'app-mixed-widget1',
  templateUrl: './mixed-widget1.component.html',
})
export class MixedWidget1Component implements OnInit {
  @Input() widgetData: any[];
  @Input() dashData: any;
  chartOptions: any = {};
  fontFamily = '';
  colorsGrayGray500 = '';
  colorsGrayGray200 = '';
  colorsGrayGray300 = '';
  colorsThemeBaseDanger = '';
  userData: any =[];
  first:any ={name:"",productive_time_sum:'00:00h'};
  second:any ={name:"",productive_time_sum:'00:00h'};;
  third:any ={name:"",productive_time_sum:'00:00h'};;
  dataFilter:any;
  enddate: any;
  startdate:any;
  proHour:number =8;
  productivityData: any =[];
  Productividad = 'Productivity';
  ProToolTip = 'Summary of your team´s productivity % in the last 30 days.';
  podium = 'Podium';
  podiumTooltip = 'Your 3 most productive team members this month.';
  totalhourToolTip = 'Total worked time in Productive time + Manual time + Meetings + Custom in the current month.';

  constructor(private layout: LayoutService,
              private generalService: GeneralService,
              private reportService: ReportsService) {
    this.fontFamily = this.layout.getProp('js.fontFamily');
    this.colorsGrayGray500 = this.layout.getProp('js.colors.gray.gray500');
    this.colorsGrayGray200 = this.layout.getProp('js.colors.gray.gray200');
    this.colorsGrayGray300 = this.layout.getProp('js.colors.gray.gray300');
    this.colorsThemeBaseDanger = this.layout.getProp(
      'js.colors.theme.base.danger'
    );
  }

  ngOnInit(): void {
    var lang = localStorage.getItem('language');
    if(lang == 'es'){
      this.Productividad = 'Productividad';
      this.ProToolTip = 'Resumen del % de productividad de tu equipo en los últimos 30 días.';
      this.podium = 'Podio';
      this.podiumTooltip = 'Tus 3 miembros de equipo más productivos este mes.';
      this.totalhourToolTip = 'Tiempo total trabajado enTiempo Productivo + Tiempo Manual + Reuniones + Tiempo sin categorizar en el mes actual.';
    }else if(lang == 'pt'){
      this.Productividad = 'Produtividade';
      this.ProToolTip = 'Resumo da % de produtividade da sua equipe nos últimos 30 dias.';
      this.podium = 'Pódio';
      this.podiumTooltip = 'Seus 3 membros de equipe mais produtivos este mês.';
      this.totalhourToolTip = 'Tempo total trabalhado em Tempo produtivo + Tempo manual + Reuniões + Personalizado no mês atual.';
    }else{
      this.Productividad = 'Productivity';
      this.ProToolTip = 'Summary of your team´s productivity % in the last 30 days.'
      this.podium = 'Podium';    
      this.podiumTooltip = 'Your 3 most productive team members this month.';
      this.totalhourToolTip = 'Total worked time in Productive time + Manual time + Meetings + Uncategorized in the current month.';
    }
    this.enddate = moment().format("YYYY-MM-DD");   
    this.startdate = moment(this.enddate).subtract(30, 'days').format("YYYY-MM-DD");  
    this.load();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.dashData && changes.dashData.currentValue) {
      this.makeUserData();
    }
  }
  makeUserData() {
    this.userData = this.dashData.users_most_productive;  
    var u1 = [];
    var u2 = [];
    var u3 = [];
    if(this.userData?.length > 0){
           u1 = this.userData[0];
           u2 = this.userData[1];
           u3 = this.userData[2];
  
          this.first = u1;
          var [hours, minutes, seconds] = this.first.productive_time_sum.split(':');
          this.first.productive_time_sum = hours+ ':'+minutes+'h'
    
          if(u2 !== undefined){
            this.second = u2
            var [hours, minutes, seconds] = this.second.productive_time_sum.split(':');
            this.second.productive_time_sum = hours+ ':'+minutes+'h'
          }
          if(u3 !== undefined){
            this.third = u3
            var [hours, minutes, seconds] = this.third.productive_time_sum.split(':');
            this.third.productive_time_sum = hours+ ':'+minutes+'h'
          }
    }
  }

  load(){
    this.dataFilter ={
      start_date :this.startdate,
      end_date: this.enddate,
      user_group_id: '',
      user_id: '',
      productivity_hour: this.proHour
     }

     const resultPromise = new Promise((resolve, reject) => {
      resolve(this.getPermission());
  })

      resultPromise.then(res=>{
      var perArr:any= res
        if (perArr !== undefined && perArr !== null) {
          let result: any;
          let result2: any;
          if (perArr.module !== undefined && perArr.module !== null) {
            result = perArr.module.find(({ slug }) => slug === 'productividad-diaria');
          }
          if (perArr.permission !== undefined && perArr.permission !== null) {
            result2 = perArr.permission.find(({ name }) => name === 'report_activity_daily');
          }

          if (result !== undefined && result2 !== undefined) {
            
            
            setTimeout(() => {
              this.productivityData = this.widgetData; 
              this.getChartOptions();
            }, 1500);
              
              
          
          }
        }
      })
 

    this.getChartOptions();
   }

   async getPermission() {
    const permission = await JSON.parse(localStorage.getItem('permissions'));
    const module = await JSON.parse(localStorage.getItem('modules'));


    return {permission,module};
  }



   valueHours(time) {
    let Hours = String(time);
    let H = Hours.split(":")[0];
    let M = Hours.split(":")[1];
    let S = Hours.split(":")[2];
    time = parseFloat(H + "." + M);

    return time;
 }  

  getChartOptions() {
    let categoriesDay =[];
    let dataAvg =[];

    if( this.productivityData.length > 0 && this.productivityData !== undefined && this.productivityData !== null ){
      categoriesDay = this.productivityData.map((value) => moment(value.start_time).format('MMM DD'));
      dataAvg = this.productivityData.map(value => this.valueHours((value.average_percentage)?value.average_percentage.toFixed(2):'00:00:00'));
    }

    const strokeColor = '#D13647';
    this.chartOptions =  {
      chart: {
        type: 'area',
        height: 190,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        sparkline: {
          enabled: true,
        },
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 5,
          left: 0,
          blur: 3,
          color: strokeColor,
          opacity: 0.5,
        },
      },
      series: [
        {
          name: '% Productivity',
          data: dataAvg,
        },
      ],
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: 'solid',
        opacity: 0,
      },
      stroke: {
        curve: 'smooth',
        show: true,
        width: 3,
        colors: [strokeColor],
      },
      xaxis: {
        categories: categoriesDay,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          style: {
            colors: this.colorsGrayGray500,
            fontSize: '12px',
            fontFamily: this.fontFamily,
          },
        },
        crosshairs: {
          show: false,
          position: 'front',
          stroke: {
            color: this.colorsGrayGray300,
            width: 1,
            dashArray: 3,
          },
        },
      },
      yaxis: {
        // min: 0,
        // max: 120,
        labels: {
          show: false,
          style: {
            colors: this.colorsGrayGray500,
            fontSize: '12px',
            fontFamily: this.fontFamily,
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
          fontFamily: this.fontFamily,
        },
        y: {
          // tslint:disable-next-line
          formatter: function (val) {
            return  val  + " %";;
          },
        },
        marker: {
          show: false,
        },
      },
      colors: ['transparent'],
      markers: {
        colors: this.colorsThemeBaseDanger,
        strokeColor: [strokeColor],
        strokeWidth: 3,
      },
    };
  }
}
