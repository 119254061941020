<div class="card p-5 mt-5" *ngIf="mode==2">
  <nav class="navbar navbar-expand-sm justify-content-center">
    <ul class="navbar-nav">
      <li *ngFor="let item of categories" class="nav-item">
        <button 
          class="btn btn-category"
          style="box-shadow: inset 0 3px 5px #888888!important;"
          [style]="isSelected(item.category_id)"
          (click)="toSelected(item.category_id)"
          translate
        >
          {{item.name}}
        </button>
        <i class="fa fa-plus" style="margin: 5px; color: black;" *ngIf="item.category_id != 5"></i>
      </li>
    </ul>
  </nav>  
</div>

<div class="card p-5 mt-5" *ngIf="mode==1">
  <nav class="navbar navbar-expand-sm justify-content-center">
    <ul class="navbar-nav">
      <li *ngFor="let item of categories" class="nav-item">
        <button 
          class="btn btn-category"
          style="box-shadow: inset 0 3px 5px #888888!important;"
          [style]="custIsSelected(item.id)"
          (click)="custToSelected(item.id)"
          translate
        >
          {{item.name}}
        </button>
        <i class="fa fa-plus" style="margin: 5px; color: black;" *ngIf="item.id!='97754efc-d85c-44cd-ac4f-fadda5f3216d'"></i>
      </li>
    </ul>
  </nav>  
</div>