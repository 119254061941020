import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tab1',
  templateUrl: './tab1.component.html',
  styleUrls: ['./tab1.component.scss']
})
export class Tab1Component implements OnInit {
  currentTab = 'Day';
  constructor() { }

  ngOnInit(): void {
  }

  setCurrentTab(tab: string) {
    this.currentTab = tab;
  }
}
