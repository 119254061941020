import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lists-widget9',
  templateUrl: './lists-widget9.component.html',
})
export class ListsWidget9Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
