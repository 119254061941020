<div class="card card-custom card-stretch gutter-b">
  <!-- Header -->
  <div class="card-header border-0 mt-4">
    <h3 class="card-title align-items-start flex-column">
      <span class="card-label font-weight-bolder text-dark"
        >Tasks Overview</span
      >
      <span class="text-muted mt-3 font-weight-bold font-size-sm"
        >Pending 10 tasks</span
      >
    </h3>
    <div class="card-toolbar">
      <div
        ngbDropdown
        placement="bottom-right"
        class="dropdown dropdown-inline"
        title="Quick actions"
      >
        <a
          ngbDropdownToggle
          class="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="ki ki-bold-more-hor"></i>
        </a>
        <div
          ngbDropdownMenu
          class="dropdown-menu dropdown-menu-md dropdown-menu-right"
        >
          <app-dropdown-menu4></app-dropdown-menu4>
        </div>
      </div>
    </div>
  </div>
  <!-- Body -->
  <div class="card-body pt-8">
    <!--begin::Item-->
    <div class="d-flex align-items-center mb-10">
      <!--begin::Symbol-->
      <div class="symbol symbol-40 symbol-light-primary mr-5">
        <span class="symbol-label">
          <span
            [inlineSVG]="'./assets/media/svg/icons/Home/Library.svg'"
            class="svg-icon svg-icon-lg svg-icon-primary"
          ></span>
        </span>
      </div>
      <!--end::Symbol-->

      <!--begin::Text-->
      <div class="d-flex flex-column font-weight-bold">
        <a class="text-dark text-hover-primary mb-1 font-size-lg cursor-pointer"
          >Project Briefing</a
        >
        <span class="text-muted">Project Manager</span>
      </div>
      <!--end::Text-->
    </div>
    <!--end::Item-->

    <!--begin::Item-->
    <div class="d-flex align-items-center mb-10">
      <!--begin::Symbol-->
      <div class="symbol symbol-40 symbol-light-warning mr-5">
        <span class="symbol-label">
          <span
            [inlineSVG]="'./assets/media/svg/icons/Communication/Write.svg'"
            class="svg-icon svg-icon-lg svg-icon-warning"
          ></span>
        </span>
      </div>
      <!--end::Symbol-->

      <!--begin::Text-->
      <div class="d-flex flex-column font-weight-bold">
        <a
          class="text-dark-75 text-hover-primary mb-1 font-size-lg cursor-pointer"
          >Concept Design</a
        >
        <span class="text-muted">Art Director</span>
      </div>
      <!--end::Text-->
    </div>
    <!--end::Item-->

    <!--begin::Item-->
    <div class="d-flex align-items-center mb-10">
      <!--begin::Symbol-->
      <div class="symbol symbol-40 symbol-light-success mr-5">
        <span class="symbol-label">
          <span
            [inlineSVG]="
              './assets/media/svg/icons/Communication/Group-chat.svg'
            "
            class="svg-icon svg-icon-lg svg-icon-success"
          ></span>
        </span>
      </div>
      <!--end::Symbol-->

      <!--begin::Text-->
      <div class="d-flex flex-column font-weight-bold">
        <a class="text-dark text-hover-primary mb-1 font-size-lg cursor-pointer"
          >Functional Logics</a
        >
        <span class="text-muted">Lead Developer</span>
      </div>
      <!--end::Text-->
    </div>
    <!--end::Item-->

    <!--begin::Item-->
    <div class="d-flex align-items-center mb-10">
      <!--begin::Symbol-->
      <div class="symbol symbol-40 symbol-light-danger mr-5">
        <span class="symbol-label">
          <span
            [inlineSVG]="'./assets/media/svg/icons/General/Attachment2.svg'"
            class="svg-icon svg-icon-lg svg-icon-danger"
          ></span>
        </span>
      </div>
      <!--end::Symbol-->

      <!--begin::Text-->
      <div class="d-flex flex-column font-weight-bold">
        <a class="text-dark text-hover-primary mb-1 font-size-lg cursor-pointer"
          >Development</a
        >
        <span class="text-muted">DevOps</span>
      </div>
      <!--end::Text-->
    </div>
    <!--end::Item-->

    <!--begin::Item-->
    <div class="d-flex align-items-center mb-2">
      <!--begin::Symbol-->
      <div class="symbol symbol-40 symbol-light-info mr-5">
        <span class="symbol-label">
          <span
            [inlineSVG]="
              './assets/media/svg/icons/Communication/Shield-user.svg'
            "
            class="svg-icon svg-icon-lg svg-icon-info"
          ></span>
        </span>
      </div>
      <!--end::Symbol-->

      <!--begin::Text-->
      <div class="d-flex flex-column font-weight-bold">
        <a class="text-dark text-hover-primary mb-1 font-size-lg cursor-pointer"
          >Testing</a
        >
        <span class="text-muted">QA Managers</span>
      </div>
      <!--end::Text-->
    </div>
    <!--end::Item-->
  </div>
</div>
