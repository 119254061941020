<div class="card card-custom" [ngClass]="cssClass">
  <!-- Header -->
  <div class="card-header border-0 pt-5">
    <h3 class="card-title align-items-start flex-column">
      <span class="card-label font-weight-bolder text-dark">New Arrivals</span>
      <span class="text-muted mt-3 font-weight-bold font-size-sm"
        >More than 400+ new members</span
      >
    </h3>
    <div class="card-toolbar">
      <ul class="nav nav-pills nav-pills-sm nav-dark-75">
        <li
          class="nav-item"
          (click)="setCurrentTab(TABS[0])"
          [style.cursor]="'pointer'"
        >
          <a
            class="nav-link py-2 px-4"
            [class.active]="currentTab === TABS[0]"
            data-toggle="tab"
            >Month</a
          >
        </li>
        <li
          class="nav-item"
          (click)="setCurrentTab(TABS[1])"
          [style.cursor]="'pointer'"
        >
          <a
            class="nav-link py-2 px-4"
            [class.active]="currentTab === TABS[1]"
            data-toggle="tab"
            >Week</a
          >
        </li>
        <li
          class="nav-item"
          (click)="setCurrentTab(TABS[2])"
          [style.cursor]="'pointer'"
        >
          <a
            class="nav-link py-2 px-4"
            [class.active]="currentTab === TABS[2]"
            data-toggle="tab"
            >Day</a
          >
        </li>
      </ul>
    </div>
  </div>
  <!-- Body -->
  <div class="card-body pt-2 pb-0 mt-n3">
    <div class="tab-content mt-5">
      <!--begin::Table-->
      <!--begin::Tap pane-->
      <div
        class="tab-pane fade"
        [class.active]="currentTab === TABS[0]"
        [class.show]="currentTab === TABS[0]"
        id="kt_tab_pane_6_1"
        role="tabpanel"
        aria-labelledby="kt_tab_pane_6_1"
      >
        <!--begin::Table-->
        <div class="table-responsive">
          <table class="table table-borderless table-vertical-center">
            <thead>
              <tr>
                <th class="p-0 w-50px"></th>
                <th class="p-0 min-w-150px"></th>
                <th class="p-0 min-w-140px"></th>
                <th class="p-0 min-w-120px"></th>
                <th class="p-0 min-w-40px"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="pl-0 py-5">
                  <div class="symbol symbol-50 symbol-light mr-2">
                    <span class="symbol-label">
                      <span
                        [inlineSVG]="'./assets/media/svg/misc/006-plurk.svg'"
                        class="svg-icon h-50 align-self-end"
                      ></span>
                    </span>
                  </div>
                </td>
                <td class="pl-0">
                  <a
                    href="#"
                    class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >Top Authors</a
                  >
                  <span class="text-muted font-weight-bold d-block"
                    >Successful Fellas</span
                  >
                </td>
                <td class="text-right">
                  <span class="text-muted font-weight-bold">
                    ReactJs, HTML
                  </span>
                </td>
                <td class="text-right">
                  <span class="text-muted font-weight-bold"> 4600 Users </span>
                </td>
                <td class="text-right pr-0">
                  <a href="#" class="btn btn-icon btn-light btn-sm">
                    <span
                      [inlineSVG]="
                        './assets/media/svg/icons/Navigation/Arrow-right.svg'
                      "
                      class="svg-icon svg-icon-md svg-icon-success"
                    ></span>
                  </a>
                </td>
              </tr>
              <tr>
                <td class="pl-0 py-5">
                  <div class="symbol symbol-50 symbol-light mr-2">
                    <span class="symbol-label">
                      <span
                        [inlineSVG]="
                          './assets/media/svg/misc/014-kickstarter.svg'
                        "
                        class="svg-icon h-50 align-self-end"
                      ></span>
                    </span>
                  </div>
                </td>
                <td class="pl-0">
                  <a
                    href="#"
                    class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >Bestseller Theme</a
                  >
                  <span class="text-muted font-weight-bold d-block"
                    >Amazing Templates</span
                  >
                </td>
                <td class="text-right">
                  <span class="text-muted font-weight-bold">
                    ReactJS, Ruby
                  </span>
                </td>
                <td class="text-right">
                  <span class="text-muted font-weight-bold"> 354 Users </span>
                </td>
                <td class="text-right pr-0">
                  <a href="#" class="btn btn-icon btn-light btn-sm">
                    <span
                      [inlineSVG]="
                        './assets/media/svg/icons/Navigation/Arrow-right.svg'
                      "
                      class="svg-icon svg-icon-md svg-icon-success"
                    ></span>
                  </a>
                </td>
              </tr>
              <tr>
                <td class="pl-0 py-5">
                  <div class="symbol symbol-50 symbol-light mr-2">
                    <span class="symbol-label">
                      <span
                        [inlineSVG]="'./assets/media/svg/misc/015-telegram.svg'"
                        class="svg-icon h-50 align-self-end"
                      ></span>
                    </span>
                  </div>
                </td>
                <td class="pl-0">
                  <a
                    href="#"
                    class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >Popular Authors</a
                  >
                  <span class="text-muted font-weight-bold d-block"
                    >Most Successful</span
                  >
                </td>
                <td class="text-right">
                  <span class="text-muted font-weight-bold">
                    Python, MySQL
                  </span>
                </td>
                <td class="text-right">
                  <span class="text-muted font-weight-bold"> 7200 Users </span>
                </td>
                <td class="text-right pr-0">
                  <a href="#" class="btn btn-icon btn-light btn-sm">
                    <span
                      [inlineSVG]="
                        './assets/media/svg/icons/Navigation/Arrow-right.svg'
                      "
                      class="svg-icon svg-icon-md svg-icon-success"
                    ></span>
                  </a>
                </td>
              </tr>
              <tr>
                <td class="pl-0 py-5">
                  <div class="symbol symbol-50 symbol-light mr-2">
                    <span class="symbol-label">
                      <span
                        [inlineSVG]="'./assets/media/svg/misc/003-puzzle.svg'"
                        class="svg-icon h-50 align-self-end"
                      ></span>
                    </span>
                  </div>
                </td>
                <td class="pl-0">
                  <a
                    href="#"
                    class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >New Users</a
                  >
                  <span class="text-muted font-weight-bold d-block"
                    >Awesome Users</span
                  >
                </td>
                <td class="text-right">
                  <span class="text-muted font-weight-bold">
                    Laravel, Metronic
                  </span>
                </td>
                <td class="text-right">
                  <span class="text-muted font-weight-bold"> 890 Users </span>
                </td>
                <td class="text-right pr-0">
                  <a href="#" class="btn btn-icon btn-light btn-sm">
                    <span
                      [inlineSVG]="
                        './assets/media/svg/icons/Navigation/Arrow-right.svg'
                      "
                      class="svg-icon svg-icon-md svg-icon-success"
                    ></span>
                  </a>
                </td>
              </tr>
              <tr>
                <td class="pl-0 py-5">
                  <div class="symbol symbol-50 symbol-light mr-2">
                    <span class="symbol-label">
                      <span
                        [inlineSVG]="'./assets/media/svg/misc/005-bebo.svg'"
                        class="svg-icon h-50 align-self-end"
                      ></span>
                    </span>
                  </div>
                </td>
                <td class="pl-0">
                  <a
                    href="#"
                    class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >Active Customers</a
                  >
                  <span class="text-muted font-weight-bold d-block"
                    >Best Customers</span
                  >
                </td>
                <td class="text-right">
                  <span class="text-muted font-weight-bold">
                    AngularJS, C#
                  </span>
                </td>
                <td class="text-right">
                  <span class="text-muted font-weight-bold"> 6370 Users </span>
                </td>
                <td class="text-right pr-0">
                  <a href="#" class="btn btn-icon btn-light btn-sm">
                    <span
                      [inlineSVG]="
                        './assets/media/svg/icons/Navigation/Arrow-right.svg'
                      "
                      class="svg-icon svg-icon-md svg-icon-success"
                    ></span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
      </div>
      <!--end::Tap pane-->

      <!--begin::Tap pane-->
      <div
        class="tab-pane fade"
        [class.active]="currentTab === TABS[1]"
        [class.show]="currentTab === TABS[1]"
        id="kt_tab_pane_6_2"
        role="tabpanel"
        aria-labelledby="kt_tab_pane_6_2"
      >
        <!--begin::Table-->
        <div class="table-responsive">
          <table class="table table-borderless table-vertical-center">
            <thead>
              <tr>
                <th class="p-0" style="width: 50px"></th>
                <th class="p-0" style="min-width: 150px"></th>
                <th class="p-0" style="min-width: 140px"></th>
                <th class="p-0" style="min-width: 120px"></th>
                <th class="p-0" style="min-width: 40px"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="pl-0 py-5">
                  <div class="symbol symbol-50 symbol-light mr-2">
                    <span class="symbol-label">
                      <span
                        [inlineSVG]="'./assets/media/svg/misc/015-telegram.svg'"
                        class="svg-icon h-50 align-self-center"
                      ></span>
                    </span>
                  </div>
                </td>
                <td class="pl-0">
                  <a
                    href="#"
                    class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >Popular Authors</a
                  >
                  <span class="text-muted font-weight-bold d-block"
                    >Most Successful</span
                  >
                </td>
                <td class="text-right">
                  <span class="text-muted font-weight-bold">
                    Python, MySQL
                  </span>
                </td>
                <td class="text-right">
                  <span class="text-muted font-weight-bold"> 7200 Users </span>
                </td>
                <td class="text-right pr-0">
                  <a href="#" class="btn btn-icon btn-light btn-sm">
                    <span
                      [inlineSVG]="
                        './assets/media/svg/icons/Navigation/Arrow-right.svg'
                      "
                      class="svg-icon svg-icon-md svg-icon-success"
                    ></span>
                  </a>
                </td>
              </tr>
              <tr>
                <td class="pl-0 py-5">
                  <div class="symbol symbol-50 symbol-light mr-2">
                    <span class="symbol-label">
                      <span
                        [inlineSVG]="'./assets/media/svg/misc/003-puzzle.svg'"
                        class="svg-icon h-50 align-self-center"
                      ></span>
                    </span>
                  </div>
                </td>
                <td class="pl-0">
                  <a
                    href="#"
                    class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >New Users</a
                  >
                  <span class="text-muted font-weight-bold d-block"
                    >Awesome Users</span
                  >
                </td>
                <td class="text-right">
                  <span class="text-muted font-weight-bold">
                    Laravel, Metronic
                  </span>
                </td>
                <td class="text-right">
                  <span class="text-muted font-weight-bold"> 890 Users </span>
                </td>
                <td class="text-right pr-0">
                  <a href="#" class="btn btn-icon btn-light btn-sm">
                    <span
                      [inlineSVG]="
                        './assets/media/svg/icons/Navigation/Arrow-right.svg'
                      "
                      class="svg-icon svg-icon-md svg-icon-success"
                    ></span>
                  </a>
                </td>
              </tr>
              <tr>
                <td class="pl-0 py-5">
                  <div class="symbol symbol-50 symbol-light mr-2">
                    <span class="symbol-label">
                      <span
                        [inlineSVG]="'./assets/media/svg/misc/005-bebo.svg'"
                        class="svg-icon h-50 align-self-center"
                      ></span>
                    </span>
                  </div>
                </td>
                <td class="pl-0">
                  <a
                    href="#"
                    class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >Active Customers</a
                  >
                  <span class="text-muted font-weight-bold d-block"
                    >Best Customers</span
                  >
                </td>
                <td class="text-right">
                  <span class="text-muted font-weight-bold">
                    AngularJS, C#
                  </span>
                </td>
                <td class="text-right">
                  <span class="text-muted font-weight-bold"> 6370 Users </span>
                </td>
                <td class="text-right pr-0">
                  <a href="#" class="btn btn-icon btn-light btn-sm">
                    <span
                      [inlineSVG]="
                        './assets/media/svg/icons/Navigation/Arrow-right.svg'
                      "
                      class="svg-icon svg-icon-md svg-icon-success"
                    ></span>
                  </a>
                </td>
              </tr>
              <tr>
                <td class="pl-0 py-5">
                  <div class="symbol symbol-50 symbol-light mr-2">
                    <span class="symbol-label">
                      <span
                        [inlineSVG]="'./assets/media/svg/misc/006-plurk.svg'"
                        class="svg-icon h-50 align-self-center"
                      ></span>
                    </span>
                  </div>
                </td>
                <td class="pl-0">
                  <a
                    href="#"
                    class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >Top Authors</a
                  >
                  <span class="text-muted font-weight-bold d-block"
                    >Successful Fellas</span
                  >
                </td>
                <td class="text-right">
                  <span class="text-muted font-weight-bold">
                    ReactJs, HTML
                  </span>
                </td>
                <td class="text-right">
                  <span class="text-muted font-weight-bold"> 4600 Users </span>
                </td>
                <td class="text-right pr-0">
                  <a href="#" class="btn btn-icon btn-light btn-sm">
                    <span
                      [inlineSVG]="
                        './assets/media/svg/icons/Navigation/Arrow-right.svg'
                      "
                      class="svg-icon svg-icon-md svg-icon-success"
                    ></span>
                  </a>
                </td>
              </tr>
              <tr>
                <td class="pl-0 py-5">
                  <div class="symbol symbol-50 symbol-light mr-2">
                    <span class="symbol-label">
                      <span
                        [inlineSVG]="
                          './assets/media/svg/misc/014-kickstarter.svg'
                        "
                        class="svg-icon h-50 align-self-center"
                      ></span>
                    </span>
                  </div>
                </td>
                <td class="pl-0">
                  <a
                    href="#"
                    class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >Bestseller Theme</a
                  >
                  <span class="text-muted font-weight-bold d-block"
                    >Amazing Templates</span
                  >
                </td>
                <td class="text-right">
                  <span class="text-muted font-weight-bold">
                    ReactJS, Ruby
                  </span>
                </td>
                <td class="text-right">
                  <span class="text-muted font-weight-bold"> 354 Users </span>
                </td>
                <td class="text-right pr-0">
                  <a href="#" class="btn btn-icon btn-light btn-sm">
                    <span
                      [inlineSVG]="
                        './assets/media/svg/icons/Navigation/Arrow-right.svg'
                      "
                      class="svg-icon svg-icon-md svg-icon-success"
                    ></span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
      </div>
      <!--end::Tap pane-->

      <!--begin::Tap pane-->
      <div
        class="tab-pane fade"
        [class.active]="currentTab === TABS[2]"
        [class.show]="currentTab === TABS[2]"
        id="kt_tab_pane_6_3"
        role="tabpanel"
        aria-labelledby="kt_tab_pane_6_3"
      >
        <!--begin::Table-->
        <div class="table-responsive">
          <table class="table table-borderless table-vertical-center">
            <thead>
              <tr>
                <th class="p-0" style="width: 50px"></th>
                <th class="p-0" style="min-width: 150px"></th>
                <th class="p-0" style="min-width: 140px"></th>
                <th class="p-0" style="min-width: 120px"></th>
                <th class="p-0" style="min-width: 40px"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="pl-0 py-5">
                  <div class="symbol symbol-50 symbol-light mr-2">
                    <span class="symbol-label">
                      <span
                        [inlineSVG]="'./assets/media/svg/misc/006-plurk.svg'"
                        class="svg-icon h-50 align-self-center"
                      ></span>
                    </span>
                  </div>
                </td>
                <td class="pl-0">
                  <a
                    href="#"
                    class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >Top Authors</a
                  >
                  <span class="text-muted font-weight-bold d-block"
                    >Successful Fellas</span
                  >
                </td>
                <td class="text-right">
                  <span class="text-muted font-weight-bold">
                    ReactJs, HTML
                  </span>
                </td>
                <td class="text-right">
                  <span class="text-muted font-weight-bold"> 4600 Users </span>
                </td>
                <td class="text-right pr-0">
                  <a href="#" class="btn btn-icon btn-light btn-sm">
                    <span
                      [inlineSVG]="
                        './assets/media/svg/icons/Navigation/Arrow-right.svg'
                      "
                      class="svg-icon svg-icon-md svg-icon-success"
                    ></span>
                  </a>
                </td>
              </tr>
              <tr>
                <td class="pl-0 py-5">
                  <div class="symbol symbol-50 symbol-light mr-2">
                    <span class="symbol-label">
                      <span
                        [inlineSVG]="'./assets/media/svg/misc/015-telegram.svg'"
                        class="svg-icon h-50 align-self-center"
                      ></span>
                    </span>
                  </div>
                </td>
                <td class="pl-0">
                  <a
                    href="#"
                    class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >Popular Authors</a
                  >
                  <span class="text-muted font-weight-bold d-block"
                    >Most Successful</span
                  >
                </td>
                <td class="text-right">
                  <span class="text-muted font-weight-bold">
                    Python, MySQL
                  </span>
                </td>
                <td class="text-right">
                  <span class="text-muted font-weight-bold"> 7200 Users </span>
                </td>
                <td class="text-right pr-0">
                  <a href="#" class="btn btn-icon btn-light btn-sm">
                    <span
                      [inlineSVG]="
                        './assets/media/svg/icons/Navigation/Arrow-right.svg'
                      "
                      class="svg-icon svg-icon-md svg-icon-success"
                    ></span>
                  </a>
                </td>
              </tr>
              <tr>
                <td class="pl-0 py-5">
                  <div class="symbol symbol-50 symbol-light mr-2">
                    <span class="symbol-label">
                      <span
                        [inlineSVG]="'./assets/media/svg/misc/003-puzzle.svg'"
                        class="svg-icon h-50 align-self-center"
                      ></span>
                    </span>
                  </div>
                </td>
                <td class="pl-0">
                  <a
                    href="#"
                    class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >New Users</a
                  >
                  <span class="text-muted font-weight-bold d-block"
                    >Awesome Users</span
                  >
                </td>
                <td class="text-right">
                  <span class="text-muted font-weight-bold">
                    Laravel, Metronic
                  </span>
                </td>
                <td class="text-right">
                  <span class="text-muted font-weight-bold"> 890 Users </span>
                </td>
                <td class="text-right pr-0">
                  <a href="#" class="btn btn-icon btn-light btn-sm">
                    <span
                      [inlineSVG]="
                        './assets/media/svg/icons/Navigation/Arrow-right.svg'
                      "
                      class="svg-icon svg-icon-md svg-icon-success"
                    ></span>
                  </a>
                </td>
              </tr>
              <tr>
                <td class="pl-0 py-5">
                  <div class="symbol symbol-50 symbol-light mr-2">
                    <span class="symbol-label">
                      <span
                        [inlineSVG]="'./assets/media/svg/misc/005-bebo.svg'"
                        class="svg-icon h-50 align-self-center"
                      ></span>
                    </span>
                  </div>
                </td>
                <td class="pl-0">
                  <a
                    href="#"
                    class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >Active Customers</a
                  >
                  <span class="text-muted font-weight-bold d-block"
                    >Best Customers</span
                  >
                </td>
                <td class="text-right">
                  <span class="text-muted font-weight-bold">
                    AngularJS, C#
                  </span>
                </td>
                <td class="text-right">
                  <span class="text-muted font-weight-bold"> 6370 Users </span>
                </td>
                <td class="text-right pr-0">
                  <a href="#" class="btn btn-icon btn-light btn-sm">
                    <span
                      [inlineSVG]="
                        './assets/media/svg/icons/Navigation/Arrow-right.svg'
                      "
                      class="svg-icon svg-icon-md svg-icon-success"
                    ></span>
                  </a>
                </td>
              </tr>
              <tr>
                <td class="pl-0 py-5">
                  <div class="symbol symbol-50 symbol-light mr-2">
                    <span class="symbol-label">
                      <span
                        [inlineSVG]="
                          './assets/media/svg/misc/014-kickstarter.svg'
                        "
                        class="svg-icon h-50 align-self-center"
                      ></span>
                    </span>
                  </div>
                </td>
                <td class="pl-0">
                  <a
                    href="#"
                    class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >Bestseller Theme</a
                  >
                  <span class="text-muted font-weight-bold d-block"
                    >Amazing Templates</span
                  >
                </td>
                <td class="text-right">
                  <span class="text-muted font-weight-bold">
                    ReactJS, Ruby
                  </span>
                </td>
                <td class="text-right">
                  <span class="text-muted font-weight-bold"> 354 Users </span>
                </td>
                <td class="text-right pr-0">
                  <a href="#" class="btn btn-icon btn-light btn-sm">
                    <span
                      [inlineSVG]="
                        './assets/media/svg/icons/Navigation/Arrow-right.svg'
                      "
                      class="svg-icon svg-icon-md svg-icon-success"
                    ></span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
      </div>
      <!--end::Tap pane-->
      <!--end::Table-->
    </div>
  </div>
</div>
