<div class="card card-onboarding" *ngIf="message">
  <div class="row">
    <a
      class="btn btn-category selected"
      (click)="oBClick('addYourTeamMembers')"
      *ngIf="message1 =='addYourTeamMembers'"
      href="#/users"
    >
     {{'Agrega los miembros de tu equipo' |translate}}
  </a>
    <a
    class="btn btn-category card-items"
    (click)="oBClick('addYourTeamMembers')"
    *ngIf="message1 !=='addYourTeamMembers'"
    href="#/users"
  >
  {{'Agrega los miembros de tu equipo' |translate}}
</a>
    <a
      class="btn btn-category selected"
      (click)="oBClick('installTheProductivityAgent')"
      *ngIf="message1 =='installTheProductivityAgent'"
      href="#/package"
    >
      {{'Instalar el agente de productividad' | translate}}
</a>
    <a
    class="btn btn-category card-items "
    (click)="oBClick('installTheProductivityAgent')"
    *ngIf="message1 !=='installTheProductivityAgent'"
    href="#/package"
  >
  {{'Instalar el agente de productividad' | translate}}
</a>
    <a
      class="btn btn-category selected"
      (click)="oBClick('configureUserGroups')"
      *ngIf="message1 =='configureUserGroups'"
      href="#/user-groups"
    >
      {{'Configurar grupos de usuarios' | translate}}
    </a>
    <a
    class="btn btn-category card-items"
    (click)="oBClick('configureUserGroups')"
    *ngIf="message1 !=='configureUserGroups'"
    href="#/user-groups"
    >
  {{'Configurar grupos de usuarios' | translate}}
  </a>
    <a
      class="btn btn-category selected"
      (click)="oBClick('classifyApplications')"
      *ngIf="message1 =='classifyApplications'"
      href="#/applications"
      >
    {{'Clasificar aplicaciones' | translate}}
</a>
    <a
    class="btn btn-category card-items"
    (click)="oBClick('classifyApplications')"
    *ngIf="message1 !=='classifyApplications'"
    href="#/applications"
  >
  {{'Clasificar aplicaciones' | translate}}
</a>
    <a
      class="btn btn-category selected"
      (click)="oBClick('realTimeProductivityReports')"
      *ngIf="message1 =='realTimeProductivityReports'"
      href="#/user-activity"
    >
      {{'Informes de productividad en tiempo real' | translate}}
</a>
    <a
    class="btn btn-category card-items"
    (click)="oBClick('realTimeProductivityReports')"
    *ngIf="message1 !=='realTimeProductivityReports'"
    href="#/user-activity"
  >
  {{'Informes de productividad en tiempo real' | translate}}
</a>
  </div>
  <p class="text">{{ onboardingText | translate}}</p>
  <br /><br />
  <a class="linkText" *ngIf="message1 !=='installTheProductivityAgent'" (click)="generalService.openModal({data: modalComponent})">{{ linkText | translate}}</a>
  <div style="align-self: end">
    <button
      class="btn mr-2 btn-form skip_btn"
      type="button"
      (click)="close()"
      translate
    >
      {{ "Saltar incorporación" | translate }}
    </button>
  </div>
</div>
