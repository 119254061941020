<div class="card card-custom card-stretch gutter-b">
  <!-- Header -->
  <div class="card-header align-items-center border-0 mt-4">
    <h3 class="card-title align-items-start flex-column">
      <span class="font-weight-bolder text-dark">My Activity</span>
      <span class="text-muted mt-3 font-weight-bold font-size-sm"
        >890,344 Sales</span
      >
    </h3>
    <div class="card-toolbar">
      <div
        ngbDropdown
        placement="bottom-right"
        class="dropdown dropdown-inline"
        title="Quick actions"
      >
        <a
          ngbDropdownToggle
          class="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="ki ki-bold-more-hor"></i>
        </a>
        <div
          ngbDropdownMenu
          class="dropdown-menu dropdown-menu-md dropdown-menu-right"
        >
          <app-dropdown-menu1></app-dropdown-menu1>
        </div>
      </div>
    </div>
  </div>
  <!-- Body -->
  <div class="card-body pt-4">
    <div class="timeline timeline-6 mt-3">
      <!--begin::Item-->
      <div class="timeline-item align-items-start">
        <!--begin::Label-->
        <div
          class="timeline-label font-weight-bolder text-dark-75 font-size-lg"
        >
          08:42
        </div>
        <!--end::Label-->

        <!--begin::Badge-->
        <div class="timeline-badge">
          <i class="fa fa-genderless text-warning icon-xl"></i>
        </div>
        <!--end::Badge-->

        <!--begin::Text-->
        <div
          class="font-weight-mormal font-size-lg timeline-content text-muted pl-3"
        >
          Outlines keep you honest. And keep structure
        </div>
        <!--end::Text-->
      </div>
      <!--end::Item-->

      <!--begin::Item-->
      <div class="timeline-item align-items-start">
        <!--begin::Label-->
        <div
          class="timeline-label font-weight-bolder text-dark-75 font-size-lg"
        >
          10:00
        </div>
        <!--end::Label-->

        <!--begin::Badge-->
        <div class="timeline-badge">
          <i class="fa fa-genderless text-success icon-xl"></i>
        </div>
        <!--end::Badge-->

        <!--begin::Content-->
        <div class="timeline-content d-flex">
          <span class="font-weight-bolder text-dark-75 pl-3 font-size-lg"
            >AEOL meeting</span
          >
        </div>
        <!--end::Content-->
      </div>
      <!--end::Item-->

      <!--begin::Item-->
      <div class="timeline-item align-items-start">
        <!--begin::Label-->
        <div
          class="timeline-label font-weight-bolder text-dark-75 font-size-lg"
        >
          14:37
        </div>
        <!--end::Label-->

        <!--begin::Badge-->
        <div class="timeline-badge">
          <i class="fa fa-genderless text-danger icon-xl"></i>
        </div>
        <!--end::Badge-->

        <!--begin::Desc-->
        <div
          class="timeline-content font-weight-bolder font-size-lg text-dark-75 pl-3"
        >
          Make deposit
          <a href="#" class="text-primary">USD 700</a>. to ESL
        </div>
        <!--end::Desc-->
      </div>
      <!--end::Item-->

      <!--begin::Item-->
      <div class="timeline-item align-items-start">
        <!--begin::Label-->
        <div
          class="timeline-label font-weight-bolder text-dark-75 font-size-lg"
        >
          16:50
        </div>
        <!--end::Label-->

        <!--begin::Badge-->
        <div class="timeline-badge">
          <i class="fa fa-genderless text-primary icon-xl"></i>
        </div>
        <!--end::Badge-->

        <!--begin::Text-->
        <div
          class="timeline-content font-weight-mormal font-size-lg text-muted pl-3"
        >
          Indulging in poorly driving and keep structure keep great
        </div>
        <!--end::Text-->
      </div>
      <!--end::Item-->

      <!--begin::Item-->
      <div class="timeline-item align-items-start">
        <!--begin::Label-->
        <div
          class="timeline-label font-weight-bolder text-dark-75 font-size-lg"
        >
          21:03
        </div>
        <!--end::Label-->

        <!--begin::Badge-->
        <div class="timeline-badge">
          <i class="fa fa-genderless text-danger icon-xl"></i>
        </div>
        <!--end::Badge-->

        <!--begin::Desc-->
        <div
          class="timeline-content font-weight-bolder text-dark-75 pl-3 font-size-lg"
        >
          New order placed <a href="#" class="text-primary">#XF-2356</a>.
        </div>
        <!--end::Desc-->
      </div>
      <!--end::Item-->

      <!--begin::Item-->
      <div class="timeline-item align-items-start">
        <!--begin::Label-->
        <div
          class="timeline-label font-weight-bolder text-dark-75 font-size-lg"
        >
          23:07
        </div>
        <!--end::Label-->

        <!--begin::Badge-->
        <div class="timeline-badge">
          <i class="fa fa-genderless text-info icon-xl"></i>
        </div>
        <!--end::Badge-->

        <!--begin::Text-->
        <div
          class="timeline-content font-weight-mormal font-size-lg text-muted pl-3"
        >
          Outlines keep and you honest. Indulging in poorly driving
        </div>
        <!--end::Text-->
      </div>
      <!--end::Item-->

      <!--begin::Item-->
      <div class="timeline-item align-items-start">
        <!--begin::Label-->
        <div
          class="timeline-label font-weight-bolder text-dark-75 font-size-lg"
        >
          16:50
        </div>
        <!--end::Label-->

        <!--begin::Badge-->
        <div class="timeline-badge">
          <i class="fa fa-genderless text-primary icon-xl"></i>
        </div>
        <!--end::Badge-->

        <!--begin::Text-->
        <div
          class="timeline-content font-weight-mormal font-size-lg text-muted pl-3"
        >
          Indulging in poorly driving and keep structure keep great
        </div>
        <!--end::Text-->
      </div>
      <!--end::Item-->

      <!--begin::Item-->
      <div class="timeline-item align-items-start">
        <!--begin::Label-->
        <div
          class="timeline-label font-weight-bolder text-dark-75 font-size-lg"
        >
          21:03
        </div>
        <!--end::Label-->

        <!--begin::Badge-->
        <div class="timeline-badge">
          <i class="fa fa-genderless text-danger icon-xl"></i>
        </div>
        <!--end::Badge-->

        <!--begin::Desc-->
        <div
          class="timeline-content font-weight-bolder font-size-lg text-dark-75 pl-3"
        >
          New order placed <a href="#" class="text-primary">#XF-2356</a>.
        </div>
        <!--end::Desc-->
      </div>
      <!--end::Item-->
    </div>
  </div>
</div>
