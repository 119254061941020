import { Component, Input, OnInit } from '@angular/core';
import { LayoutService } from '../../../../../core';
import { ReportsService } from 'src/app/services/reports.service';
import * as moment from 'moment';

@Component({
  selector: 'app-stats-widget11',
  templateUrl: './stats-widget11.component.html',
})
export class StatsWidget11Component implements OnInit {
  @Input() cssClass;
  @Input() symbolShape;
  @Input() baseColor;
  chartOptions: any = {};
  fontFamily = '';
  colorsGrayGray500 = '';
  colorsGrayGray200 = '';
  colorsGrayGray300 = '';
  colorsThemeBase = '';
  colorsThemeLight = '';
  symbolCSSClasses = '';
  svgCSSClasses = '';
  dataFilter:any;
  enddate: any;
  startdate:any;
  proHour:number =8;
  productivityData: any;
  totalTime:any;

  constructor(private layout: LayoutService,
              private reportService: ReportsService) { }

  loadLayoutView() {
    this.fontFamily = this.layout.getProp('js.fontFamily');
    this.colorsGrayGray500 = this.layout.getProp('js.colors.gray.gray500');
    this.colorsGrayGray200 = this.layout.getProp('js.colors.gray.gray200');
    this.colorsGrayGray300 = this.layout.getProp('js.colors.gray.gray300');
    this.colorsThemeBase = this.layout.getProp(
      `js.colors.theme.base.${this.baseColor}`
    );

    this.colorsThemeLight = this.layout.getProp(
      `js.colors.theme.light.${this.baseColor}`
    );
  }

  ngOnInit(): void {
    this.enddate = moment().format("YYYY-MM-DD");   
    this.startdate = moment(this.enddate).subtract(30, 'days').format("YYYY-MM-DD");  
    
    if (!this.baseColor) {
      this.baseColor = 'success';
    }

    if (!this.symbolShape) {
      this.symbolShape = 'symbol-circle';
    }
    this.loadLayoutView();
    this.symbolCSSClasses = `symbol ${this.symbolShape} symbol-50 symbol-light-${this.baseColor} mr-2`;
    this.svgCSSClasses = `svg-icon svg-icon-xl svg-icon-${this.baseColor}`;
    this.load();
  }

  valueHours(time) {
    let Hours = String(time);
    let H = Hours.split(":")[0];
    let M = Hours.split(":")[1];
    let S = Hours.split(":")[2];
    time = parseFloat(H + "." + M);

    return time;
 }  

  load(){
    this.dataFilter ={
      start_date :this.startdate,
      end_date: this.enddate,
      user_group_id: '',
      user_id: '',
      productivity_hour: this.proHour
     }
    this.reportService.activityDaily(this.dataFilter).subscribe((res)=>{
      this.productivityData = res;
      this.chartOptions = this.getChartOptions();
    })
  }

  getChartOptions() {
    let categoriesDay = this.productivityData.map((value) => moment(value.date).format('MMM DD'));
    let total_Time= this.productivityData.map(value => parseFloat(this.secToH(this.valueHours((parseInt(value.productive_time) + parseInt(value.uncategorized_time) + parseInt(value.manual_time) + parseInt(value.manual_time_system))))));
    // let dataNotProductive = this.productivityData.map(value => this.valueHours((value.none_productive_time)?value.none_productive_time:'00:00:00'));
    this.totalTime = 0
    for(var i=0; i<total_Time.length; i++){
        this.totalTime += total_Time[i]
    }
    this.totalTime = this.totalTime.toFixed(2);
    return {
      series: [{
        name: 'Total time',
        data: total_Time
      }],
      chart: {
        type: 'area',
        height: 150,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        sparkline: {
          enabled: true
        }
      },
      plotOptions: {},
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: 'solid',
        opacity: 1
      },
      stroke: {
        curve: 'smooth',
        show: true,
        width: 3,
        colors: [this.colorsThemeBase]
      },
      xaxis: {
        categories: categoriesDay,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false,
          style: {
            colors: this.colorsGrayGray500,
            fontSize: '12px',
            fontFamily: this.fontFamily
          }
        },
        crosshairs: {
          show: false,
          position: 'front',
          stroke: {
            color: this.colorsGrayGray300,
            width: 1,
            dashArray: 3
          }
        },
        tooltip: {
          enabled: true,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: '12px',
            fontFamily: this.fontFamily
          }
        }
      },
      yaxis: {
        min: 0,
        max: 100,
        labels: {
          show: false,
          style: {
            colors: this.colorsGrayGray500,
            fontSize: '12px',
            fontFamily: this.fontFamily
          }
        }
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0
          }
        },
        hover: {
          filter: {
            type: 'none',
            value: 0
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0
          }
        }
      },
      tooltip: {
        style: {
          fontSize: '12px',
          fontFamily: this.fontFamily
        },
        y: {
          formatter: (val) => {
            return val + " Hours";
          }
        }
      },
      colors: [this.colorsThemeLight],
      markers: {
        colors: [this.colorsThemeLight],
        strokeColor: [this.colorsThemeBase],
        strokeWidth: 3
      }
    };
  }

  secToH(seconds){
    var hours = Math.floor(seconds / 60 / 60);

    var minutes = Math.floor(seconds / 60) - (hours * 60);

   var formatted = hours.toString().padStart(2, '0') + '.' + minutes.toString().padStart(2, '0');
   return formatted
  }
}
