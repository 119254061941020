import { Component, OnInit,Input } from "@angular/core";
import { LayoutService } from "../../../../../core";
import { ReportsService } from "src/app/services/reports.service";
import * as moment from "moment";
import { GeneralService } from "src/app/services/general.service";
@Component({
  selector: "app-stats-widget12",
  templateUrl: "./stats-widget12.component.html",
})
export class StatsWidget12Component implements OnInit {
  @Input() widget12Data: any[];
  chartOptions: any = {};
  fontFamily = "";
  colorsGrayGray500 = "";
  colorsGrayGray200 = "";
  colorsGrayGray300 = "";
  colorsThemeBaseDanger = "";
  colorsThemeBasePrimary = "";
  colorsThemeLightPrimary = "";
  dataFilter: any;
  enddate: any;
  startdate: any;
  proHour: number = 8;
  productivityData: any;
  totalTime: any = 0;
  Reuninones = 'Reuniones';
  meetingTooltip = 'Summary of time invested in meetings in the last 30 days.';
  constructor(
    private layout: LayoutService,
    private reportService: ReportsService,
    private generalService: GeneralService
  ) {
    this.fontFamily = this.layout.getProp("js.fontFamily");
    this.colorsGrayGray500 = this.layout.getProp("js.colors.gray.gray500");
    this.colorsGrayGray200 = this.layout.getProp("js.colors.gray.gray200");
    this.colorsGrayGray300 = this.layout.getProp("js.colors.gray.gray300");
    this.colorsThemeBaseDanger = this.layout.getProp(
      "js.colors.theme.base.danger"
    );
    this.colorsThemeBasePrimary = this.layout.getProp(
      "js.colors.theme.base.primary"
    );
    this.colorsThemeLightPrimary = this.layout.getProp(
      "js.colors.theme.light.primary"
    );
  }

  ngOnInit(): void {
    var lang = localStorage.getItem('language');
    if(lang == 'es'){
      this.Reuninones = 'Reuniones';
      this.meetingTooltip = 'Resumen del tiempo invertido en reuniones en los últimos 30 días.';
    }else if(lang == 'pt'){
      this.Reuninones = 'Reuniões';
      this.meetingTooltip = 'Resumo do tempo investido em reuniões nos últimos 30 dias.';
    }else{
      this.Reuninones = 'Meetings';
      this.meetingTooltip = 'Summary of time invested in meetings in the last 30 days.';
    }
    this.enddate = moment().format("YYYY-MM-DD");
    this.startdate = moment(this.enddate)
      .subtract(30, "days")
      .format("YYYY-MM-DD");
    this.load();
  }

  valueHours(time) {
    let Hours = String(time);
    let H = Hours.split(":")[0];
    let M = Hours.split(":")[1];
    let S = Hours.split(":")[2];
    time = parseFloat(H + "." + M);

    return time;
  }

  load() {
    this.dataFilter = {
      start_date: this.startdate,
      end_date: this.enddate,
      user_group_id: "",
      user_id: "",
      productivity_hour: this.proHour,
    };


    const resultPromise = new Promise((resolve, reject) => {
      resolve(this.getPermission());
  })

     resultPromise.then(res=>{
      var perArr:any= res
      if(perArr !== undefined && perArr !== null){
        let result:any;
        let result2:any;

        if(perArr.module !== undefined && perArr.module !== null){
          result = perArr.module.find( ({ slug }) => slug === 'productividad-diaria' );
        }
        if(perArr.permission !== undefined && perArr.permission !== null){
          result2 = perArr.permission.find( ({ name }) => name === 'report_activity_daily' );
        }
         
        if (result !== undefined && result2 !== undefined) {
        //   this.generalService.dashboard().subscribe((res)=>{
        //     this.productivityData = res.meeting_time;
        //     this.getChartOptions();
        //  })
          //this.reportService.activityDaily(this.dataFilter).subscribe((res) => {
            setTimeout(() => {
            this.productivityData = this.widget12Data;
            this.getChartOptions();
          }, 1500);
          //});

         

        }
      }

     })
   

    this.getChartOptions();
  }

  async getPermission() {
    const permission = await JSON.parse(localStorage.getItem('permissions'));
    const module = await JSON.parse(localStorage.getItem('modules'));


    return {permission,module};
  }

  getChartOptions() {
    let categoriesDay = [];
    let dataMeeting = [];

    if (
      this.productivityData != undefined &&
      this.productivityData != null
    ) {
      // categoriesDay = this.productivityData.map((value) =>
      //   moment(value.day).format("MMM DD")
      // );
      dataMeeting = this.productivityData.map((value) =>
        parseFloat(
          this.secondsToHours(
            this.valueHours(value.manual_time ? value.manual_time : "00:00:00")
          )
        )
      );
      this.totalTime = 0;
      if(this.productivityData !== undefined && this.productivityData !== null && this.productivityData.length > 0){
        categoriesDay = this.productivityData.map((value) => moment(value.start_time).format('MMM DD'));
      }

      console.log("Total",this.productivityData[4].manual_time);
      //this.totalTime =  this.secondsToHours(this.productivityData);
      // console.log(this.totalTime);
       for (var i = 0; i < this.productivityData.length; i++) {
         this.totalTime += parseFloat(this.productivityData[i].manual_time);
       }
       this.totalTime = this.secondsToHours(this.totalTime);
      // if (this.totalTime > 0) {
      //   this.totalTime= this.totalTime.toFixed(2);
      // }
    }
  console.log('this.productivitydata Meeting',this.productivityData);
  
    this.chartOptions = {
      series: [
        {
          name: "Meeting",
          data: dataMeeting,
        },
      ],
      chart: {
        type: "area",
        height: 150,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "solid",
        opacity: 1,
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [this.colorsThemeBasePrimary],
      },
      xaxis: {
        categories: categoriesDay,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          style: {
            colors: this.colorsGrayGray500,
            fontSize: "12px",
            fontFamily: this.fontFamily,
          },
        },
        crosshairs: {
          show: false,
          position: "front",
          stroke: {
            color: this.colorsGrayGray300,
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
        //  enabled: true,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: "12px",
            fontFamily: this.fontFamily,
          },
        },
      },
      yaxis: {
        // min: 0,
        // max: 50,
        labels: {
          show: false,
          style: {
            colors: this.colorsGrayGray500,
            fontSize: "12px",
            fontFamily: this.fontFamily,
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: this.fontFamily,
        },
        y: {
          // tslint:disable-next-line
          formatter: function (val) {
            if(val > 1){
              return val + " Hours";
            }else{
              return val + " Hour";
            }
          },
        },
        marker: {
          show: false,
        },
      },
      colors: [this.colorsThemeLightPrimary],
      markers: {
        colors: this.colorsThemeLightPrimary,
        strokeColor: [this.colorsThemeBasePrimary],
        strokeWidth: 3,
      },
    };
  }

  secondsToHours(time) {
    const hour = time / 3600;

    return hour.toFixed(2);
  }
}
