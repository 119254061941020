<div class="row">
  <div class="col-xl-4">
    <app-tiles-widget1
      cssClass="gutter-b card-stretch"
      [chartColor]="'danger'"
    ></app-tiles-widget1>
  </div>
  <div class="col-xl-8">
    <div class="row">
      <div class="col-xl-3">
        <app-tiles-widget3
          cssClass="gutter-b"
          [widgetHeight]="'150px'"
        ></app-tiles-widget3>
      </div>
      <div class="col-xl-9">
        <app-tiles-widget10
          cssClass="gutter-b"
          [widgetHeight]="'150px'"
        ></app-tiles-widget10>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6">
        <app-tiles-widget13
          cssClass="gutter-b"
          [widgetHeight]="'175px'"
        ></app-tiles-widget13>
        <div class="row">
          <div class="col-xl-6">
            <app-tiles-widget11
              cssClass="gutter-b"
              [widgetHeight]="'150px'"
              [baseColor]="'primary'"
            ></app-tiles-widget11>
          </div>
          <div class="col-xl-6">
            <app-tiles-widget12
              cssClass="gutter-b"
              [widgetHeight]="'150px'"
              [iconColor]="'success'"
            ></app-tiles-widget12>
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <app-tiles-widget14
          cssClass="gutter-b card-stretch"
        ></app-tiles-widget14>
      </div>
    </div>
  </div>
  <!-- <div class="col-lg-6 col-xxl-4">
    <app-mixed-widget1></app-mixed-widget1>
  </div>
  <div class="col-lg-6 col-xxl-4">
    <app-lists-widget9></app-lists-widget9>
  </div>
  <div class="col-lg-6 col-xxl-4">
    <app-stats-widget11></app-stats-widget11>
    <app-stats-widget12></app-stats-widget12>
  </div>
  <div class="col-lg-6 col-xxl-4 order-1 order-xxl-1">
    <app-lists-widget1></app-lists-widget1>
  </div>
  <div class="col-xxl-8 order-2 order-xxl-1">
    <app-advance-tables-widget2></app-advance-tables-widget2>
  </div>
  <div class="col-lg-6 col-xxl-4 order-1 order-xxl-2">
    <app-lists-widget3></app-lists-widget3>
  </div>
  <div class="col-lg-6 col-xxl-4 order-1 order-xxl-2">
    <app-lists-widget4></app-lists-widget4>
  </div>
  <div class="col-lg-12 col-xxl-4 order-1 order-xxl-2">
    <app-lists-widget8></app-lists-widget8>
  </div>
</div>
<div class="row">
  <div class="col-lg-4">
    <app-mixed-widget14></app-mixed-widget14>
  </div>
  <div class="col-lg-8">
    <app-advance-tables-widget4></app-advance-tables-widget4>
  </div> -->
</div>

<div class="row">
  <div class="col-lg-6 col-xxl-4">
    <app-mixed-widget6
      cssClass="gutter-b card-stretch"
      [chartColor]="'danger'"
    ></app-mixed-widget6>
  </div>
  <div class="col-lg-6 col-xxl-8">
    <app-advance-tables-widget1
      cssClass="gutter-b card-stretch"
    ></app-advance-tables-widget1>
  </div>
</div>

<div class="row">
  <div class="col-xl-4">
    <app-mixed-widget10 cssClass="gutter-b card-stretch"></app-mixed-widget10>
  </div>
  <div class="col-xl-4">
    <app-mixed-widget11 cssClass="gutter-b card-stretch"></app-mixed-widget11>
  </div>
  <div class="col-xl-4">
    <app-mixed-widget12 cssClass="gutter-b card-stretch"></app-mixed-widget12>
  </div>
</div>

<div class="row">
  <div class="col-lg-6">
    <app-lists-widget10 cssClass="gutter-b card-stretch"></app-lists-widget10>
  </div>
  <div class="col-lg-6">
    <app-lists-widget11 cssClass="gutter-b card-stretch"></app-lists-widget11>
  </div>
</div>
