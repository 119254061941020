// Spain
export const locale = {
  lang: 'es',
  data: {
    'Agregar Tiempo Manual': 'Agregar Tiempo Manual',
    'Actividad por Usuario': 'Actividad por Usuario',
    'Uso de Aplicaciones': 'Uso de Aplicaciones',
    'Tiempo Trabajado': 'Tiempo Trabajado',
    'Capturas de Pantalla': 'Capturas de Pantalla',
    'Reportes de Productividad': 'Reportes de Productividad',   
    'Grupos de Usuarios': 'Grupos de Usuarios',
    'Ventana de Tiempo': 'Ventana de Tiempo',
    'Configuración de Seguridad': 'Configuración de Seguridad',
    'Aplicaciones': 'Aplicaciones',
    'Grupos de Aplicaciones': 'Grupos de Aplicaciones',
    'Empresas Involucradas': 'Empresas Involucradas',
    'Roles': 'Roles',
    'Configuración de Cliente': 'Configuración de Cliente',
    'Recolector de Logs': 'Recolector de Logs',
    'Papelera': 'Papelera',
    'Pagos Recibidos': 'Pagos Recibidos',
    'Tiempo Manual del Sistema':'Tiempo Manual',
    'Tiempo Manual del Sistemaa':'Reunión',
    'Reunión':'Tiempo Manual',
    'Reuniones':'Reuniones',
    'Usuarios': 'Usuarios',
    'Planilla Automática de Horas': 'Planilla Automática de Horas',    
    'Productividad Diaria': 'Productividad Diaria',
    'Ante.': 'Ante.',
    'Prox.': 'Prox.',
    'Hoy': 'Hoy',
    'Mes': 'Mes',
    'Semana': 'Semana',
    'Día': 'Día',
    'Año': 'Año',
    'Guardar': 'Guardar',
    'Cerrar': 'Cerrar',
    'Nombre': 'Nombre',
    'Fecha': 'Fecha',
    'Hora inicio': 'Hora inicio',
    'Hora finalización': 'Hora finalización',
    'Ninguna': 'Ninguna',
    'Seleccione una tarea': 'Seleccione una tarea',
    'Entrar': 'Entrar',
    'Perfil': 'Perfil',
    'Mi Perfil': 'Mi Perfil',
    'Inactividad': 'Inactividad',
    'Privado': 'Privado',
    'Sin categorizar': 'Sin categorizar',
    'No productivo': 'No productivo',
    'No Productivo': 'No Productivo',
    'Productivo': 'Productivo',
    'Filtros': 'Filtros',
    'Nombre de la aplicación': 'Nombre de la aplicación',
    'Descripción': 'Descripción',
    'Duración': 'Duración',
    'Horas': 'Horas',
    'Añadir nuevo usuario': 'Añadir nuevo usuario',
    'Importar Excel': 'Importar Excel',
    'Grupos': 'Grupos',
    'Editar': 'Editar',
    'Si': 'Si',
    'Rango de horas':'Rango de horas',
    '¿Quieres eliminar este usuario de forma permanente?': '¿Quieres eliminar este usuario de forma permanente?',
    'Cancelar': 'Cancelar',
    'Crear': 'Crear',
    'Confirmar contraseña': 'Confirmar contraseña',
    'Estoy de acuerdo con los': 'Estoy de acuerdo con los',
    'términos y condiciones.': 'términos y condiciones.',
    'Estoy de acuerdo con los terminos y condiciones': 'Estoy de acuerdo con los terminos y condiciones',
    'Configuración de seguridad': 'Configuración de seguridad',
    'correo electronico es requerido':'correo electronico es requerido',
    'El correo electrónico es inválido': 'El correo electrónico es inválido',
    'Se requiere el nombre':'Se requiere el nombre',
    'El nombre debe tener al menos 2 caracteres':'El nombre debe tener al menos 2 caracteres',
    'El nombre no debe exceder los 14 caracteres' :'El nombre no debe exceder los 14 caracteres',
    'Se requiere empresa': 'Se requiere empresa',
    'Se requiere rol' :'Se requiere rol',
    'Estado': 'Estado',
    'Activo':'Activo',
    'Inactivo': 'Inactivo',
    'Se requiere estado' :'Se requiere estado',
    'Se requiere tarifa por hora' :'Se requiere tarifa por hora',
    'Se requiere límite semanal' :'Se requiere límite semanal',
    'se requiere contraseña':'se requiere contraseña',
    'La contraseña debe tener al menos 6 caracteres':'La contraseña debe tener al menos 6 caracteres',
    'La contraseña no debe exceder los 20 caracteres' :'La contraseña no debe exceder los 20 caracteres',
    'Repita la contraseña':'Repita la contraseña',
    'Se requiere repetir la contraseña':'Se requiere repetir la contraseña',
    'Repetir la contraseña no coincide con la contraseña':'Las contraseñas no coinciden',
    'Introduce la contraseña para actualizar':'Introduce la contraseña para actualizar',
    'Archivo Excel': 'Archivo Excel',
    'Agregar grupos adicionales' :'Agregar grupos adicionales',
    'Seleccione al menos un grupo':'Seleccione al menos un grupo',
    'Rol': 'Rol',
    'Tarifa por Hora':'Tarifa por Hora',
    'Límite Semanal (Horas)':'Límite Semanal (Horas)',
    'Primera Conexión':'Primera Conexión',
    'Ultima Conexión':'Ultima Conexión',
    'Estatus de Usuario':'Estatus de Usuario',
    'Modo del Agente':'Modo del Agente',
    'Acciones':'Acciones',
    'Desactivado':'Desactivado',
    'Activado':'Activado',
    'Interactivo':'Interactivo',
    'Silencioso':'Silencioso',
    'Empresa':'Empresa',
    'Correo electrónico': 'Correo electrónico',
    'Contraseña':'Contraseña',
    'Usuario nuevo':'Usuario nuevo',
    'editar usuario':'Editar usuario',
    'Se requiere usuario':'Se requiere usuario',
    'El grupo de usuarios es obligatorio':'El grupo de usuarios es obligatorio',
    'Productiva':'Productiva',
    'No Productiva':'No Productiva',
    'Sin Categorizar':'Sin Categorizar',
    'Empresas':'Empresas',
    'Descargas':'Descargas',
    'Ayuda':'Ayuda',
    'Inducción':'Inducción',
    'Salir':'Salir',
    'Añadir nuevo grupo':'Añadir nuevo grupo',
    'No puedes eliminar este registro porque tiene informacion asociada':'No puedes eliminar este registro porque tiene informacion asociada',
    'ordenar por':'Ordenar por',
    'Añadir nuevo log':'Añadir nuevo log',
    'Log Solicitado':'Log Solicitado',
    'Fecha/Hora Solicitud':'Fecha/Hora Solicitud',
    'Recibido':'Recibido',
    'Log agente':'Log agente',
    'Base de datos':'Base de datos',
    'Monitor':'Monitor',
    'Descargar':'Descargar',
    'Eliminar':'Eliminar',
    'se requiere grupo de usuarios':'Se requiere grupo de usuarios',
    'Lunes':'Lunes',
    'Martes':'Martes',
    'Miércoles':'Miércoles',
    'Jueves':'Jueves',
    'Viernes':'Viernes',
    'Sábado':'Sábado',
    'Domingo':'Domingo',
    'Hasta':'Hasta',
    'Desde':'Desde',
    'Buscar':'Buscar',
    'Permisos':'Permisos',
    'Tiempo Reunión':'Tiempo Reunión',
    'Productividad':'Productividad',
    'No hay registros':'No hay registros',
    'Actualizar usuario':'Actualizar usuario',
    'Grupo':'Grupo',
    'Editar Grupo':'Editar Grupo',
    '¿Quieres eliminar este grupo de forma permanente?': '¿Quieres eliminar este grupo de forma permanente?',
    '¿Quieres eliminar este regla de forma permanente?': '¿Quieres eliminar este regla de forma permanente?',
    'Uso':'Uso',
    'Tiempo total':'Tiempo total',
    'Tiempo':'Tiempo',
    'Promedio productividad':'Promedio productividad',
    'Revisar':'Revisar',
    '(Solo para despliegues con Microsoft Active Directory)':'(Solo para despliegues con Microsoft Active Directory)',
    'Desintalador de Windows':'Desintalador de Windows',
    'Si tu trabajo o el de tu equipo involucra actividades fuera del computador, esta actividad puede agregarse en el calendario de ClowdWork para ser agregado al reporte de actividad.':"Si tu trabajo o el de tu equipo involucra actividades fuera del computador, esta actividad puede agregarse en el calendario de ClowdWork para ser agregado al reporte de actividad.",
    "Solo tienes que hacer clic sobre la fecha y agregar el nombre de la actividad y las horas invertidas en ella. Esta información se visualizará en los reportes de productividad.":"Solo tienes que hacer clic sobre la fecha y agregar el nombre de la actividad y las horas invertidas en ella. Esta información se visualizará en los reportes de productividad.",
    "El administrador podrá visualizar fácilmente las actividades fuera de línea de sus colaboradores a través de los filtros en los reportes.":"El administrador podrá visualizar fácilmente las actividades fuera de línea de sus colaboradores a través de los filtros en los reportes.",
    "assets/media/help/manualtimesp.jpg":"assets/media/help/manualtimesp.jpg",
    "assets/media/help/manualtime1sp.jpg":"assets/media/help/manualtime1sp.jpg",
    "Más":"Más",
    "Menos":"Menos",
    "Abrir": "Abrir",
    "Enero" : "Enero",
    "Febrero" : "Febrero",
    "Marzo" : "Marzo",
    "Abril" : "Abril",
    "Mayo" : "Mayo",
    "Junio" : "Junio",
    "Julio" : "Julio",
    "Agosto" : "Agosto",
    "Septiembre" : "Septiembre",
    "Octubre" : "Octubre",
    "Noviembre" : "Noviembre",
    "Diciembre" : "Diciembre",
    "la fecha es": "La fecha es",
    "¡Hola": "¡Hola!",
    "Actualización del perfil": "Actualización del perfil",
    "Fecha de nacimiento": "Fecha de nacimiento",
    "Usuario eliminado con éxito": "Usuario eliminado con éxito",
    "La configuración de los usuarios se puede gestionar fácilmente en la plataforma.":"La configuración de los usuarios se puede gestionar fácilmente en la plataforma.",
    "Los usuarios y las capturas de pantalla pueden activarse o desactivarse fácilmente, según sea necesario, haciendo clic en la columna correspondiente.":"Los usuarios y las capturas de pantalla pueden activarse o desactivarse fácilmente, según sea necesario, haciendo clic en la columna correspondiente.",
    'Los usuarios pueden ser eliminados en la columna "Acciones". Haciendo clic en el icono del "bolígrafo", se pueden modificar los ajustes de cada usuario, como se muestra en la siguiente imagen:': 'Los usuarios pueden ser eliminados en la columna "Acciones". Haciendo clic en el icono del "lápiz", se pueden modificar los ajustes de cada usuario, como se muestra en la siguiente imagen:',
    'assets/media/help/users1sp.gif':'assets/media/help/users1sp.gif',
    'assets/media/help/users2sp.gif':'assets/media/help/users2sp.gif',
    "La plataforma Clowdwork permite crear fácilmente grupos o departamentos de la empresa para poder visualizar los informes por áreas. Los usuarios pueden ser transferidos de un grupo a otro simplemente arrastrando el ícono del usuario a cualquier grupo deseado.":"Clowdwork permite crear fácilmente grupos o departamentos de la empresa para poder visualizar los informes por áreas. Los usuarios pueden ser transferidos de un grupo a otro simplemente arrastrando el ícono del usuario a cualquier grupo deseado.",
    "Los grupos pueden ser creados fácilmente y los usuarios pueden ser eliminados fácilmente y sus nombres pueden ser cambiados si es necesario":"Los grupos pueden ser creados y los usuarios pueden ser eliminados fácilmente.  Sus nombres pueden ser cambiados si es necesario",
    'assets/media/help/group1sp.gif':'assets/media/help/group1sp.gif',
    "Usuario añadido con éxito":"Usuario añadido con éxito",
    "Usuario actualizado con éxito":"Usuario actualizado con éxito",
    'Api no encontrada para agregar grupos de usuarios':'API no encontrada para agregar grupos de usuarios',
    'Grupo eliminado con éxito.':'Grupo eliminado con éxito.',
    'El grupo cambió con éxito.':'El grupo cambió con éxito.',
    'Algo salió mal':'Algo salió mal',
    'Grupo añadido con éxito':'Grupo añadido con éxito',
    'Grupo actualizado con éxito.':'Grupo actualizado con éxito.',
    "Una ventana de tiempo es un período entre una hora de inicio y una hora de finalización en la cual, no se generarán reportes para toda la empresa, grupo o usuario seleccionado.":"Una ventana de tiempo es un período entre una hora de inicio y una hora de finalización en la cual, no se generarán reportes para toda la empresa, grupo o usuario seleccionado.",
    'assets/media/help/windowtracking1sp.gif':'assets/media/help/windowtracking1sp.gif',
    'La funcionalidad de ventana de tiempo, está preconfigurada para que siga una jerarquía entre los periodos / reglas de tiempo.':'La funcionalidad de ventana de tiempo, está preconfigurada para que siga una jerarquía entre los periodos / reglas de tiempo.',
    "La jerarquía de los periodos es la siguiente:":"La jerarquía de los periodos es la siguiente:",
    'Días':'Días',
    'es la primera.':'es la primera.',
    'es la segunda, solapa a Días.':'es la segunda. Solapa a Días.',
    'es la tercera, solapa a Días y a Semana.':'es la tercera. Solapa a Días y a Semana.',
    'Por ejemplo, si establece en':'Por ejemplo, si establece',
     'que todos los sábados de 15:00 a 23:59, entonces todos los sábados estará inhabilitado el registro de actividades en ese intervalo de horas.':'todos los sábados de 15:00 a 23:59, entonces todos los sábados estará inhabilitado el registro de actividades en ese intervalo de horas.',
     'assets/media/help/windowtracking2sp.jpg':'assets/media/help/windowtracking2sp.jpg',
     'assets/media/help/windowtracking3sp.jpg':'assets/media/help/windowtracking3sp.jpg',
     'assets/media/help/windowtracking4sp.jpg':'assets/media/help/windowtracking4sp.jpg',
     'Si luego establece en':'Si luego establece',
     "que todos los fines de semana de 15:00 a 23:59, entonces prevalece esta regla y todos los fines de semana estará inhabilitado el registro de actividades en ese intervalo de horas.":"todos los fines de semana de 15:00 a 23:59, entonces prevalece esta regla y todos los fines de semana estará inhabilitado el registro de actividades en ese intervalo de horas.",
     "de 15:00 a 23:59, entonces en el día que eligió en la fecha  estará inhabilitado el registro de actividades en ese intervalo de horas.":"de 15:00 a 23:59, entonces en el día que eligió estará inhabilitado el registro de actividades en ese intervalo de horas.",
     "En el ejemplo de prueba se establecio el domingo 7-01-2023, entonces, esta regla solapará a la regla de Semana para este día en específico.":"En el ejemplo de prueba se estableció el domingo 7-01-2023, entonces, esta regla solapará a la regla de Semana para este día en específico.",
     'Antes de crear las reglas de tiempo, valide que la hora establecida en el campo':'Antes de crear las reglas de tiempo, se debe validar que la hora establecida en el campo',
     'sea la correcta (cada navegador muestra el formato de hora diferente - 24 hrs  / AM -PM).':'sea la correcta (cada navegador muestra el formato de hora diferente - 24 hrs  / AM -PM).',
     'Nota':'Nota',
     'Las reglas de tiempo creadas se aplicarán después del próximo reinicio de la PC del usuario. Este reinicio es necesario para que el agente tome las configuraciones asignadas desde el módulo de ventana de tiempo.':"Las reglas de tiempo creadas se aplicarán después del próximo reinicio de la PC del usuario. Este reinicio es necesario para que el agente tome las configuraciones asignadas desde el módulo de ventana de tiempo.",
    "VENTANA DE TIEMPO":"VENTANA DE TIEMPO", 
    "Todos los grupos":"Todos los grupos",
    "Todos los usuarios":"Todos los usuarios",
    "Crear regla":"Crear regla",
    "Filtrar reglas":"Filtrar reglas",
    "Nombre regla":"Nombre regla",    
    "Inicio":"Inicio",    
    "Fin":"Fin",   
    "Seguimiento":"Seguimiento",    
    "Período":"Período",    
    "Usuario":"Usuario",
    "Ver":"Ver",
    "Deshabilitar seguimiento":"Deshabilitar seguimiento",
    "Días de semana":"Días de semana",
    "Fines de semana":"Fines de semana",
    "Ver regla":"Ver regla",
    "Editar regla": "Editar regla",
    "Grupo de usuarios":"Grupo de usuarios",
    'Software de seguimiento de tiempo':'Software de seguimiento de tiempo',
    'completamente automático.':'completamente automático.',
    'El informe de actividad de los usuarios le permite ver la cantidad de tiempo que cualquier miembro del equipo invierte en actividades productivas e improductivas, actividades privadas, trabajo fuera del ordenador y su tiempo de inactividad.':'El informe de actividad de los usuarios permite ver la cantidad de tiempo que cualquier miembro del equipo invierte en actividades productivas, no productivas, actividades privadas, trabajo fuera del computador y su tiempo de inactividad.',
    'Todos los informes pueden descargarse fácilmente en formato PDF o CSV con un solo clic. La opción de descarga se habilitará una vez que los informes estén disponibles.':'Todos los reportes pueden descargarse fácilmente en formato PDF o CSV con un solo clic. La opción de descarga se habilitará una vez que los reportes estén disponibles.',
    'Descargue ahora el software de productividad automática de ClowdWork:':'Descarga ahora el software de productividad automática de ClowdWork:',
    'assets/media/help/useractivity1sp.png':'assets/media/help/useractivity1sp.png',
    'Este informe muestra la actividad de los usuarios. Puedes ampliar cualquier momento específico en el gráfico y podrás ver la actividad en ese marco de tiempo.':'Este reporte muestra la actividad de los usuarios. Puedes ampliar cualquier momento específico en el gráfico y podrás ver la actividad en ese marco de tiempo.',
    'assets/media/help/useractivity2sp.png':'assets/media/help/useractivity2sp.png',
    'assets/media/help/useractivity3sp.png':'assets/media/help/useractivity3sp.png',
    'assets/media/help/useractivity4sp.png':'assets/media/help/useractivity4sp.png',
    'El informe de actividad de los usuarios puede filtrarse por usuario, grupo o fecha para que pueda acceder fácilmente a la información que necesita.':'El reporte de actividad de los usuarios puede filtrarse por usuario, grupo o fecha para que puedas acceder fácilmente a la información que necesitas.',
    'Actividad por Usuario (Rango de Horas)':'Actividad por Usuario (Rango de Horas)',
    'ClowdWork realiza un seguimiento automático de la actividad de los usuarios y proporciona métricas de productividad, ayudándole a identificar el tiempo invertido en actividades que añaden valor a su negocio':'ClowdWork realiza un seguimiento automático de la actividad de los usuarios y proporciona métricas de productividad, ayudando a identificar el tiempo invertido en actividades que añaden valor al negocio',
    'Todos los informes se pueden descargar fácilmente en formato PDF o CSV con un solo clic. El botón de descarga estará disponible una vez que descargue el software':'Todos los reportes se pueden descargar fácilmente en formato PDF o CSV con un solo clic. El botón de descarga estará disponible una vez se descargue el software',
    'PARA COMENZAR A GENERAR METRÍCAS DEBES DESCARGAR EL AGENTE AUTOMÁTICO DE PRODUCTIVIDAD EN EL SIGUIENTE ENLACE':'PARA COMENZAR A GENERAR METRÍCAS DEBES DESCARGAR EL AGENTE AUTOMÁTICO DE PRODUCTIVIDAD EN EL SIGUIENTE ENLACE',
    'assets/media/help/dailypro1sp.png':'assets/media/help/dailypro1sp.png',
    'assets/media/help/dailypro2sp.png':'assets/media/help/dailypro2sp.png',
    'assets/media/help/dailypro3sp.png':'assets/media/help/dailypro3sp.png',
    'Este informe le permite ver el tiempo dedicado a actividades productivas (medido por la barra de la izquierda en horas), el tiempo no productivo, el tiempo manual o el tiempo fuera del ordenador y el porcentaje de productividad (la línea roja medida por la barra de porcentaje de la derecha) de cada uno de los miembros de su equipo.':'Este reporte permite ver el tiempo dedicado a actividades productivas (medido por la barra de la izquierda en horas), el tiempo no productivo, el tiempo manual o el tiempo fuera del computador y el porcentaje de productividad (la línea roja medida por la barra de porcentaje de la derecha) de cada uno de los miembros del equipo.',
    'En la parte inferior puedes ver el informe en formato de tabla.':' En la parte inferior puedes ver el informe en formato de tabla.',
    'La columna de productividad muestra el porcentaje de productividad asociado a cada día para el usuario o área seleccionada. Este porcentaje se calcula en función de las horas de trabajo de cada empresa, que se pueden configurar en la casilla situada junto al título de la columna de productividad, en el extremo derecho de la tabla.':'La columna de productividad muestra el porcentaje de productividad asociado a cada día para el usuario o área seleccionada. Este porcentaje se calcula en función de las horas de trabajo de cada empresa, que se pueden configurar en la casilla situada junto al título de la columna de productividad, en el extremo derecho de la tabla.',
    'El informe de productividad diaria dispone de un sistema de búsqueda que permite filtrar la información (por usuario, grupo o área y fecha) para ver sólo los datos que necesita.':'El reporte de productividad diaria dispone de un sistema de búsqueda que permite filtrar la información (por usuario, grupo o área y fecha) para ver sólo los datos que se necesitan.',
    'Este informe hace un seguimiento del uso del tiempo de trabajo ya que muestra todas las aplicaciones, herramientas, software y sitios web a los que se ha accedido, especificando el tiempo exacto invertido en cada uno de ellos. ClowdWork hace un seguimiento automático de todos los miembros del equipo, independientemente de su ubicación, y ofrece un desglose por URL o aplicación para que puedas identificar fácilmente si el tiempo de trabajo':'Este informe hace un seguimiento del uso del tiempo de trabajo ya que muestra todas las aplicaciones, herramientas, software y sitios web a los que se ha accedido, especificando el tiempo exacto invertido en cada uno de ellos. ClowdWork hace un seguimiento automático de todos los miembros del equipo, independientemente de su ubicación, y ofrece un desglose por URL o aplicación para que puedas identificar fácilmente si el tiempo de trabajo se está invirtiendo en actividades que agregan valor a tu empresa',
    'Todos los informes pueden descargarse en PDF o Excel haciendo clic en los botones verdes en la parte superior derecha, los cuales aparecerán una vez se hayan generado las métricas:':'Todos los reportes pueden descargarse en PDF o Excel haciendo clic en los botones verdes en la parte superior derecha, los cuales aparecerán una vez se hayan generado las métricas:',
    'PARA COMENZAR A GENERAR MÉTRICAS DEBES DESCARGAR EL AGENTE AUTOMÁTICO DE PRODUCTIVIDAD EN EL SIGUIENTE ENLACE':'PARA COMENZAR A GENERAR MÉTRICAS, DEBES DESCARGAR EL AGENTE AUTOMÁTICO DE PRODUCTIVIDAD EN EL SIGUIENTE ENLACE',
    'Haciendo clic en el botón que aparece junto a cada aplicación, podrás ver una lista detallada de todos los archivos, URLs y apps que ha utilizado cualquier miembro del equipo y el tiempo que ha dedicado a cada uno. Haciendo clic en el menú de la parte superior, podrás ver la actividad por categorías: Productiva, no productiva, sin categorizar o actividad sin conexión.':'Haciendo clic en el botón que aparece junto a cada aplicación, podrás ver una lista detallada de todos los archivos, URLs y apps que ha utilizado cualquier miembro del equipo y el tiempo que ha dedicado a cada una. Haciendo clic en el menú de la parte superior, podrás ver la actividad por categorías: Productiva, no productiva, sin categorizar o actividad sin conexión.',
    'El segundo botón permite clasificar cada aplicación, como productiva, no productiva o sin categorizar, para toda la organización, un grupo o un usuario. Con base en esta clasificación se generan las métricas de productividad. En la parte derecha se muestra un gráfico que muestra cómo se divide el uso del tiempo laboral en términos porcentuales.':'El segundo botón permite clasificar cada aplicación, como productiva, no productiva o sin categorizar para toda la organización, un grupo o un usuario. Con base en esta clasificación se generan las métricas de productividad. En la parte derecha se muestra un gráfico que muestra cómo se divide el uso del tiempo laboral en términos porcentuales.',
    'assets/media/help/appuse1sp.png':'assets/media/help/appuse1sp.png',
    'assets/media/help/appuse2sp.png':'assets/media/help/appuse2sp.png',
    'Datos no disponibles':'Datos no disponibles',
    'Cambiar logotipo':'Cambiar logotipo',
    'Cargar imagen':'Cargar imagen',
    'Su categoria':'Su categoria',
    'Cliente': 'Cliente',
    'Agregar cliente': 'Agregar cliente',
    'Vista':'Vista',
    'Nombre de empresa': 'Nombre de empresa',
    'Módulos':'Módulos',
    'Administrador de usuarios': 'Administrador de usuarios',
    'Es compañero':'Es partner',
    'nombre de host': 'nombre de host',
    'licencia de cantidad':'Cantidad de licencias',
    'Cliente creado con éxito': 'Cliente creado con éxito',
    'Nuevo cliente':'Nuevo cliente',
    'Ver cliente':'Ver cliente',
    'ID de idioma':'ID de idioma',
    'Solicitar demostración':'Solicitar demostración',
    'Se ha enviado la solicitud.':'Se ha enviado la solicitud.',
    'Solicite una demostración': 'Solicita una demostración',
    'Actualizar hora manual':'Actualizar hora manual',
    '¿Está seguro de que desea eliminar este evento?':'¿Estás seguro de que deseas eliminar este evento?',
    'Evento añadido con éxito':'Evento añadido con éxito',
    'Tiempo en reuniones':'Tienpo Manual',
    'Tablero':'Tablero',
    'Induccion':'Induccion',
    'Cambiar':'Cambiar',
    'Cargo':'Cargo',
    'Aceptar': 'Aceptar',
    'Cambiar contraseña': 'Cambiar contraseña',
    'Repetir contraseña':'Repetir contraseña',
    'Nueva contraseña':'Nueva contraseña',
    'Contraseña anterior':'Contraseña anterior',
    'Cambio':'Cambio',
    'Versión OS X':'Versión OS X',
    'Versión Linux':'Versión Linux',
    'Versión Windows':'Versión Windows',
    'Desde OS 10.11 en adelante':'Desde OS 10.11 en adelante',
    'Desde Ubuntu 18.04 en adelante':'Desde Ubuntu 18.04 en adelante',
    'Desde Windows 7 en adelante':'Desde Windows 7 en adelante',
    'El informe de tiempo trabajado muestra la cantidad de tiempo invertido en actividades productivas, no productivas, manuales o fuera de línea.':'El informe de tiempo trabajado muestra la cantidad de tiempo invertido en actividades productivas, no productivas, manuales o fuera de línea.',
    'Todos los informes se pueden descargar fácilmente en formato PDF o CSV con un solo clic. El botón de descarga estará disponible una vez que descargue el software.':'Todos los reportes se pueden descargar fácilmente en formato PDF o CSV con un solo clic. El botón de descarga estará disponible una vez que se descargue el software.',
    'PARA COMENZAR A GENERAR METRÍCAS DEBES DESCARGAR EL AGENTE AUTOMÁTICO DE PRODUCTIVIDAD EN EL SIGUIENTE ENLACE:':'PARA COMENZAR A GENERAR METRÍCAS DEBES DESCARGAR EL AGENTE AUTOMÁTICO DE PRODUCTIVIDAD EN EL SIGUIENTE ENLACE:',
    'assets/media/help/windowtrack1sp.png':'assets/media/help/windowtrack1sp.png',
    'assets/media/help/windowtrack2sp.png':'assets/media/help/windowtrack2sp.png',
    'Para cada miembro del equipo, se puede mostrar el total de horas trabajadas por día. La línea verde permite comparar entre usuarios el tiempo trabajado en el rango seleccionado. La línea roja vertical muestra el valor medio trabajado por todo el equipo en el rango de tiempo a analizar.':'Para cada miembro del equipo, se puede mostrar el total de horas trabajadas por día. La línea verde permite comparar entre usuarios el tiempo trabajado en el rango seleccionado. La línea roja vertical muestra el valor medio trabajado por todo el equipo en el rango de tiempo a analizar.',
    'Los botones de la parte superior permite seleccionar la vista del tipo de tiempo requerido (Productivo, Manual, Sin categoría, No productivo u Ocioso).':'Los botones de la parte superior permiten seleccionar la vista del tipo de tiempo requerido (Productivo, Manual, Sin categoría, No productivo o inactividad).',
    'El informe de Tiempo Trabajado tiene un sistema de búsqueda que permite filtrar la información (por usuario, grupo o área y fecha) para ver sólo los datos que necesita.':'El reporte de Tiempo Trabajado tiene un sistema de búsqueda que permite filtrar la información (por usuario, grupo o área y fecha) para ver sólo los datos que necesitas.',
    '¿No tienes cuenta?':"¿No tienes cuenta?",
    '¿Olvidaste tu password?':'¿Olvidaste tu contraseña?',
    'Ocurrio un error, verifica el usuario y el password':'Ocurrió un error. Por favor verifica el usuario y la contraseña',
    'Esta función le permite ver cómo avanza el trabajo con capturas de pantalla automáticas a intervalos regulares, lo que garantiza que la actividad diaria se ajuste a las prioridades y los objetivos de la empresa. Esta función se puede activar o desactivar en cualquier momento.':'Esta función permite ver cómo avanza el trabajo con capturas de pantalla automáticas a intervalos regulares, lo que garantiza que la actividad diaria se ajuste a las prioridades y los objetivos de la empresa. Esta función se puede activar o desactivar en cualquier momento.',
    'PARA EMPEZAR A GENERAR ESTE INFORME ES NECESARIO DESCARGAR EL GESTOR AUTOMÁTICO DE PRODUCTIVIDAD HACIENDO CLIC EN EL SIGUIENTE ENLACE:':'PARA EMPEZAR A GENERAR ESTE INFORME ES NECESARIO DESCARGAR EL AGENTE AUTOMÁTICO DE PRODUCTIVIDAD HACIENDO CLIC EN EL SIGUIENTE ENLACE:',
    'ClowdWork realiza capturas de pantalla a intervalos regulares y mide los niveles de actividad para que puedas gestionar la productividad de tus equipos de trabajo operativos. Por defecto, las capturas de pantalla se realizan cada 5 minutos, pero los intervalos son totalmente personalizables. Los niveles de actividad se basan en el uso del ratón y del teclado de cada usuario. Se puede acceder al informe de capturas de pantalla y a los niveles de actividad en tiempo real desde cualquier dispositivo y, haciendo clic en cada captura de pantalla, se puede ampliar o descargar la imagen.':'ClowdWork realiza capturas de pantalla a intervalos regulares y mide los niveles de actividad para que puedas gestionar la productividad de tus equipos de trabajo operativos. Los niveles de actividad se basan en el uso del mouse y del teclado de cada usuario. Se puede acceder al reporte de capturas de pantalla y a los niveles de actividad en tiempo real desde cualquier dispositivo y, haciendo clic en cada captura de pantalla, se puede ampliar o descargar la imagen.',
    'Esta función se puede activar o desactivar en cualquier momento desde la configuración (en el menú de la parte superior derecha':'Esta función se puede activar o desactivar en cualquier momento desde la configuración (en el menú de la parte superior derecha)',
    'según las necesidades de cada empresa.':'según las necesidades de cada empresa.',
    'Grupo de administradores':'Grupo de administradores',
    'Saltar incorporación':'Saltar incorporación',
    'En este módulo puede crear usuarios y actualizar la información de cada miembro del equipo (nombre de usuario, grupo al que pertenece, contraseña). También puede asignar roles (usuario general, usuario con acceso a informes - administrador del grupo o administrador general). La captura de usuarios y capturas de pantalla también se puede habilitar o deshabilitar.':'En este módulo puedes crear usuarios y actualizar la información de cada miembro del equipo (nombre de usuario, grupo al que pertenece, contraseña). También se pueden asignar roles (usuario general, usuario con acceso a reportes - administrador del grupo o administrador general). Los usuarios y capturas de pantalla también se puede habilitar o deshabilitar.',
    'Agregue los miembros de su equipo >>':'Agrega a los miembros de tu equipo >>',
    'Seleccione el sistema operativo correspondiente y complete la descarga. El software comenzará a generar automáticamente métricas de productividad.':'Selecciona el sistema operativo correspondiente y completa la descarga. El software comenzará a generar automáticamente métricas de productividad.',
    'Instalar el agente de productividad >>':'Instalar el agente de productividad >>',
    'Puede crear, actualizar o eliminar grupos según los departamentos de su empresa':'Puedes crear, actualizar o eliminar grupos según los departamentos de tu empresa',
    'Configurar grupos de usuarios >>':'Configurar grupos de usuarios >>',
    'Puede clasificar las aplicaciones que utilizan los miembros de su equipo como productivas o no productivas para que los informes de productividad se puedan generar con precisión. De esta manera, puedes ayudar a todos los miembros del equipo a enfocarse en aquellas actividades que realmente agregan valor a tu negocio.':'Puedes clasificar las aplicaciones que utilizan los miembros de tu equipo como productivas o no productivas para que los reportes de productividad se puedan generar con precisión. De esta manera, puedes ayudar a todos los miembros del equipo a enfocarse en aquellas actividades que realmente agregan valor a tu negocio.',
    'Clasificar aplicaciones >>':'Clasificar aplicaciones >>',
    'La plataforma ofrece varios informes de productividad. Cada módulo cuenta con filtros, para que puedas ver la información ya sea por grupo/departamento de la empresa o individualmente. ¡También puede descargar los informes en formato PDF o Excel con solo un clic!':'La plataforma ofrece varios reportes de productividad. Cada módulo cuenta con filtros, para que puedas ver la información ya sea por grupo/departamento de la empresa o individualmente. ¡También puedes descargar los reportes en formato PDF o Excel con solo un clic!',
    'Informes >>':'Reportes >>',
    'Agrega los miembros de tu equipo':'Agrega a los miembros de tu equipo',
    'Instalar el agente de productividad':'Instalar el agente de productividad',
    'Configurar grupos de usuarios':'Configurar grupos de usuarios',
    'Clasificar aplicaciones':'Clasificar aplicaciones',
    'Informes de productividad en tiempo real':'Informes de productividad en tiempo real',
    'Formulario de activación':'Formulario de activación',
    'Correo electrónico del cliente':'Correo electrónico del cliente',
    'Facturación y Planes':'Facturación y Planes',
    '-Las actividades productivas son aquellas que agregan valor al negocio.':'-Las actividades productivas son aquellas que agregan valor al negocio.',
    '-Las actividades no productivas son aquellas que no agregan valor al negocio.':'-Las actividades no productivas son aquellas que no agregan valor al negocio.',
    '-Las actividades no categorizadas son aquellas actividades que el administrador aún no ha clasificado como productivas o no productivas y en algunos casos, algunas empresas lo usan como comodín para validar qué usuarios y con qué frecuencia están usando esa aplicación.':'-La clasificación sin categorizar es un comodín y a propósito se puede dejar en esa categoría, alguna actividad específica para validar qué usuarios y con qué frecuencia la están realizando.',
    'El sistema almacena automáticamente las aplicaciones y los sitios web utilizados por los miembros del equipo y los registra de manera predeterminada como productivos.' : 'El sistema almacena automáticamente las aplicaciones y los sitios web utilizados por los miembros del equipo y los registra de manera predeterminada como productivos.',
    'Para categorizar una aplicación para todos los usuarios de la empresa, debe seleccionar la opción todos los grupos y todos los usuarios en los filtros. En caso de que necesite categorizar una aplicación solo para un grupo o usuario en particular, debe indicar en el filtro correspondiente el nombre del grupo/usuario y actualizar la lista haciendo clic en el botón de búsqueda. Luego debe buscar la aplicación, marcar uno de los criterios (Productivo, No productivo o Sin categorizar) y de esta manera la aplicación seleccionada quedará categorizada para todos los usuarios, grupo y/o usuario seleccionado.':'Para categorizar una aplicación para todos los usuarios de la empresa, se debe seleccionar la opción todos los grupos y todos los usuarios en los filtros. En caso de que necesites categorizar una aplicación solo para un grupo o usuario en particular, debes indicar en el filtro correspondiente el nombre del grupo/usuario y actualizar la lista haciendo clic en el botón de búsqueda. Luego debes buscar la aplicación, marcar uno de los criterios (Productivo, No productivo o Sin categorizar) y de esta manera la aplicación seleccionada quedará categorizada para todos los usuarios, grupo y/o usuario seleccionado.',
    'Este módulo le permite categorizar el uso de aplicaciones/sitios web utilizados por sus empleados como Productivo, Sin categorizar y No Productivo.': 'Este módulo permite categorizar el uso de aplicaciones/sitios web utilizados por los miembros de tu equipo como Productivo, Sin categorizar y No Productivo.',
    'assets/media/help/app1sp.png':'assets/media/help/app1sp.png',
    'assets/media/help/app2sp.png':'assets/media/help/app2sp.png',
    'Regla añadida con éxito':'Regla añadida con éxito',
    'Regla actualizada con éxito':'Regla actualizada con éxito',
    'Desinstaladores':'Desinstaladores',
    'Descargar manual de desinstalación de Windows':'Descargar manual de desinstalación de Windows',
    'Descargar manual de desinstalación de GNU/Linux':'Descargar manual de desinstalación de GNU/Linux',
    'Descargar manual de desinstalación de Mac':'Descargar manual de desinstalación de Mac',
    'Archivo importado con éxito.':'Archivo importado con éxito.',
    'La actualización no está disponible.':'La actualización no está disponible.',
    'Actualizar cliente':'Actualizar cliente',
    'Reportar a':'Reportar a',
    'Se requiere la URL de informe':'Se requiere la URL de informe',
    'DB sufijo':'DB sufijo',
    'Se requiere el sufijo db':'Se requiere el sufijo db',
    'Nuevo beta':'Nuevo beta',
    'Nuevo stable':'Nuevo stable',
    'El canal es requerido':'El canal es requerido',
    'Se requiere la versión de Windows':'Se requiere la versión de Windows',
    'Se requiere la versión de Linux':'Se requiere la versión de Linux',
    'versión Mac':'versión Mac',
    'Se requiere la versión de Mac':'Se requiere la versión de Mac',
    'Intervalo de inventario de hardware':'Intervalo de inventario de hardware',
    'Se requiere un intervalo de inventario de hardware':'Se requiere un intervalo de inventario de hardware',
    'Intervalo de inventario de software':'Intervalo de inventario de software',
    'Se requiere un intervalo de inventario de software':'Se requiere un intervalo de inventario de software',
    'Tiempo de inactividad a ralentí':'Tiempo de inactividad a ralentí',
    'Se requiere tiempo de inactividad para inactividad':'Se requiere tiempo de inactividad para inactividad',
    'Intervalo de envío del registro':'Intervalo de envío del registro',
    'Se requiere el intervalo de envío del registro':'Se requiere el intervalo de envío del registro',
    'Intervalo de verificación de config':'Intervalo de verificación de config',
    'Se requiere intervalo de verificación de config':'Se requiere intervalo de verificación de config',
    'Intervalo de registro':'Intervalo de registro',
    'Se requiere intervalo de registro':'Se requiere intervalo de registro',
    'Canal':'Canal',
    'Monitor activado':'Monitor activado',
    'Captura de pantalla':'Captura de pantalla',
    'Salida y clerre de sesion':'Salida y clerre de sesión',
    'Modo privado':'Modo privado',
    'Permitir el inventario de hardware':'Permitir el inventario de hardware',
    'Permitir el inventario de software':'Permitir el inventario de software',
    'Send registry':'Enviar registro',
    'Silent mode':'Modo silencioso',
    'No existe ningun user con ese identificador':'No existe ningun usuario con ese identificador',
    'error No puedes eliminar este registro porque tiene informacion asociada':'Error.  No puedes eliminar este registro porque tiene informacion asociada',
    'Proyectos':'Proyectos',
    'Buscar nuevo':'Buscar nuevo',
    'Filtrar':'Filtrar',
    'Marcar como favorito':'Marcar como favorito',
    'Nuevo proyecto':'Nuevo proyecto',
    'Filtrar por usuario':'Filtrar por usuario',
    'Nuevo subproyecto':'Nuevo subproyecto',
    'Nueva tarea':'Nueva tarea',
    'Nombre del proyecto':'Nombre del proyecto',
    'Fecha de inicio':'Fecha de inicio',
    'Fecha final':'Fecha final',
    'Cliente ':'Cliente',
    'Categoría':'Categoría',
    'Mi equipo':'Mi equipo',
    'screensot_enable':'Habilitar capturas de pantalla',
    'Ver más':'Ver más',
    "Agregar recolección de log":"Agregar recolección de log",
    'Correo electrónico a la usuario creada':'Correo electrónico a la usuario creada',
    "Descargar manual de Instalación de Windows":"Descargar manual de Instalación de Windows",
    'Descargar manual de instalación de GNU/Linux':'Descargar manual de instalación de GNU/Linux',
    'Descargar Instalador OS X':'Descargar Instalador OS X',
    'Sistema Operativo/Agente':'Sistema Operativo/Agente',
    'Trabaja de forma más inteligente y disfruta de la vida':'Trabaja de forma más inteligente y disfruta de la vida',
    'Nombre Completo':'Nombre Completo',
    'El nombre de la empresa es obligatorio':'El nombre de la empresa es obligatorio',
    'El nombre de la empresa debe contener al menos 3 caracteres':'El nombre de la empresa debe contener al menos 3 caracteres',
    'He leído y estoy de acuerdo con':'He leído y estoy de acuerdo con',
    'Términos y condiciones':'Términos y condiciones',
    'de CloudWork.':'de CloudWork.',
    'INFORMACIÓN DE USUARIO':'INFORMACIÓN DE USUARIO',
    ': Mínimo 8 caracteres':': Mínimo 8 caracteres',
    'Registro':'Registro',
    'Regístrese para una prueba gratuita de ClowdWork': 'Regístrese para una prueba gratuita de ClowdWork',
    'REGISTRATE GRATIS':'REGISTRATE GRATIS',
    'Software de seguimiento de tiempo completamente automático':'Software de seguimiento de tiempo completamente automático',
    'Registro exitoso':'Registro exitoso',
    ': Mínimo 6 caracteres':': Mínimo 6 caracteres',
    'Tienpo Manual':'Tiempo Manual',
    'En reunión':'Reunión',
    'Aplicaciones más usadas':'Aplicaciones más usadas',
    'Tiempos de tracking más altos':'Tiempos de tracking más altos',
    'Tiempo Manual':'Reunións',
    'Tiempo Manual ':"Tiempo Manual", 
    '(Dominio de la empresa)':'(Dominio de la empresa)',
    "No utilice caracteres especiales ni espacios; use guiones '-' como separadores. Máximo 14 caracteres":"No utilice caracteres especiales ni espacios; use guiones '-' como separadores. Máximo 14 caracteres",
    "Caracteres especiales y espacios no permitidos.":"Caracteres especiales y espacios no permitidos.",
    'img_validation':'Formato de imagen no válido. Solo se permiten los formatos JPEG, PNG y GIF.',
    'proyectos': 'proyectos',
    'Referencia del proyecto':'Referencia del proyecto',
    'database_type':'Tipo de base de datos',
    TRANSLATOR: {
      SELECT: 'Elige tu idioma',
    },
    MENU: {
      NEW: 'nuevo',
      ACTIONS: 'Comportamiento',
      CREATE_POST: 'Crear nueva publicación',
      PAGES: 'Pages',
      FEATURES: 'Caracteristicas',
      APPS: 'Aplicaciones',
      DASHBOARD: 'Tablero'
    },
    AUTH: {
      GENERAL: {
        OR: 'O',
        SUBMIT_BUTTON: 'Enviar',
        NO_ACCOUNT: 'No tienes una cuenta?',
        SIGNUP_BUTTON: 'Regístrate',
        FORGOT_BUTTON: '¿Olvidaste tu contraseña',
        BACK_BUTTON: 'Botón posterior',
        PRIVACY: 'Privacidad',
        LEGAL: 'Legal',
        CONTACT: 'Contacto',
      },
      LOGIN: {
        TITLE: 'Crear una cuenta',
        BUTTON: 'Registrarse',
      },
      FORGOT: {
        TITLE: 'Olvidaste tu Contraseña?',
        DESC: 'Ingresa tu correo electrónico para restablecer tu contraseña',
        SUCCESS: 'Tu cuenta se ha restablecido correctamente.'
      },
      REGISTER: {
        TITLE: 'Regístrate',
        DESC: 'Ingresa tus datos para crear tu cuenta',
        SUCCESS: 'Tu cuenta se ha registrado correctamente.'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Nombre completo',
        PASSWORD: 'Contraseña',
        CONFIRM_PASSWORD: 'Confirmar contraseña',
        USERNAME: 'Usuario'
      },
      VALIDATION: {
        INVALID: '{{name}} no es válido',
        REQUIRED: '{{name}} es requerido',
        MIN_LENGTH: '{{name}} longitud mínima es {{min}}',
        AGREEMENT_REQUIRED: 'Es requerido aceptar los términos y condiciones',
        NOT_FOUND: 'El requerido {{name}} no fue encontrado',
        INVALID_LOGIN: 'El login es incorrecto',
        REQUIRED_FIELD: 'Campo requerido',
        MIN_LENGTH_FIELD: 'Longitud mínima del campo:',
        MAX_LENGTH_FIELD: 'Longitud máxima del campo:',
        INVALID_FIELD: 'Campo no válido',
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Conteo de registros seleccionados: ',
        ALL: 'Todos',
        SUSPENDED: 'Suspendido',
        ACTIVE: 'Activo',
        FILTER: 'Filtrar',
        BY_STATUS: 'Por estado',
        BY_TYPE: 'Por Tipo',
        BUSINESS: 'Empresa',
        INDIVIDUAL: 'Individuo',
        SEARCH: 'Buscar',
        IN_ALL_FIELDS: 'En todos los campos'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Clientes',
        CUSTOMERS_LIST: 'Lista de clientes',
        NEW_CUSTOMER: 'Nuevo cliente',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Borrar cliente',
          DESCRIPTION: 'Estás seguro que deseas borrar este cliente de manera permanente?',
          WAIT_DESCRIPTION: 'Borrando cliente...',
          MESSAGE: 'El cliente ha sido borrado'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Borrado de clientes',
          DESCRIPTION: 'Estás seguro que quieres borrar de manera permanente los clientes seleccionados?',
          WAIT_DESCRIPTION: 'Borrando clientes...',
          MESSAGE: 'Los clientes seleccionados han sido borrados'
        },
        UPDATE_STATUS: {
          TITLE: 'El estado ha sido actualizado para los clientes seleccionados',
          MESSAGE: 'El estado de los clientes seleccionados ha sido actualizado con éxito'
        },
        EDIT: {
          UPDATE_MESSAGE: 'El cliente ha sido actualizado',
          ADD_MESSAGE: 'El cliente ha sido creado'
        }
      }
    }
  }
};
