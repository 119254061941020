<h4 style="color: rgb(3, 135, 243)">{{ month }}</h4>
<div class="row">
  <div class="col-lg-6 col-xxl-4">
    <!-- <div class="timeTab" style="margin-left: 25%">
      <app-tab1></app-tab1>
    </div> -->
    <app-mixed-widget1  [widgetData]="productivityData" [dashData]="dashData" ></app-mixed-widget1>
  </div>
  <div class="col-lg-6 col-xxl-4" style="margin-bottom: 2%">
    <!-- <div class="timeTab" style="margin-left: 25%">
      <app-tab1></app-tab1>
    </div> -->  
    <div class="card card-custom">
      <p style="text-align: left; margin: 6% 0% 0% 8%">
        <span class="dash_heading" style="font-size: 120%; font-weight: 1000" >{{AplicacionesMasUsadas}} </span><i ngbTooltip="{{applicationTooltip}}" class="fa fa-info-circle ml-5"></i>
      </p>
      <highcharts-chart [Highcharts]="highcharts" [options]="appColumnChart">
      </highcharts-chart>
      <hr />
      <app-stats-widget12 [widget12Data]="productivityData"></app-stats-widget12>
    </div>
  </div>
  <div class="col-lg-6 col-xxl-4">
    <!-- <div class="timeTab" style="margin-left: 25%">
      <app-tab1></app-tab1>
    </div> -->
    <div class="card card-custom" >
      <p style="text-align: left; margin: 6% 0% 0% 8%">
        <b class="dash_heading" style="font-size: 120%; font-weight: 1000"
        >{{TiemposDeTrackingMasAltos}}</b
        > <i ngbTooltip="{{categoryTooltip}}" class="fa fa-info-circle ml-5"></i>
      </p>
      <highcharts-chart
        [Highcharts]="highcharts"
        [options]="topTimeTrackedClassChart"
      >
      </highcharts-chart>
      <hr />
      <div
        class="d-flex justify-content-between card-spacer flex-grow-1"
      >
        <div class="d-flex flex-column">
          <span
            class="text-dark-75 font-weight-bolder font-size-h3 dash_heading"
            style="text-align: center; white-space: nowrap!important;"
            >{{Tiempototal}} <i ngbTooltip="{{totaltimeToolTip}}" class="fa fa-info-circle ml-5 ttip"></i><br>{{ totalTime }}h</span
            >
        </div>
      </div>
      <highcharts-chart [Highcharts]="highcharts" [options]="totalProductivity">
      </highcharts-chart>
    </div>
  </div>
  <!-- <div class="col-lg-6 col-xxl-4">
    <app-lists-widget9></app-lists-widget9>
  </div>
  <div class="col-lg-6 col-xxl-4">
    <app-stats-widget11></app-stats-widget11>
    <app-stats-widget12></app-stats-widget12>
  </div>
  <div class="col-lg-6 col-xxl-4 order-1 order-xxl-1">
    <app-lists-widget1></app-lists-widget1>
  </div>
  <div class="col-xxl-8 order-2 order-xxl-1">
    <app-advance-tables-widget2></app-advance-tables-widget2>
  </div>
  <div class="col-lg-6 col-xxl-4 order-1 order-xxl-2">
    <app-lists-widget3></app-lists-widget3>
  </div>
  <div class="col-lg-6 col-xxl-4 order-1 order-xxl-2">
    <app-lists-widget4></app-lists-widget4>
  </div>
  <div class="col-lg-12 col-xxl-4 order-1 order-xxl-2">
    <app-lists-widget8></app-lists-widget8>
  </div>
</div>
<div class="row">
  <div class="col-lg-4">
    <app-mixed-widget14></app-mixed-widget14>
  </div>
  <div class="col-lg-8">
    <app-advance-tables-widget4></app-advance-tables-widget4>
  </div>
</div> -->
</div>
