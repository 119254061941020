import { Component, OnInit, Input } from '@angular/core';
import { LayoutService } from '../../../../core';
import * as Highcharts from 'highcharts';
import { GeneralService } from 'src/app/services/general.service'
import { ReportsService } from 'src/app/services/reports.service';
import * as moment from 'moment';


@Component({
  selector: 'app-dashboard1',
  templateUrl: './dashboard1.component.html',
})
export class Dashboard1Component implements OnInit {
  @Input() baseColor;
  highcharts = Highcharts;
  appColumnChart: any;
  topTimeTrackedClassChart: any;
  currentTab= 'day';
  appData: any=[];
  dashData:any=[];
  appClassData: any=[];
  month:any;
  totalProductivity:any;
  dataFilter:any;
  enddate: any;
  startdate:any;
  proHour:number =8;
  productivityData: any=[];

  totalTime:any;
  fontFamily = '';
  colorsGrayGray500 = '';
  colorsGrayGray200 = '';
  colorsGrayGray300 = '';
  colorsThemeBase = '';
  colorsThemeLight = '';
  loading =false;
  AplicacionesMasUsadas = 'Aplicaciones más usadas';
  Tiempototal = 'Tiempo total';
  TiemposDeTrackingMasAltos = 'Tiempos de tracking más altos';
  applicationTooltip = 'Top 3 Most Used Applications in the last 30 days. For more details, go to the Applications usage report';
  totaltimeToolTip = 'This graph shows the total time invested by your team in Productive time + Manual time + Meetings + Custom in the last 30 days.';
  categoryTooltip = 'Top 3 most tracked categories in the last 30 days';
  constructor(
     private generalService: GeneralService,
     private reportService: ReportsService,
     private layout: LayoutService
     
  ) {}

  ngOnInit(): void {
    var lang = localStorage.getItem('language');
    if(lang == 'es'){
      this.AplicacionesMasUsadas = 'Las 3 aplicaciones más usadas';
      this.Tiempototal = 'Tiempo Total Trabajado';
      this.TiemposDeTrackingMasAltos = 'Categorías que registran más tiempo';
      this.applicationTooltip = 'Las 3 aplicacionas más usadas en los últimos 30 días.Para más detalles, puedes ir al reporte de Uso de Aplicaciones.';
      this.categoryTooltip = 'Las 3 aplicacionas más usadas en los últimos 30 días.Para más detalles, puedes ir al reporte de Uso de Aplicaciones.';
      this.totaltimeToolTip = 'Esta gráfica muestra el tiempo total invertido por tu equipo en Tiempo Productivo + Tiempo Manual + Reuniones + Métrica personalizables en los últimos 30 días.';
    }else if(lang == 'pt'){
      this.AplicacionesMasUsadas = 'Os 3 principais aplicativos mais usados';
      this.Tiempototal = 'Tempo Total Trabalhado';
      this.TiemposDeTrackingMasAltos = 'Categorías que registran más tiempo';
      this.applicationTooltip = 'Os 3 principais aplicativos mais usados nos últimos 30 dias. Para obtermaisdetalhes, acesse o relatório de uso de aplicativos.';
      this.categoryTooltip = 'Las 3 aplicacionas más usadas en los últimos 30 días.Para más detalles, puedes ir al reporte de Uso de Aplicaciones.';
      this.totaltimeToolTip = 'Este gráfico mostra o tempo total investido por sua equipe em Tempo Produtivo + Tempo Manual + Reuniões + Personalizado nos últimos 30 dias.';
    }else{
      this.AplicacionesMasUsadas = 'Top 3 Most Used Applications';
      this.Tiempototal = 'Total Worked Time';
      this.TiemposDeTrackingMasAltos = 'Top Tracked Categories';
      this.applicationTooltip = 'Top 3 Most Used Applications in the last 30 days. For more details, go to the Applications usage report.';
      this.categoryTooltip = 'Top 3 most tracked categories in the last 30 days.';
      this.totaltimeToolTip = 'This graph shows the total time invested by your team in Productive time + Manual time + Meetings + Custom in the last 30 days.';
    }
    this.enddate = moment().format("YYYY-MM-DD");   
    this.startdate = moment(this.enddate).subtract(30, 'days').format("YYYY-MM-DD");  
    const date = new Date();
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
this.month = months[date.getMonth()] + ". " + date.getFullYear();
   this.loadLayoutView();
   this.load()
  }

    loadLayoutView() {
    this.fontFamily = this.layout.getProp('js.fontFamily');
    this.colorsGrayGray500 = this.layout.getProp('js.colors.gray.gray500');
    this.colorsGrayGray200 = this.layout.getProp('js.colors.gray.gray200');
    this.colorsGrayGray300 = this.layout.getProp('js.colors.gray.gray300');
    this.colorsThemeBase = this.layout.getProp(
      `js.colors.theme.base.${this.baseColor}`
    );

    this.colorsThemeLight = this.layout.getProp(
      `js.colors.theme.light.${this.baseColor}`
    );
  }

  load(){
    this.generalService.dashboard().subscribe((res)=>{
       this.dashData = res;
       this.appData = res.most_used_applications;
       this.appClassData = res.top_time_tracked_classification;
       
        this.appChart();
       this.classificationChart();
    })
    this.appChart();
    this.classificationChart();
    this.dataFilter ={
      start_date :this.startdate,
      end_date: this.enddate,
      user_group_id: '',
      user_id: '',
      productivity_hour: this.proHour
     }
    

    const resultPromise = new Promise((resolve, reject) => {
      resolve(this.getPermission());
  })
     resultPromise.then(res=>{
      var perArr:any= res
      if(perArr !== undefined && perArr !==null){
        let result:any;
        let result2:any;
        if(perArr.module !== undefined && perArr.module !==null){
            result = perArr.module.find( ({ slug }) => slug === 'productividad-diaria' );
        }
        if(perArr.permission !== undefined && perArr.permission !==null){
           result2 = perArr.permission.find( ({ name }) => name === 'report_activity_daily' );
        }
        
        if(result !==undefined && result2 !==undefined){
          this.reportService.activityDaily(this.dataFilter).subscribe((res)=>{
            this.productivityData = res;
            
            this.totalProductivityGraph();
          })
        }
      }
     })
    


    this.totalProductivityGraph();
  }

  async getPermission() {
    const permission = await JSON.parse(localStorage.getItem('permissions'));
    const module = await JSON.parse(localStorage.getItem('modules'));


    return {permission,module};
  }

  
  preferredOrder(obj, order) {
    var newObject = {};
    for(var i = 0; i < order.length; i++) {
        if(obj.hasOwnProperty(order[i])) {
            newObject[order[i]] = obj[order[i]];
        }
    }
    return newObject;
}

  appChart(){
    var app1 = 0
    var app2 = 0
    var app3 = 0
    var catArr =[];
    
    if( this.appData.length > 0 && this.appData !== undefined && this.appData !== null){
      app1 = this.timeDecimal(this.appData[0].duration)
      if(this.appData.length > 1){
        app2 = this.timeDecimal(this.appData[1].duration)
      }
      if(this.appData.length > 2){
        app3 = this.timeDecimal(this.appData[2].duration)
      }
      
       for(var i =0; i<this.appData.length; i++){
               catArr.push(this.appData[i].name)
       }
    }

    this.appColumnChart = {
      chart: {
        type: "column",
        height: (9.5 / 16 * 100) + '%' // 16:9.5 ratio
      },
      title: false,
      exporting: {
        enabled: false,
      },
      // tooltip: {
      //   shared: true
      // },
     yAxis: {
        labels: {
          enabled: false
      },
      title: {
          text: null
      }
      },
      credits: {
        enabled: false
      },
      xAxis: {
        scrollbar: {
          enabled: false
        },
        labels: {
          rotation: 0,
          style: {
           //   fontSize: '80%',
              fontWeight: 'bold',
              // fontFamily: 'helvetica, arial, Verdana, sans-serif'
          }
      },
      categories:catArr
      },
      tooltip : {  
        formatter: function () {
          var title = "<b style='text-decoration: underline'>" + this.x + "</b>";
          var minutes = this.y;
          var min = Math.floor(Math.abs(minutes));
          var sec = Math.floor((Math.abs(minutes) * 60)% 60);
          var msec = Math.floor((Math.abs(minutes) * 3600) % 60);
          var valueHours = (min < 10 ? "0" : "") + min + ":" + (sec < 10 ? "0" : "") + sec + ":" + (msec < 10 ? "0" : "") + msec;             
          var time = " " + this.series.name + ": " + valueHours;
          return title + "<br>" + time;
        },
      },
      plotOptions : {
        bar: {
          dataLabels: {
            enabled: true, 
            style: {textShadow: 0}
          }
        },
        series: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            align: "center",
            color: "#FFFFFF",
            x: 0,
            style: {textShadow: 0},
            formatter: function() {
              var minutes = this.y;
              
              var min = Math.floor(Math.abs(minutes));
              var sec = Math.floor((Math.abs(minutes) * 60)% 60);
              var msec = Math.floor((Math.abs(minutes) * 3600) % 60);
              return (min < 10 ? "0" : "") + min + ":" + (sec < 10 ? "0" : "") + sec + ":" + (msec < 10 ? "0" : "") + msec;              
            }, 
  
          },      
        },   
      }, 
      legend: false,
      series: [
        {
         // type: "column",
          name: "duration",
          data: [{y:app1, color:'#0065cd'},
                 {y:app2,color:'#3699ff'},
                 {y:app3,color:'#63a2e3'}],
          dataLabels: {
            enabled: true,
           // rotation: -90,
            color: '#FFFFFF',
            align: 'center',
            y:50,
            style: {
                fontSize: '100%',
                fontFamily: 'helvetica, arial, sans-serif',
                textShadow: false,
                fontWeight: 'normal',
            }
        },
        },
      ]
    };
  }



  classificationChart(){
    var productive:any = 0;
    var uncategorized:any = 0;
    var nonProductive:any =0;
    var lang = localStorage.getItem('language');
    var data;
    if(lang == 'es'){
       data = {
        Productivo: 0,
        SinCategorizar: 0,
        NoProductivo:0
      }
    }
    else if(lang == 'pt'){
      data = {
        Productivo: 0,
        SinCategorizar: 0,
        NoProductivo:0
      }
   }
    else{
       data = {
        Productive: 0,
        SinCategorizar: 0,
        NonProductive:0
      }
    }

    if(this.appClassData != undefined && this.appClassData != null){

      this.appClassData.forEach(element => {
        
      if(element.productive_time_sum !== null){
        productive = productive + this.timeDecimal(element.productive_time_sum);
        if(lang == 'es'){
        data.Productivo = data.Productivo + this.timeDecimal(element.productive_time_sum);
       }else if(lang == 'pt'){
        data.Productivo =  data.Productivo + this.timeDecimal(element.productive_time_sum);
      }
       else{
        data.Productive = data.Productive + this.timeDecimal(element.productive_time_sum);
       }
        
      }
      if(element.uncategorized_time_sum !== null){
        uncategorized = uncategorized + this.timeDecimal(element.uncategorized_time_sum);
        if(lang == 'es'){
        data.SinCategorizar = data.SinCategorizar + this.timeDecimal(element.uncategorized_time_sum);
        }else if(lang == 'pt'){
          data.SinCategorizar = data.SinCategorizar + this.timeDecimal(element.uncategorized_time_sum);
        }else{
          data.uncategorized = data.uncategorized + this.timeDecimal(element.uncategorized_time_sum);
        }
      }
      if(element.none_productive_time_sum !== null){
        nonProductive = nonProductive + this.timeDecimal(element.none_productive_time_sum);
        if(lang == 'es'){
        data.NoProductivo =  data.NoProductivo + this.timeDecimal(element.none_productive_time_sum);
        }
        else if(lang == 'pt'){
          data.NoProductivo = data.NoProductivo + this.timeDecimal(element.none_productive_time_sum);
        }else{
          data.NonProductive = data.NonProductive + this.timeDecimal(element.none_productive_time_sum);
        }
      }
    });

      var sortable = [];
          for (var cf in data) {
              sortable.push([cf, data[cf]]);
          }

          sortable.sort(function(a, b) {
              return a[1] - b[1];
     });
     
    }
    
      this.topTimeTrackedClassChart = {
        chart: {
          type: "column",
          height: (9.5 / 16 * 100) + '%' // 16:9.5 ratio
        },
        title: false,
        exporting: {
          enabled: false,
        },
        // tooltip: {
        //   shared: true
        // },
       yAxis: {
          labels: {
            enabled: false
        },
        title: {
            text: null
        }
        },
        xAxis: {
          scrollbar: {
            enabled: false
          },
          labels: {
            rotation: 0,
            style: {
                fontSize: '100%',
                fontWeight: 'bold',
                // fontFamily: 'helvetica, arial, Verdana, sans-serif'
            }
        },
        categories:[sortable[2][0],sortable[1][0],sortable[0][0]]
        },
        credits: {
          enabled: false
        },
        tooltip : {
          formatter: function () {
            var title = "<b style='text-decoration: underline'>" + this.x + "</b>";
            var sec = this.y;
            var hours = Math.floor(sec / 60 / 60);

            var minutes = Math.floor(sec / 60) - (hours * 60);
        
            var seconds = sec % 60;
        
            var formatted = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');          
            var time = " " + this.series.name + ": " + formatted;
            return title + "<br>" + time;
          },
        },
        plotOptions : {
          bar: {
            dataLabels: {
              enabled: true, 
              style: {textShadow: 0}
            }
          },
          series: {
            stacking: 'normal',
            dataLabels: {
              enabled: true,
              align: "center",
              color: "#FFFFFF",
              x: 0,
              style: {textShadow: 0},
              formatter: function() {
                var sec = this.y;

                var hours = Math.floor(sec / 60 / 60);

                var minutes = Math.floor(sec / 60) - (hours * 60);
            
                var seconds = sec % 60;
            
                var formatted = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
                return  formatted;        
              }, 
    
            },      
          },   
        }, 
        legend: false,
        series: [
          {
           // type: "column",
            name: "duration",
            data: [{y:parseInt(sortable[2][1]), color:'#1f7c62'},
                   {y:parseInt(sortable[1][1]),color:'#25aba4'},
                   {y:parseInt(sortable[0][1]),color:'#02ede1'}],
            dataLabels: {
              enabled: true,
             // rotation: -90,
              color: '#FFFFFF',
              align: 'center',
              y:50,
              style: {
                  fontSize: '100%',
                  fontFamily: 'helvetica, arial, sans-serif',
                  textShadow: false,
                  fontWeight: 'normal',
              }
          },
          },
        ]
    };
  }

  totalProductivityGraph() {
    let categoriesDay=[];
    let total_Time=[];
    this.totalTime = 0
     
    if(this.productivityData !== undefined && this.productivityData !== null && this.productivityData.length > 0){
      categoriesDay = this.productivityData.map((value) => moment(value.start_time).format('MMM DD'));
      total_Time= this.productivityData.map(value => parseFloat(this.secondsToHours(this.valueHours((parseInt(value.productive_time) + parseInt(value.uncategorized_time) + parseInt(value.manual_time) + parseInt(value.manual_time_system))))));
      // let dataNotProductive = this.productivityData.map(value => this.valueHours((value.none_productive_time)?value.none_productive_time:'00:00:00'));
      
      for(var i=0; i<total_Time.length; i++){
          this.totalTime += total_Time[i]
      }
      this.totalTime = this.totalTime.toFixed(2);
    }


    this.totalProductivity = {   
      chart: {
        type: "area",
        height: 150,
        toolbar: {
          show: false
        },
        marginBottom:0
      },
      title:false,
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },

      // title: {
      //   text: "Daily Productivity",
      // },
      xAxis: {
        categories: categoriesDay,
        min:0,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false
        },
        // labels: {
        //   show: false,
        //   style: {
        //     colors: this.colorsGrayGray500,
        //     fontSize: '10',
        //     fontFamily: this.fontFamily
        //   }
        // },
        // crosshairs: {
        //   show: false,
        //   position: 'front',
        //   stroke: {
        //     color: this.colorsGrayGray300,
        //     width: 1,
        //     dashArray: 3
        //   }
        // },
        crosshair: false,
        tickLength:0,
        labels:false,
        states: {
          normal: {
            filter: {
              type: 'none',
              value: 0
            }
          },
          hover: {
            filter: {
              type: 'none',
              value: 0
            }
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: 'none',
              value: 0
            }
          }
        },
        tooltip: {
          enabled: true,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: '12px',
            fontFamily: this.fontFamily
          }
        }
      },
      yAxis: {
        crosshair: false,
        labels: false ,
        title:false,
        min: 0,
        // max: 12,
      },
      tooltip: {
        style: {
          fontSize: '12px',
          fontFamily: this.fontFamily
        },
        y: {
          formatter: (val) => {
            return val + " Hours";
          }
        }
      },
     // plotOptions: {
        // area: {
        //   stacking: "normal",
        //   lineColor: "#666666",
        //   lineWidth: 1,
        //   marker: {
        //     lineWidth: 1,
        //     lineColor: "#666666",
        //   },
        // },
      //},
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
     series: [{
        showInLegend: false, 
        data: total_Time,
        tooltip: { valueSuffix: " Hours" },
        color: '#4eb14d',
      }],     
    };    
   
  }

  setCurrentTab(tab: string) {
    this.currentTab = tab;
  }

  timeDecimal(time) {
    return time?.split(':')
          .map(function(val) { return parseInt(val, 10); } )
          .reduce( function(previousValue, currentValue, index, array){
              return previousValue + currentValue / Math.pow(60, index);
  });
  } 

  secondsToHours(time){
    var hours = Math.floor(time / 60 / 60);

    var minutes = Math.floor(time / 60) - (hours * 60);

    var seconds = time % 60;

    var formatted = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');

    return formatted;
  }

  valueHours(time) {
    let Hours = String(time);
    let H = Hours.split(":")[0];
    let M = Hours.split(":")[1];
    let S = Hours.split(":")[2];
    time = parseFloat(H + "." + M);

    return time;
 }  

}
