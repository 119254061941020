import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../../../../core';

@Component({
  selector: 'app-dashboard3',
  templateUrl: './dashboard3.component.html',
})
export class Dashboard3Component implements OnInit {
  constructor() { }

  ngOnInit(): void { }
}
